import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../Wrapper";
import sortedNationality from "../../../data/nationality";

const FastAction = ({
    setAllNationality,
    setAllOccupierInformation,
}) => {
    const context = useContext(ThemeContext);

    const [selectedNationality, setSelectedNationality] = useState("");
    const [openNationality, setOpenNationality] = useState(false);

    const handleNationalityChange = (nationality) => {
        setSelectedNationality(nationality);
        setAllNationality(nationality);
    }

    const handleInfomationCheck = (action) => {
        if(action) {
            setAllOccupierInformation();
        }
    }

    return (
        <>
            <div className="bloc-chp">
                <div className="blcChp col-100">
                    <div className="option-check">
                        <input
                            id="allinfo"
                            name="radio-group"
                            type="checkbox"
                            onClick={(e) => handleInfomationCheck(e.target.checked)}
                        />
                        <label for="allinfo">{context.translate("fill_all_contact_information")}</label>
                    </div>
                </div>
                <div className="blcChp col-100">
                    <div className="option-check">
                        <input
                            id="allnationality"
                            name="radio-group"
                            type="checkbox"
                            onClick={(e) => setOpenNationality(e.target.checked)}
                        />
                        <label for="allnationality">{context.translate("fill_all_nationality")}</label>
                    </div>
                </div>
                {openNationality &&
                    <div className="blcChp col-100">
                        <label>{context.translate('nationality')} *</label>
                        <select
                            onChange={(e) => handleNationalityChange(e.target.value)}
                            value={selectedNationality}
                        >
                            <option value=""></option>
                            {sortedNationality.map((nationality, index) => {
                                const value = nationality.demonyms.fra.m
                                if (value && value !== "") {
                                    return (
                                        <option key={index} value={value}>{value}</option>
                                    )
                                }
                            })}
                        </select>
                    </div>
                }
            </div>
        </>
    );
}

export default FastAction;