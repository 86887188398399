import axios from "axios";
import api from "../config/api-config";

export const searchRooms = (payload) => {
  return axios.post(`${api.host}/TCTarif`, payload, {
    /* headers: {
            Authorization: token,
        }, */
  });
};

export const getAllFiltersForSearchRooms = (hotelName) => {
  return axios.get(`${api.host}/typeChambre/filter/${hotelName}`);
};

export const getRoomDetail = (payload) => {
  return axios.post(
    `${api.host}/TCTarif/detailRoomTypeWithPriceBetweenDates`,
    payload,
    {
      /* headers: {
            Authorization: token,
        }, */
    }
  );
};

export const sendImpendingReservation = (payload) => {
  return axios.post(
    `${api.host}/reservation/addImpendingReservation`,
    payload,
    {
      /* headers: {
            Authorization: token,
        }, */
    }
  );
};



export const stepUpdateInventory = (payload) => {
  // console.log("stepUpdateInventory payload: ", payload);
  return axios.post(
    `${api.host}/TCTarif/updateInventory`,
    payload,
  )
}

export const migrateDateInventory = (payload) => {
  console.log("migrateDateInventory payload: ", payload);
  return axios.post(
    `${api.host}/TCTarif/migrateInventory`,
    payload,
  )
}

export const revertInventory = (payload) => {
  // console.log("revertInventory payload: ", payload);
  return axios.post(
    `${api.host}/TCTarif/revertInventory`,
    payload,
  )
}


