import React from "react";
import { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import HotelRouter from './hotelRouter';
import Wrapper from "./components/Wrapper";
import DefaultHotel from "./components/DefaultHotel";

// Global styles
// import "sanitize.css/sanitize.css";
// import "semantic-ui-css/semantic.min.css";
// import "./index.css";

// import "./styles/parc/css/animate.css";
import "./styles/parc/css/animate_hide.css";
import "./styles/parc/css/calandar.css";
import "./styles/parc/css/reset.css";
import "./styles/parc/css/slick.css";
import "./styles/parc/css/modal.css";

import './i18n';

ReactDOM.render(
  <Suspense fallback="load">
    <Wrapper>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<DefaultHotel />} />
          <Route path="/:hotel/*" element={<HotelRouter />} />
        </Routes>
      </BrowserRouter>
    </Wrapper>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
