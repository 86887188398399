import React, { useEffect, useState, useContext } from 'react';
import { ThemeContext } from '../Wrapper';
import { translate } from '../../services/translate';

const ModificationTimelineForMyBookingItem = ({ modificationTimeline }) => {
  const context = useContext(ThemeContext)
  // A new State for the modificationTimeLine
  const [modificationTimeLineState, setModificationTimeLineState] = useState(modificationTimeline)
  useEffect(() => {
    const translationPayload = {
      to_language: context.getLanguage(),
      texts: {
        modificationTimeline: modificationTimeline
      }
    }
    const translateModificationTimeline = async (payload) => {
      const translatedResponsePayload = await translate(payload)
      const translatedList = translatedResponsePayload.data.translated
      const translatedModificationTimeLine = translatedList?.modificationTimeline
      setModificationTimeLineState(translatedModificationTimeLine)
    }
    translateModificationTimeline(translationPayload)
  }, [modificationTimeline])
  
  return (
    <div>
      {
        modificationTimeLineState.length > 0 &&
        <>
          <h3>{context.translate('status_of_the_reservation')} :</h3>
          {modificationTimeLineState.map((etat) => {
            return <p key={`${context.translate(etat.label)} ${etat.date}`}>
              <strong>{etat.label} {context.translate('the')} {etat.date}</strong>
            </p>
          })}
        </>
      }
    </div>
  );
};

export default ModificationTimelineForMyBookingItem;