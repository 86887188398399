import axios from "axios";
import api from "../config/api-config";

export const login = (payload) => {
  return axios.post(`${api.host}/user/login`, payload, {
    /* headers: {
            Authorization: token,
        }, */
  });
};

export const register = (payload) => {
  return axios.post(`${api.host}/user/registerClient`, payload, {
    /* headers: {
            Authorization: token,
        }, */
  });
};

export const validateRegisterConfirmationCode = (payload) => {
  return axios.post(`${api.host}/user/register/activate`, payload, {
    /* headers: {
            Authorization: token,
        }, */
  });
};

export const sendEmailForResetPassword = (payload) => {
  return axios.post(`${api.host}/user/SendEmailUpdateCompte`, payload, {
    /* headers: {
            Authorization: token,
        }, */
  });
};

export const resetPasswordByEmail = (payload) => {
  return axios.post(`${api.host}/user/compareCode`, payload, {
    /* headers: {
            Authorization: token,
        }, */
  });
};

export const verifyToken = (token) => {
  return axios.get(`${api.host}/verify_token`, {
    headers: {
      Authorization: token,
    },
  });
};

export const verifyCodeToResetPassword = (payload) => {
  return axios.post(`${api.host}/user/isClientResetPasswordCodeOK`, payload, {
    // headers: {
    //   Authorization: token,
    // },
  });
};