import React, { useContext } from "react";
import { ThemeContext } from "../Wrapper";

const ConfirmationNumber = ({
  state,
  indexReservedPrice,
  confirmationNumber,
}) => {

  const context = useContext(ThemeContext);

  return (
    <div
      key={confirmationNumber}
      className="item"
    >
      {context.translate("room")}{" "}
      {indexReservedPrice + 1}{" "}
      {
        state?.val !== 0 && <>
        {context.translate("confirmation")}:{" "}
          <strong>
            #{confirmationNumber}
          </strong>
        </>
      }
    </div>
  );
};

export default ConfirmationNumber;