import React, { useContext } from "react";
import RateState from "./RateState";

import api from "../../../config/api-config";
import { getDaysBetweenToDate } from "../../../services/date";
import { ThemeContext } from "../../Wrapper";

const ModifyInfoRoom = ({ rate, indexReservedPrice }) => {
  const context = useContext(ThemeContext);
  return (
    <div className="bordered flex">
      <div className="contImg">
        <img
          className="cover"
          src={
            api.host +
            "/" +
            rate.infoTypeChambre.photoCrop[0].replace("\\", "/")
          }
          alt="room"
        />
      </div>
      <div className="right">
        <RateState state={rate.infoEtat} />
        <div className="mb10">
          <strong>{context.translate('room')} {indexReservedPrice + 1}</strong>
        </div>
        <div className="mb10">
          <strong>{rate?.nomTarif}</strong>
        </div>
        <ul className="result">
          <li>
            <span className="material-symbols-rounded">bedtime</span>
            {getDaysBetweenToDate(
              new Date(rate.dateSejour.debut),
              new Date(rate.dateSejour.fin)
            )}{" "}
            {context.translate('nights')}
          </li>
          <li>
            <span className="material-symbols-rounded">group</span>
            {rate.guests.nbAdulte} {context.translate('adults')} {context.translate('and')} {rate.guests.nbEnfant} {context.translate('children')}
          </li>
          <li>
            <span className="material-symbols-rounded">zoom_out_map</span>
            {context.translate('surface')} : {rate.infoTypeChambre.superficie} m²
          </li>
          <li>
            <span className="material-symbols-rounded">single_bed</span>
            {rate.nomTypeChambre} - {rate.infoTypeChambre.chambreTotal} {context.translate('rooms')}
          </li>
        </ul>
        <div>
        <strong>
          {context.translate('price')}: {(rate.toPay.afterProm * context.currency.value).toFixed(2)} { context.currency.symbol }
        </strong>
        </div>
      </div>
    </div>
  );
};

export default ModifyInfoRoom;
