import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { cancelARoom } from "../../../services/booking";
import { ThemeContext } from "../../Wrapper";

const ModifyBookingFooter = ({
  rate,
  isReservationCanceled,
  indexItinerary,
  indexReservedPrice,
  getBookingSummaryByBookingId,
  setValidateBookingError,
}) => {
  const params = useParams();

  const context = useContext(ThemeContext);
  const removeItemFromCart = (itineraryIndex, ratePlanIndex) => {
    context.showLoader(true);

    const payload = {
      idReservation: params.bookingId,
      indexItineraire: itineraryIndex,
      indexTarif: ratePlanIndex,
    };
    console.log(itineraryIndex, ratePlanIndex);
    cancelARoom(payload)
      .then((results) => {
        if (results.data.status === 200) {
          getBookingSummaryByBookingId();
        } else {
          setValidateBookingError({
            showValidateBookingError: true,
            message: context.translate('an_error_occured_during_the_cancelation'),
          });

          context.showLoader(false);
        }
      })
      .catch((error) => {
        context.showLoader(false);
      });
  };

  return (
    <div className="content contTot">
      <div className=" flex flexBetween">
        <div className="left">
          <div>
            <strong className="uppercase">{context.translate('price')} :</strong>
          </div>
          <div className="priceTot">
            {(rate.toPay.afterProm * context.currency.value).toFixed(2)} { context.currency.symbol }
          </div>
        </div>
        {!isReservationCanceled && rate.etat !== 0 && (
          <div className="right">
            <div className="option withTxt">
              <a href="#">
                <span className="material-symbols-rounded">edit</span>
                {context.translate('modify_room_reservation')}
              </a>
            </div>
            <div className="option withTxt">
              <a
                onClick={() =>
                  removeItemFromCart(indexItinerary, indexReservedPrice)
                }
              >
                <span className="material-symbols-rounded">close</span>
                {context.translate('cancel_room_reservation')}
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModifyBookingFooter;
