import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { deleteImpendingReservation } from "../services/booking";
import { ThemeContext } from "./Wrapper";
import { revertInventory } from "../services/room";

export default function TimeUp({
  isModalTimeUpOpened,
  setIsModalTimeUpOpened,
  searchRoom,
  searchRoomPayload
}) {
  const navigate = useNavigate();
  const context = useContext(ThemeContext)

  

  const handleClose = () => {
    setIsModalTimeUpOpened(false);
    const hotelName = context.getHotelName();
    localStorage.removeItem("cartExpirationDate");
    const urlParts = window.location.href.split(`/${hotelName}`);

    if (urlParts[1] !== '' && urlParts[1] !== '#') {
      context.setIsRedirectFromBookingForm(true);
      navigate(`/${hotelName}`);
    }

    // // delete all impending reservation of the cart
    deleteImpendingReservation({
      cart_id: localStorage.getItem('cartId'),
      check_in: '',
      check_out: '',
    })
      .then(result => {
        // console.log("deleteImpendingReservation: ", result)
      })

    if (localStorage.getItem("cart") && searchRoom) {
      const cart = JSON.parse(localStorage.getItem("cart"));
      searchRoom({
        searchRoomPayloadArg: {
          ...searchRoomPayload,
          dateDebut: cart[cart.length - 1].dateSejour.debut,
          dateFin: cart[cart.length - 1].dateSejour.fin,
        },
        doNotSetCalendarData: false,
      })
    }

    // window.location.reload();
  };

  return (
    <>
      {isModalTimeUpOpened ? (
        <div className="modal open">
          <div className="modal_inner" style={{maxWidth: '200px'}}>
            <label
              className="modal_close"
              htmlFor="modal-1"
              onClick={handleClose}
            ></label>
            <div className="modal_content">
              <div className="head_modal center">
                <img src="images/waiting.png" alt="" />
                <div className="h5">{context.translate('you_were_absent_for_a_while')}.</div>
              </div>
              <div className="cont_modal center">
                <p>{context.translate('do_you_want_to_update_your_research')}</p>
                <a
                  className="btn"
                  href="#"
                  onClick={handleClose}
                >
                  {context.translate('verify_the_disponibility')}
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : null}{" "}
    </>
  );
}
