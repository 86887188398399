import React, { useContext, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeaderBoard from "../../components/LeaderBoard";

import ValidateBookingForm from "../../components/booking/ValidateBookingForm";
import { ThemeContext } from "../../components/Wrapper";

// VAOVAO
import banner from "../../styles/parc/images/banner2.jpg";
import { useNavigate } from "react-router-dom";

const Booking = ({ urlHotelName }) => {
  const context = useContext(ThemeContext);
  const navigate = useNavigate();

  let cart = localStorage.getItem("cart");
  if (cart) cart = JSON.parse(cart);

  const setCartToTheLastElementOfCart = () => {
    let newCart = [];
    let cart = localStorage.getItem("cart");
    if (cart) {
      cart = JSON.parse(cart);
      const newCartTemp = cart[cart.length - 1];
      newCartTemp.tarifReserves = [];
      newCart.push(newCartTemp);
      context.cookies.set("cart", JSON.stringify(newCart), { path: "/" });
    }
  };

  useEffect(() => {
    // Scroll vers le haut lorsque le composant est monté ou rechargé
    window.scrollTo(0, 0);
    if (!cart) {
      // console.log('Booking tsy cart');
      navigate(`/${urlHotelName}`);
    }
    setCartToTheLastElementOfCart();
  }, []);

  return (
    <>
      <Header urlHotelName={urlHotelName} />
      <main>
        <LeaderBoard backgroundImage={banner} />
        {
          cart &&
          <ValidateBookingForm cart={cart} urlHotelName={urlHotelName} />
        }
      </main>
      <Footer />
    </>
  );
};

export default Booking;
