import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeaderBoard from "../../components/LeaderBoard";
import { useNavigate, useParams } from "react-router-dom";
import { ThemeContext } from "../../components/Wrapper";
import NavigationLocation from "../../components/NavigationLocation";
import {
  searchBookingByAccount,
  searchBookingByItinerary,
} from "../../services/booking";
import MyBookingItem from "../../components/my-booking/MyBookingItem";

const MyBooking = () => {
  const context = useContext(ThemeContext);

  const [bookings, setBookings] = useState([]);

  const [searchBookingError, setSearchBookingError] = useState({
    showSearchBookingError: false,
    message: "",
  });

  const navigate = useNavigate();

  const params = useParams();

  const searchMyBooking = () => {
    context.showError(true);
    context.showLoader(true);
    if (params.searchBy === "by-account") {
      const customerInformation = {
        email: params.email,
        mdp: params.itineraryNumberOrPassword,
      };
      searchBookingByAccount(customerInformation)
        .then((results) => {
          if (results.data.status === 200) {
            setBookings(results.data.itineraries);
          } else {
            setSearchBookingError({
              showSearchBookingError: true,
              message: results.data.message,
            });
          }
          context.showLoader(false);
        })
        .catch((error) => {
          context.showLoader(false);
          context.showError(false);
        });
    } else {
      const itineraryInformation = {
        email: params.email,
        numero: params.itineraryNumberOrPassword,
      };
      searchBookingByItinerary(itineraryInformation)
        .then((results) => {
          if (results.data.status === 200) {
            setBookings(results.data.itineraries);
          } else {
            setSearchBookingError({
              showSearchBookingError: true,
              message: results.data.message,
            });
          }
          context.showLoader(false);
        })
        .catch((error) => {
          context.showLoader(false);
          context.showError(false);
        });
    }
  };

  useEffect(() => {
    searchMyBooking();
  }, []);

  return (
    <>
      <Header />
      <main>
        <LeaderBoard />
        <section className="internalPage">
          <div className="wrapper">
            <NavigationLocation>
              <li>{context.translate("my_account")}</li>
              <li>{context.translate("reservation")}</li>
            </NavigationLocation>
            <div className="mxAuto">
              <div className="bookingList flex">
                {searchBookingError.showSearchBookingError ? (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {searchBookingError.message}
                  </p>
                ) : (
                  <MyBookingItem bookings={bookings} />
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default MyBooking;
