// require('dotenv').config();
const host = process.env.REACT_APP_BACK_URL;
// const host = "http://aiolia.us:3000"
// const host = "https://api.park.aiolia.us";
// const host = "http://localhost:3000";
// const host = "https://adr-hotel-back.herokuapp.com";

// const python_translator_host = "https://python-translator.herokuapp.com/v2";
const python_translator_host = "https://traduction1.aiolia.us/v2";
const python_translator_host_V1 = "https://traduction1.aiolia.us/v1";

/* axios.interceptors.response.use(undefined, (error) => {
  if (error.response) {
    console.log(error.response);
    if (error.response.status === 401) {
      console.log("UNAUTHORIZED");
      //   history.push('/login');
    }
  }
  return Promise.reject(error);
}); */

const exportedObject = {
  host,
  python_translator_host,
  python_translator_host_V1
};

export default exportedObject;
