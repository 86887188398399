import axios from "axios";
import api from "../config/api-config";

export const translate = (payload) => {
  return axios.post(
    `${api.python_translator_host}/translate
    `,
    payload,
    {
      /* headers: {
            Authorization: token,
        }, */
    }
  );
};
export const translate_v1 = (payload) => {
  return axios.post(
    `${api.python_translator_host_V1}/translate`, payload
  )
}
