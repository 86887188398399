import React, { useContext} from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ThemeContext } from "./Wrapper";

const NavigationSteps = ({ actualStep }) => {
  const navigate = useNavigate();
  const context = useContext(ThemeContext);
  const steps = [
    {
      value: 'plan your stay',
      label: context.translate('plan_your_trip'),
      link: `/${JSON.parse(sessionStorage.getItem("hotel")).urlName}`,
      active: false,
    },
    {
      value: 'enhance your stay',
      label: context.translate('enhance_your_stay'),
      active: false,
    },
    {
      value: 'your coordinates',
      label: context.translate('your_coordinates'),
      active: false,
    },
    {
      value: 'your voucher',
      label: context.translate('your_voucher'),
      active: false,
    }
  ];

  for (let i = 0; i < steps.length; i++) {
    if (steps[i].value === actualStep) {
      for (let u = i; u > -1; u--) {
        steps[u].active = true;
      }
      break;
    }
  }

  const changeStep = (link) => {
    if (!!link) {
      navigate(link);
    }
  };

  return (
    <div className="breadcrumb uppercase">
      <nav>
        <ul>
          {
            steps.map(({ value, label, link, active }) => (
            <li key={value}>
              <a 
                onClick={() => changeStep(link)} 
                className={active ? '' : 'greyedOut'}
              >
                {label}
              </a>
            </li>))
          }
        </ul>
      </nav>
    </div>
  );
};

export default NavigationSteps;