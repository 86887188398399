import React, { useContext, useEffect, useState } from "react";
import sortedNationality from "../../../data/nationality";
import { ThemeContext } from "../../Wrapper";
import PhoneInput from "react-phone-number-input";

const IndividualInfoForm = ({
    categoriePers,

    rate,
    itinerary,
    indexItinerary,
    indexReservedPrice,
    indexPers,
    occupierInformationErrors,
    customerInformation,
    setCustomerInformation,

    handleChange,
    setOccupierPhoneNumber,

    occupierIds,
    setOccupierIds,
}) => {
    const context = useContext(ThemeContext);
    const [selectedNationality, setSelectedNationality] = useState("");

    const setOccupierNationality = (inputName, inputValue) => {
        handleChange({
            target: {
                name: inputName,
                value: inputValue,
            }
        });
    }

    useEffect(() => {
        setOccupierIds((oldOccupierIds) => {
            return {
                ...oldOccupierIds,
                [`${indexItinerary}_${indexReservedPrice}_${indexPers}`]: rate.isNationaux && categoriePers === "adulte",
            }
        })
        const name = `occupier_tarif-${indexItinerary}_${indexReservedPrice}_${indexPers}`;
        setSelectedNationality(rate.isNationaux ? "Malgache" : "");
        handleChange({
            target: {
                name: name,
                value: rate,
            }
        });
        if (rate.isNationaux && categoriePers === "adulte") {
            handleChange({
                target: {
                    name: `occupier_cin-${indexItinerary}_${indexReservedPrice}_${indexPers}`,
                    value: "",
                }
            });
        }
    }, [])

    useEffect(() => {
        setOccupierNationality(
            `occupier_nationality-${indexItinerary}_${indexReservedPrice}_${indexPers}`,
            selectedNationality
        )
    }, [selectedNationality])

    useEffect(() => {
        setSelectedNationality(rate.isNationaux ? "Malgache" : customerInformation[`occupier_nationality-${indexItinerary}_${indexReservedPrice}_${indexPers}`] || "");
    }, [customerInformation])

    return (
        <>
            <div className="bloc-chp">
                <div className="blcChp col-50">
                    <label>{context.translate('nationality')} *</label>
                    <select
                        id={`occupier_nationality-${indexItinerary}_${indexReservedPrice}_${indexPers}`}
                        onChange={(e) => setSelectedNationality(e.target.value)}
                        value={selectedNationality}
                        disabled={rate.isNationaux ? true : false}
                    >
                        <option value=""></option>
                        {sortedNationality.map((nationality, index) => {
                            const value = nationality.demonyms.fra.m
                            if (value && value !== "") {
                                return (
                                    <option key={index} value={value}>{value}</option>
                                )
                            }
                        })}
                    </select>
                    {occupierInformationErrors[
                        `occupier_nationality-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                    ] && (
                            <div className="textError">
                                {
                                    occupierInformationErrors[
                                    `occupier_nationality-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                                    ]
                                }
                            </div>
                        )}
                </div>
                <div className="blcChp col-50">
                    {categoriePers === "adulte" && (rate.isNationaux || selectedNationality === "Malgache") &&
                        <>
                            <label>{context.translate('cin_number')} *</label>
                            <input
                                id={`occupier_cin-${indexItinerary}_${indexReservedPrice}_${indexPers}`}
                                name={`occupier_cin-${indexItinerary}_${indexReservedPrice}_${indexPers}`}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                value={
                                    customerInformation?.[
                                        `occupier_cin-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                                    ] ? customerInformation[`occupier_cin-${indexItinerary}_${indexReservedPrice}_${indexPers}`]
                                        : ''
                                }
                                disabled={categoriePers === "enfant" ? true : false}
                            />
                            {/* <span>Pour les nationalité malagasy uniquement.</span> */}
                            <div className="textHint">Pour les nationalité malagasy uniquement.</div>
                            {occupierInformationErrors[
                                `occupier_cin-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                            ] && (
                                    <div className="textError">
                                        {
                                            occupierInformationErrors[
                                            `occupier_cin-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                                            ]
                                        }
                                    </div>
                                )}
                        </>
                    }
                </div>
                <div className="blcChp col-50">
                    <label>{context.translate('name')} *</label>
                    <input
                        id={`occupier_name-${indexItinerary}_${indexReservedPrice}_${indexPers}`}
                        name={`occupier_name-${indexItinerary}_${indexReservedPrice}_${indexPers}`}
                        onChange={handleChange}
                        className={
                            occupierInformationErrors[
                                `occupier_name-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                            ]
                                ? "form-control errorForm"
                                : "form-control"
                        }
                        type="text"
                        value={
                            customerInformation?.[
                                `is_principal_client-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                            ] ? customerInformation?.booker_name
                                : customerInformation?.[
                                    `occupier_name-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                                ] ? customerInformation[`occupier_name-${indexItinerary}_${indexReservedPrice}_${indexPers}`]
                                    : ''
                        }
                    />
                    {occupierInformationErrors[
                        `occupier_name-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                    ] && (
                            <div className="textError">
                                {
                                    occupierInformationErrors[
                                    `occupier_name-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                                    ]
                                }
                            </div>
                        )}
                </div>
                <div className="blcChp col-50">
                    <label>{context.translate('first_name')} *</label>
                    <input
                        id={`occupier_first_name-${indexItinerary}_${indexReservedPrice}_${indexPers}`}
                        name={`occupier_first_name-${indexItinerary}_${indexReservedPrice}_${indexPers}`}
                        onChange={handleChange}
                        className={
                            occupierInformationErrors[
                                `occupier_first_name-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                            ]
                                ? "form-control errorForm"
                                : "form-control"
                        }
                        type="text"
                        value={
                            customerInformation?.[
                                `is_principal_client-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                            ] ? customerInformation?.booker_first_name
                                : customerInformation?.[
                                    `occupier_first_name-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                                ] ? customerInformation[`occupier_first_name-${indexItinerary}_${indexReservedPrice}_${indexPers}`]
                                    : ''
                        }
                    />
                    {occupierInformationErrors[
                        `occupier_first_name-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                    ] && (
                            <div className="textError">
                                {
                                    occupierInformationErrors[
                                    `occupier_first_name-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                                    ]
                                }
                            </div>
                        )}
                </div>

                <div class="blcChp col-100">
                    <label>{context.translate('email')} *</label>
                    <input
                        id={`occupier_email_confirmation-${indexItinerary}_${indexReservedPrice}_${indexPers}`}
                        name={`occupier_email_confirmation-${indexItinerary}_${indexReservedPrice}_${indexPers}`}
                        onChange={handleChange}
                        className={
                            occupierInformationErrors[
                                `occupier_email_confirmation-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                            ]
                                ? "form-control errorForm"
                                : "form-control"
                        }
                        type="email"
                        value={
                            customerInformation?.[
                                `is_principal_client-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                            ] ? customerInformation?.booker_email
                                : customerInformation?.[
                                    `occupier_email_confirmation-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                                ] ? customerInformation[`occupier_email_confirmation-${indexItinerary}_${indexReservedPrice}_${indexPers}`]
                                    : ''
                        }
                    />
                    {occupierInformationErrors[
                        `occupier_email_confirmation-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                    ] && (
                            <div className="textError">
                                {
                                    occupierInformationErrors[
                                    `occupier_email_confirmation-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                                    ]
                                }
                            </div>
                        )}
                    <p className="info">
                        {context.translate(
                            "this_is_the_email_address_to_which_your_confirmation_will_be_sent"
                        )}{" "}
                        .
                    </p>
                </div>
                <div class="blcChp tel col-50" id={`occupier_phone-${indexItinerary}_${indexReservedPrice}_${indexPers}`}>
                    <label class="show">{context.translate("portable_phone")}</label>
                    <PhoneInput
                        className={
                            occupierInformationErrors[
                                `occupier_phone-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                            ]
                                ? "form-control errorForm"
                                : "form-control"
                        }
                        value={
                            customerInformation?.[
                                `is_principal_client-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                            ] ? customerInformation?.booker_phone
                                : customerInformation?.[
                                    `occupier_phone-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                                ] ? customerInformation[`occupier_phone-${indexItinerary}_${indexReservedPrice}_${indexPers}`]
                                    : ''
                        }
                        onChange={(phoneNumber) => setOccupierPhoneNumber(
                            `occupier_phone-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                            , phoneNumber
                        )}
                    />
                    {occupierInformationErrors[
                        `occupier_phone-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                    ] && (
                            <div className="textError">
                                {
                                    occupierInformationErrors[
                                    `occupier_phone-${indexItinerary}_${indexReservedPrice}_${indexPers}`
                                    ]
                                }
                            </div>
                        )}
                </div>

                {/* <div className="blcChp blcBtn col-100">
                    <input type="button" className="btn-primary" value="suivant" />
                    <a href="#" className="link" title="retour / annuler la réservation">retour / annuler la réservation</a>
                </div> */}
            </div>
        </>
    )
}

export default IndividualInfoForm;