import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "./Wrapper";

const NavigationLocation = ({
    urlHotelName,
    children }) => {
    const navigate = useNavigate();
    const context = useContext(ThemeContext);
    return (
        // <div className="breadcrumb uppercase">
        //     <nav>
        //         <ul>
        //             <li>
        //                 <a
        //                     onClick={() => {
        //                         navigate(`/${context.getHotel().name}`);
        //                     }}
        //                 >
        //                     {context.translate('plan_your_trip')}
        //                 </a>
        //             </li>
        //             {children}
        //         </ul>
        //     </nav>
        // </div>
        <section className="sec-breadcrumb">
            <div className="container">
                <div className="breadcrumb wow fadeIn" data-wow-delay="800ms" data-wow-duration="1000ms">
                    <nav>
                        <span>Vous êtes ici : </span>
                        <ul>
                            <li>
                                <a
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        navigate(`/${urlHotelName}`);
                                    }}
                                >
                                    {context.translate('plan_your_trip')}
                                </a>
                            </li>
                            {children}
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
    );
};

export default NavigationLocation;