import React, { useContext, useEffect, useState } from "react";
// import logo from "../assets/img/logo.jpg";
import { useNavigate } from "react-router-dom";
import LoginForm from "./account/LoginForm";
import { ThemeContext } from "./Wrapper";
import api from "../config/api-config";
// import ibis from "../assets/img/logo.jpg";
// import colbert from "../assets/img/colbert.png";
// import leLouvre from "../assets/img/le-louvre.png";
import MalagasyFlag from "../assets/img/mg.png";
import FrenchFlag from "../assets/img/fr.png";
import UnitedKingdomFlag from "../assets/img/gb.png";
import DeutscheFlag from "../assets/img/de.png";
import SpanishFlag from "../assets/img/es.png";
import { event } from "jquery";

const Header = ({
  urlHotelName,
  showResultForSearchBooking,
  setShowResultForSearchBooking
}) => {
  const context = useContext(ThemeContext);
  const navigate = useNavigate();
  const language = localStorage.getItem("lang") || "fr";
  const [image, setImage] = useState(null);
  const [background, setBackground] = useState("none");
  const [openSelectCurrency, setOpenSelectCurrency] = useState(false);
  const [openSelectLanguage, setOpenSelectLanguage] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("currency")) {
      const defaultCurrency = {
        name: "euro",
        value: 1,
        symbol: '€',
      };
      localStorage.setItem(
        "currency",
        JSON.stringify(defaultCurrency)
      );
      context.setCurrency(defaultCurrency);
    }
    if (context.getHotel().logo !== "") {
      setImage(`${api.host}/${context.getHotel().logo}`);
      setBackground(
        context.getHotel().urlName !== "ibis"
          ? context.getHotel().theme.btn.primary
          : "none"
      );
    }
  }, [context.getHotel()]);

  const languages = [
    {
      flag: MalagasyFlag,
      abbrev: 'mg',
      name: 'Malagasy'
    },
    {
      flag: FrenchFlag,
      abbrev: 'fr',
      name: 'Français',
    },
    {
      flag: UnitedKingdomFlag,
      abbrev: 'en',
      name: 'English'
    },
    {
      flag: '',
      abbrev: 'es',
      name: 'Italian'
    }
  ];

  const redirectToSearchBooking = () => {
    if (showResultForSearchBooking === true) {
      setShowResultForSearchBooking(false);
    }
    navigate(`/${urlHotelName}/search-booking`);
  }

  return (
    <>
      <header>
        <div className="wrapper flex flexBetween">
          <div className="blcLeft flex">
            <select onChange={(event) => {
              context.onChangeLanguage(event.target.value);
            }} className="lang wow fadeInDown" data-wow-delay="800ms" data-wow-duration="2000ms">
              <option value="">{context.translate('language')}</option>
              {
                languages.map(language => (
                  <option value={language.abbrev} key={language.name}>
                    {language.name}
                  </option>
                ))
              }
            </select>
          </div>
          <div className="blcRight flex wow fadeInDown" data-wow-delay="800ms" data-wow-duration="2000ms">
            <a
              onClick={() => redirectToSearchBooking()}
              style={{ cursor: 'pointer' }}
              className="btn">
              <span></span>
              <i>{context.translate("search_reservation")}</i>
            </a>

            <LoginForm urlHotelName={urlHotelName} />

            {/* <div className="btn loginBox">
              <a href="#"><span></span><i>{context.translate("login")}</i></a>
              <div className="">
                <div className="h3">{context.translate("login_with_your_account")}</div>
                <div className="contFormControl mail">
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Email"
                    name="email"
                    autoComplete="false" />
                </div>
                <div className="contFormControl password">
                  <input className="form-control" id="password" type="password" placeholder="Mot de passe" name="password" autoComplete="false" /><span className="material-symbols-rounded pointer toggle-password"></span>
                </div>
                <a href="#" className="forgotPsw">{context.translate("forgotten_password")}</a>
                <a className="btn-primary">{context.translate("login")}</a>

                <a className="pas-de-compte">
                  {context.translate("no_online_account")}
                </a>
                <div className="inscrit">
                  <p>
                    <a style={{
                      cursor: 'pointer'
                    }}
                      onClick={() => {
                        navigate(`/${urlHotelName}/register`)
                      }}
                    >{context.translate("register_today")}</a> {context.translate('to_gain_more_time_during_your_next_reservation')}.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
