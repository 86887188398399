import React, { useContext, useState } from "react";
import { useLocation, useNavigate, } from "react-router-dom";

import { ThemeContext } from "../../Wrapper";
import Header from "../../Header";
import LeaderBoard from "../../LeaderBoard";
import NavigationLocation from "../../NavigationLocation";

// import nosyTanyHely from "../../styles/parc/images/banner.jpg";
import nosyTanyKely from "../../../styles/parc/images/banner.jpg"
import ReportCalendar from './ReportCalendar';
import { postponeBooking } from "../../../services/booking";

const ReservationReportComponent = ({
    urlHotelName
}) => {
    const context = useContext(ThemeContext);
    const navigate = useNavigate();
    const location = useLocation();
    const reservationDetail = location.state.reservationDetail;

    const [message, setMessage] = useState('');

    const [newDate, setNewDate] = useState(reservationDetail.dateReport || reservationDetail.dateEntree);

    const postpone = () => {
        const payload = {
            newDate,
            reservationDetail: reservationDetail,
        }
        postponeBooking(payload)
            .then(result => {
                // console.log("postponeBooking result: ", result);
                setMessage('');
                navigate(`/${urlHotelName}/search-booking`);
            })
            .catch(error => {
                // console.log("postponeBooking error: ", error);
                setMessage(error.response.data.message);
            })
    }

    return (
        <>
            <Header urlHotelName={urlHotelName} />
            <main>
                <LeaderBoard backgroundImage={nosyTanyKely} />
            </main>

            <section className="contentPage">
                <NavigationLocation urlHotelName={urlHotelName}>
                    <li><span>{context.translate('postpone')}</span></li>
                </NavigationLocation>
                <div className="container flex flexBetween">

                    {/* <div className="leftCol col" data-wow-delay="800ms" data-wow-duration="3500ms">
                        <div className="blcCalandar">
                            <h2>{context.translate('calendar_of_park')} Nosy Tanihely</h2>
                            <ReportCalendar />
                        </div>
                    </div> */}

                    <div className="blcFormulaire wow">
                        <form>
                            <h3>{context.translate("postpone")} ticket {reservationDetail.numero}</h3>
                            <div className="bloc-chp">

                                <div className="blcChp col-100">
                                    <label>Nouvelle date</label>
                                    <input
                                        className="form-control"
                                        value={newDate}
                                        onChange={(e) => setNewDate(e.target.value)}
                                        type="date"
                                    />
                                </div>

                                <div className="blcChp col-100">
                                    <div style={{ color: 'red' }}>{message}</div>
                                    <input
                                        type="button"
                                        className="btn-primary"
                                        value={context.translate('postpone')}
                                        onClick={postpone}
                                    />
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ReservationReportComponent;