import React from "react";
import RegisterForm from "../../components/account/RegisterForm";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import LeaderBoard from "../../components/LeaderBoard";

import banner from "../../styles/parc/images/banner2.jpg";

const Register = ({
  urlHotelName
}) => {
  return (
    <>
      <Header urlHotelName={urlHotelName} />
      <main>
        <LeaderBoard backgroundImage={banner} />
        <RegisterForm urlHotelName={urlHotelName} />
      </main>
      <Footer />
    </>
  );
};

export default Register;
