import React, { useEffect } from 'react';
import Header from '../../components/Header';
import LeaderBoard from '../../components/LeaderBoard';

// VAOVAO
import banner from "../../styles/parc/images/banner2.jpg";
import Footer from '../../components/Footer';
import ClientVoucherComponent from '../../components/booking/Parc/ClientVoucherComponent';
import { useLocation } from 'react-router-dom';

const ClientVoucher = ({
    urlHotelName
}) => {
    const location = useLocation();
    const reservationDetails = location.state.reservationDetails;
    useEffect(() => {
        // Scroll vers le haut lorsque le composant est monté ou rechargé
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Header urlHotelName={urlHotelName}></Header>
            <main>
                <LeaderBoard backgroundImage={banner} />
                <ClientVoucherComponent urlHotelName={urlHotelName} reservationDetails={reservationDetails} />
            </main>
            <Footer />
        </>
    );
};

export default ClientVoucher;