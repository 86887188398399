import React from "react";
import Header from "../../components/Header";
import LeaderBoard from "../../components/LeaderBoard";
import Footer from "../../components/Footer";
import nosyTanyHely from "../../styles/parc/images/banner-2.jpeg";

const GeneralConditionsOfSale = () => {
  let hotelName = '';
  try {
    // hotelName = JSON.parse(sessionStorage.getItem("hotel")).name;
    hotelName = 'Nosy Tanihely';
  } catch (err) {
    console.error(err);
    hotelName = 'Colbert';
  }
  return (
    <div>
      <Header />
      <main>
        <LeaderBoard backgroundImage={nosyTanyHely} />
        <div className="infoGeneralConditionOfSale">
          <div>
            <div className="headerLegalNotice">Conditions générales de vente</div>
            <div>{hotelName}</div>
          </div>
          <div>1.Objet</div>
          <div>Les présentes conditions générales ont pour objet de régir les modalités de réservation et d'accès aux services proposés par le Parc National Tanikely, ci-après désigné "le Parc". Elles établissent les droits et les obligations des parties contractantes et s'imposent à tout client souhaitant effectuer une réservation auprès du Parc.</div>

          <div>
            <div>Réservation</div>
            <div>La réservation des services du Parc s'effectue exclusivement en ligne via notre plateforme de réservation et au bureau de Nosy Tanikely. En validant sa réservation, le client reconnaît avoir pris connaissance et accepté l'intégralité des présentes conditions générales de vente, ainsi que des conditions spécifiques liées à la réservation effectuée.</div>
          </div>
          <div>
            <div>Processus de réservation</div>
            <div>La réservation devient effective dès lors que le paiement est effectué par le client. Une confirmation de réservation est alors envoyée au client par voie électronique, récapitulant les détails de sa réservation ainsi que les conditions tarifaires applicables.</div>
          </div>
          <div>
            <div>Modification de la Réservation</div>
            <div>Le client bénéficie de la possibilité de modifier sa réservation jusqu'à deux fois, et ce jusqu'à 2 jours avant la date prévue de la réservation. Toute demande de modification doit être formulée en ligne via notre plateforme de réservation et est soumise à la disponibilité des créneaux horaires disponibles.</div>
          </div>
          <div>
            <div>Annulation et Remboursement</div>
            <div>Conformément aux présentes conditions générales, toute réservation est ferme et définitive. Aucune annulation ne sera acceptée après la confirmation de la réservation, sauf en cas de force majeure. En cas d'annulation due à un événement de force majeure, le client pourra prétendre à un remboursement intégral du montant payé. Les événements considérés comme des cas de force majeure incluent, mais ne se limitent pas à :
              <ol style={{
                marginLeft: 100
              }}>
                <li>Catastrophes naturelles telles que les ouragans, les tremblements de terre, les inondations, les incendies de forêt, etc.</li>
                <li>Guerres, conflits armés ou actes de terrorisme.</li>
                <li>Épidémies ou pandémies.</li>
              </ol>
            </div>
          </div>
          <div>
            <div>Consommation de la prestation</div>
            <div>Le client s'engage à respecter scrupuleusement le règlement intérieur, les règles de sécurité et les consignes édictées par le Parc. En cas de non-respect de ces règles, le Parc se réserve le droit de refuser l'accès aux installations sans préavis ni remboursement.</div>
          </div>
          <div>
            <div>Responsabilité</div>
            <div>
              <p>
                Le Parc décline toute responsabilité en cas de vol, de perte ou de dommage survenant aux biens du client pendant la visite. Le client reconnaît et accepte les risques inhérents à la pratique des activités proposées et décharge le Parc de toute responsabilité en cas d’accident.
              </p>
              <p>
                L'ensemble des documents de réservation doit être présenté soit sur support numérique via smartphone à l'arrivée au Parc, soit sous format papier imprimé par le Client avec une qualité d'impression adéquate. Le Client est tenu de vérifier la qualité d'impression en s'assurant que les informations figurant sur le billet et/ou les autres documents ainsi que le code-barres sont clairement lisibles.
                Tout document de paiement de réservation partiellement imprimé, souillé, endommagé ou illisible ne pourra être validé et sera considéré de fait comme non valide. En conséquence, le client ne pourra prétendre à aucune compensation résultant de cette invalidité.
              </p>

            </div>
          </div>
          <div>
            <div>Réclamations</div>
            <div>Toute réclamation relative aux prestations fournies par le Parc doit être formulée par écrit dans un délai de huit jours suivant la visite et déposée auprès de son bureau administratif. Passé ce délai, aucune réclamation ne pourra être prise en compte.</div>
          </div>
          <div>
            <div>Prix et Paiement</div>
            <div>Les prix des services indiqués sur notre plateforme de réservation sont fermes et non négociables. Le paiement s'effectue en ligne au moment de la réservation et est considéré comme définitif et irrévocable.</div>
          </div>
          <div>
            <div>Respect de la vie privée</div>
            <div>Le Parc s'engage à respecter la vie privée et la confidentialité des informations personnelles fournies par le client. Ces données ne seront en aucun cas transmises à des tiers sans le consentement explicite du client.<a className="hyperlinkAIOLIA" target="_blank" href="http://park.aiolia.us/io" rel="noreferrer"><span className="yellowAIOLIA">park.aiolia.us/io</span></a>, à ses entités, à ses partenaires, à ses prestataires (et notamment aux prestataires de paiement en ligne). Le client autorise <a className="hyperlinkAIOLIA" target="_blank" href="http://park.aiolia.us/io" rel="noreferrer"><span className="yellowAIOLIA">park.aiolia.us/io</span></a> à communiquer ses données personnelles à des tiers à la condition qu’une telle communication se révèle compatible avec la réalisation des opérations incombant à elloha.com au titre des présentes conditions générales et en lien avec la Charte clients de protection des données personnelles. En particulier lors du paiement en ligne, les coordonnées bancaires du client devront être transmises par le prestataire de paiement <span className="boldSocieteGenerale">Société Générale</span>  à la banque de l’établissement, pour l’exécution du contrat de réservation. Le client est informé que ce transfert de données peut donc s’exécuter dans des pays étrangers ne disposant pas d’une protection des données personnelles adéquate au sens de la loi Informatique et Libertés. Cependant, le client consent à ce transfert nécessaire pour l’exécution de sa réservation. <span className="boldSocieteGenerale">(Société Générale/Park.aiolia)</span> en leur qualité de professionnel, se sont engagés vis-à-vis de l’établissement à prendre toutes les mesures de sécurité et de respect de la confidentialité des données pour lesdits transferts de données.</div>
          </div>
          <div>
            <div>Convention de preuve</div>
            <div>La validation de la réservation par le client constitue une acceptation électronique des présentes conditions générales. Les registres informatisés conservés par le Parc font foi de la réservation et de l'acceptation des conditions générales par le client.<a className="hyperlinkAIOLIA" target="_blank" href="http://park.aiolia.us/io" rel="noreferrer"><span className="yellowAIOLIA">park.aiolia.us/io</span></a> seront conservés dans des conditions raisonnables de sécurité et considérés comme les preuves des communications, des commandes et des paiements intervenus entre les parties. Le client est informé que son adresse IP est enregistrée au moment de la réservation.</div>
          </div>
          <div>
            <div>Force majeure</div>
            <div>Aucune des parties ne pourra être tenue responsable de l'inexécution de ses obligations en cas de force majeure, telle que définie par la loi. Les parties sont exonérées de leur responsabilité en cas de survenance d'un tel événement.</div>
          </div>
          <div>
            <div>Règlement des litiges</div>
            <div>Tout litige relatif à l'interprétation ou à l'exécution des présentes conditions générales sera soumis à la compétence exclusive des tribunaux compétents d’Antananarivo - Madagascar.</div>
          </div>
          <div>
            <div>Intégralité</div>
            <div>Les présentes conditions générales expriment l'intégralité des obligations des parties et prévalent sur tout autre document. Aucune condition générale ou spécifique communiquée par le client ne pourra s'intégrer aux présentes conditions générales sans l'accord écrit et préalable du Parc.</div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );

};

export default GeneralConditionsOfSale;