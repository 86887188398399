import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeaderBoard from "../../components/LeaderBoard";
import SearchBookingByAccount from "../../components/search-booking/SearchBookingByAccount";
import SearchBookingByItinerary from "../../components/search-booking/SearchBookingByItinerary";
import { ThemeContext } from "../../components/Wrapper";
import NavigationLocation from "../../components/NavigationLocation";
import {
  searchBookingByAccount,
  searchBookingByItinerary,
} from "../../services/booking";
import MyBookingItem from "../../components/my-booking/MyBookingItem";
import banner from "../../styles/parc/images/banner2.jpg";
import ClientReservationComponent from "../../components/my-booking/Parc/ClientReservationComponent";

import ico1 from "../../styles/parc/images/ico1.png";
import ico3 from "../../styles/parc/images/ico3.png";
import ico4 from "../../styles/parc/images/ico4.png";

const SearchBooking = ({
  urlHotelName
}) => {
  const [showResultForSearchBooking, setShowResultForSearchBooking] = useState(
    false
  );

  const context = useContext(ThemeContext);

  const [bookings, setBookings] = React.useState([]);

  const [searchBookingError, setSearchBookingError] = React.useState({
    showSearchBookingError: false,
    isErrorFindByAccount: true,
    message: "",
  });

  const searchMyBooking = (searchBy, email, itineraryNumberOrPassword) => {
    context.showError(true);
    context.showLoader(true);
    if (searchBy === "by-account") {
      const customerInformation = {
        email: email,
        mdp: itineraryNumberOrPassword,
      };
      searchBookingByAccount(customerInformation)
        .then((results) => {
          if (results.data.status === 200 && results.data.itineraries) {
            setShowResultForSearchBooking(true);
            setBookings(results.data.itineraries);
          } else {
            setSearchBookingError({
              showSearchBookingError: true,
              isErrorFindByAccount: true,
              message: "Nous vous prions de nous excuser. Nous ne trouvons pas votre réservation. Veuillez vérifier les informations saisies, puis réessayez.",
            });
          }
          context.showLoader(false);
        })
        .catch((error) => {
          context.showLoader(false);
          context.showError(false);
        });
    } else {
      const itineraryInformation = {
        email: email,
        numero: itineraryNumberOrPassword,
      };
      searchBookingByItinerary(itineraryInformation)
        .then((results) => {
          console.log("searchBookingByItinerary results: ", results.data.itineraries);
          if (results.data.status === 200 && results.data.itineraries) {
            setShowResultForSearchBooking(true);
            setBookings(results.data.itineraries);
          } else {
            setSearchBookingError({
              showSearchBookingError: true,
              isErrorFindByAccount: false,
              message: "Nous vous prions de nous excuser. Nous ne trouvons pas votre réservation. Veuillez vérifier les informations saisies, puis réessayez.",
            });
          }
          context.showLoader(false);
        })
        .catch((error) => {
          context.showLoader(false);
          context.showError(false);
        });
    }
  };

  const setParamsForSearchBooking = (
    searchBy,
    email,
    itineraryNumberOrPassword
  ) => {
    searchMyBooking(searchBy, email, itineraryNumberOrPassword);
  };

  useEffect(() => {
    // Scroll vers le haut lorsque le composant est monté ou rechargé
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header urlHotelName={urlHotelName} showResultForSearchBooking={showResultForSearchBooking} setShowResultForSearchBooking={setShowResultForSearchBooking} />
      <main>
        <LeaderBoard backgroundImage={banner} />

        {showResultForSearchBooking ? (
          <>
            <ClientReservationComponent context={context} clientReservations={bookings} setClientReservations={setBookings} urlHotelName={urlHotelName} />
          </>
          // <section className="internalPage">
          //   <div className="wrapper">
          //     <NavigationLocation>
          //       <li>{context.translate("my_account")}</li>
          //       <li>{context.translate("reservation")}</li>
          //     </NavigationLocation>
          //     <div className="mxAuto">
          //       <div className="bookingList flex">
          //         <MyBookingItem bookings={bookings} />
          //       </div>
          //     </div>
          //   </div>
          // </section>
        ) : (
          <>
            <NavigationLocation urlHotelName={urlHotelName}>
              <li><span>{context.translate('login')}</span></li>
            </NavigationLocation>
            <section className="contentPage  pageInt recherche-reservation">
              <div class="deco">
                <div class="ico-para ico1 trigger_parallax01">
                  <img src={ico1} />
                </div>
                <div class="ico-para ico5 trigger_parallax01">
                  <img src={ico3} />
                </div>
                <div class="ico-para ico4 trigger_parallax01">
                  <img src={ico4} />
                </div>
              </div>
              <div className="container">
                <h2 className="wow fadeIn" data-wow-delay="800ms" data-wow-duration="2000ms">{context.translate('search_reservation')}</h2>
                <div className="boxCenter flex flexBetween col2Conex">
                  <SearchBookingByAccount
                    urlHotelName={urlHotelName}
                    setParamsForSearchBooking={setParamsForSearchBooking}
                    searchBookingError={searchBookingError}
                  />
                  <SearchBookingByItinerary
                    urlHotelName={urlHotelName}
                    setParamsForSearchBooking={setParamsForSearchBooking}
                    searchBookingError={searchBookingError}
                  />
                </div>
              </div>
            </section>
          </>

        )}
      </main>
      <Footer />
    </>
  );
};

export default SearchBooking;
