import React,{useContext} from 'react';
import { ThemeContext } from '../../Wrapper';

const RateState = ({ state, }) => {
  const context = useContext(ThemeContext)
  return (
    <div>
      { state && <p><strong>{context.translate('room_status')} : { context.translate(state.label) } {context.translate('the').toLowerCase()} { state.date }</strong></p> }
    </div>
  );
};

export default RateState;