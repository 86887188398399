const commonNationality = [
    {
        "demonyms": {
            "eng": {
                "f": "French",
                "m": "French"
            },
            "fra": {
                "f": "Française",
                "m": "Français"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "British",
                "m": "British"
            },
            "fra": {
                "f": "Britannique",
                "m": "Britannique"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "American",
                "m": "American"
            },
            "fra": {
                "f": "Américaine",
                "m": "Américain"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Italian",
                "m": "Italian"
            },
            "fra": {
                "f": "Italienne",
                "m": "Italien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Bulgarian",
                "m": "Bulgarian"
            },
            "fra": {
                "f": "Bulgare",
                "m": "Bulgare"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Polish",
                "m": "Polish"
            },
            "fra": {
                "f": "Polonaise",
                "m": "Polonais"
            }
        }
    },
]

const nationalityData = [
    {
        "demonyms": {
            "eng": {
                "f": "Andorran",
                "m": "Andorran"
            },
            "fra": {
                "f": "Andorrane",
                "m": "Andorran"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "French",
                "m": "French"
            },
            "fra": {
                "f": "Française",
                "m": "Français"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Laotian",
                "m": "Laotian"
            },
            "fra": {
                "f": "Laotienne",
                "m": "Laotien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Canadian",
                "m": "Canadian"
            },
            "fra": {
                "f": "Canadienne",
                "m": "Canadien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Nigerian",
                "m": "Nigerian"
            },
            "fra": {
                "f": "Nigériane",
                "m": "Nigérian"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Ni-Vanuatu",
                "m": "Ni-Vanuatu"
            },
            "fra": {
                "f": "Vanuatuane",
                "m": "Vanuatuan"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Czech",
                "m": "Czech"
            },
            "fra": {
                "f": "Tchèque",
                "m": "Tchèque"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Malawian",
                "m": "Malawian"
            },
            "fra": {
                "f": "Malawienne",
                "m": "Malawien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Malian",
                "m": "Malian"
            },
            "fra": {
                "f": "Malienne",
                "m": "Malien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Icelander",
                "m": "Icelander"
            },
            "fra": {
                "f": "Islandaise",
                "m": "Islandais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Norwegian",
                "m": "Norwegian"
            },
            "fra": {
                "f": "Norvégienne",
                "m": "Norvégien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Saint Vincentian",
                "m": "Saint Vincentian"
            },
            "fra": {
                "f": "Vincentaise",
                "m": "Vincentais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Guadeloupian",
                "m": "Guadeloupian"
            },
            "fra": {
                "f": "Guadeloupéenne",
                "m": "Guadeloupéen"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Chilean",
                "m": "Chilean"
            },
            "fra": {
                "f": "Chilienne",
                "m": "Chilien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Bermudian",
                "m": "Bermudian"
            },
            "fra": {
                "f": "Bermudienne",
                "m": "Bermudien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Kuwaiti",
                "m": "Kuwaiti"
            },
            "fra": {
                "f": "Koweïtienne",
                "m": "Koweïtien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Dominican",
                "m": "Dominican"
            },
            "fra": {
                "f": "Dominiquaise",
                "m": "Dominiquais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Montenegrin",
                "m": "Montenegrin"
            },
            "fra": {
                "f": "Monténégrine",
                "m": "Monténégrin"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Virgin Islander",
                "m": "Virgin Islander"
            },
            "fra": {
                "f": "",
                "m": ""
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Cameroonian",
                "m": "Cameroonian"
            },
            "fra": {
                "f": "Camerounaise",
                "m": "Camerounais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Sri Lankan",
                "m": "Sri Lankan"
            },
            "fra": {
                "f": "Sri-lankaise",
                "m": "Sri-lankais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Chinese",
                "m": "Chinese"
            },
            "fra": {
                "f": "Chinoise",
                "m": "Chinois"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Bangladeshi",
                "m": "Bangladeshi"
            },
            "fra": {
                "f": "Bangladaise",
                "m": "Bangladais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Swedish",
                "m": "Swedish"
            },
            "fra": {
                "f": "Suédoise",
                "m": "Suédois"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Grenadian",
                "m": "Grenadian"
            },
            "fra": {
                "f": "Grenadienne",
                "m": "Grenadien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Turkish",
                "m": "Turkish"
            },
            "fra": {
                "f": "Turque",
                "m": "Turc"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Guinean",
                "m": "Guinean"
            },
            "fra": {
                "f": "Guinéenne",
                "m": "Guinéen"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Tanzanian",
                "m": "Tanzanian"
            },
            "fra": {
                "f": "Tanzanienne",
                "m": "Tanzanien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Rwandan",
                "m": "Rwandan"
            },
            "fra": {
                "f": "Rwandaise",
                "m": "Rwandais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Singaporean",
                "m": "Singaporean"
            },
            "fra": {
                "f": "Singapourienne",
                "m": "Singapourien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Moroccan",
                "m": "Moroccan"
            },
            "fra": {
                "f": "Marocaine",
                "m": "Marocain"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Saint Barthélemy Islander",
                "m": "Saint Barthélemy Islander"
            },
            "fra": {
                "f": "Barthéloméenne",
                "m": "Barthéloméen"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Iraqi",
                "m": "Iraqi"
            },
            "fra": {
                "f": "Irakienne",
                "m": "Irakien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Bruneian",
                "m": "Bruneian"
            },
            "fra": {
                "f": "Brunéienne",
                "m": "Brunéien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Manx",
                "m": "Manx"
            },
            "fra": {
                "f": "",
                "m": ""
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "North Korean",
                "m": "North Korean"
            },
            "fra": {
                "f": "Nord-coréenne",
                "m": "Nord-coréen"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Iranian",
                "m": "Iranian"
            },
            "fra": {
                "f": "Iranienne",
                "m": "Iranien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Curaçaoan",
                "m": "Curaçaoan"
            },
            "fra": {
                "f": "Curacienne",
                "m": "Curacien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Paraguayan",
                "m": "Paraguayan"
            },
            "fra": {
                "f": "Paraguayenne",
                "m": "Paraguayen"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Albanian",
                "m": "Albanian"
            },
            "fra": {
                "f": "Albanaise",
                "m": "Albanais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Tadzhik",
                "m": "Tadzhik"
            },
            "fra": {
                "f": "Tadjike",
                "m": "Tadjike"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Bolivian",
                "m": "Bolivian"
            },
            "fra": {
                "f": "Bolivienne",
                "m": "Bolivien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Austrian",
                "m": "Austrian"
            },
            "fra": {
                "f": "Autrichienne",
                "m": "Autrichien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Kittitian or Nevisian",
                "m": "Kittitian or Nevisian"
            },
            "fra": {
                "f": "Kittitienne-et-nevicienne",
                "m": "Kittitien-et-nevicien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "American Islander",
                "m": "American Islander"
            },
            "fra": {
                "f": "",
                "m": ""
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Colombian",
                "m": "Colombian"
            },
            "fra": {
                "f": "Colombienne",
                "m": "Colombien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Kosovar",
                "m": "Kosovar"
            },
            "fra": {
                "f": "Kosovare",
                "m": "Kosovar"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Belizean",
                "m": "Belizean"
            },
            "fra": {
                "f": "Bélizienne",
                "m": "Bélizien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Guinea-Bissauan",
                "m": "Guinea-Bissauan"
            },
            "fra": {
                "f": "Bissau-Guinéenne",
                "m": "Bissau-Guinéen"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Marshallese",
                "m": "Marshallese"
            },
            "fra": {
                "f": "Marshallaise",
                "m": "Marshallais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Burmese",
                "m": "Burmese"
            },
            "fra": {
                "f": "Birmane",
                "m": "Birman"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "French Polynesian",
                "m": "French Polynesian"
            },
            "fra": {
                "f": "Polynésienne",
                "m": "Polynésien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Brazilian",
                "m": "Brazilian"
            },
            "fra": {
                "f": "Brésilienne",
                "m": "Brésilien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Croatian",
                "m": "Croatian"
            },
            "fra": {
                "f": "Croate",
                "m": "Croate"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Somali",
                "m": "Somali"
            },
            "fra": {
                "f": "Somalienne",
                "m": "Somalien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Afghan",
                "m": "Afghan"
            },
            "fra": {
                "f": "Afghane",
                "m": "Afghan"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Anguillian",
                "m": "Anguillian"
            },
            "fra": {
                "f": "Anguillane",
                "m": "Anguillan"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Cook Islander",
                "m": "Cook Islander"
            },
            "fra": {
                "f": "Cookienne",
                "m": "Cookien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Sahrawi",
                "m": "Sahrawi"
            },
            "fra": {
                "f": "",
                "m": ""
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "New Zealander",
                "m": "New Zealander"
            },
            "fra": {
                "f": "Neo-Zélandaise",
                "m": "Neo-Zélandais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Eritrean",
                "m": "Eritrean"
            },
            "fra": {
                "f": "Érythréenne",
                "m": "Érythréen"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Cambodian",
                "m": "Cambodian"
            },
            "fra": {
                "f": "Cambodgienne",
                "m": "Cambodgien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Bahamian",
                "m": "Bahamian"
            },
            "fra": {
                "f": "Bahamienne",
                "m": "Bahamien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Belarusian",
                "m": "Belarusian"
            },
            "fra": {
                "f": "Biélorusse",
                "m": "Biélorusse"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Norfolk Islander",
                "m": "Norfolk Islander"
            },
            "fra": {
                "f": "Norfolkaise",
                "m": "Norfolkais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Tuvaluan",
                "m": "Tuvaluan"
            },
            "fra": {
                "f": "Tuvaluane",
                "m": "Tuvaluan"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "South Georgian South Sandwich Islander",
                "m": "South Georgian South Sandwich Islander"
            },
            "fra": {
                "f": "",
                "m": ""
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Mauritanian",
                "m": "Mauritanian"
            },
            "fra": {
                "f": "Mauritanienne",
                "m": "Mauritanien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "New Caledonian",
                "m": "New Caledonian"
            },
            "fra": {
                "f": "Néo-Calédonienne",
                "m": "Néo-Calédonien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Bulgarian",
                "m": "Bulgarian"
            },
            "fra": {
                "f": "Bulgare",
                "m": "Bulgare"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Mozambican",
                "m": "Mozambican"
            },
            "fra": {
                "f": "Mozambicaine",
                "m": "Mozambicain"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Niuean",
                "m": "Niuean"
            },
            "fra": {
                "f": "Niuéenne",
                "m": "Niuéen"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Estonian",
                "m": "Estonian"
            },
            "fra": {
                "f": "Estonienne",
                "m": "Estonien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Italian",
                "m": "Italian"
            },
            "fra": {
                "f": "Italienne",
                "m": "Italien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Maltese",
                "m": "Maltese"
            },
            "fra": {
                "f": "Maltaise",
                "m": "Maltais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Slovene",
                "m": "Slovene"
            },
            "fra": {
                "f": "Slovène",
                "m": "Slovène"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Indian",
                "m": "Indian"
            },
            "fra": {
                "f": "Indienne",
                "m": "Indien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Peruvian",
                "m": "Peruvian"
            },
            "fra": {
                "f": "Péruvienne",
                "m": "Péruvien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Burundian",
                "m": "Burundian"
            },
            "fra": {
                "f": "Burundaise",
                "m": "Burundais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Lithuanian",
                "m": "Lithuanian"
            },
            "fra": {
                "f": "Lituanienne",
                "m": "Lituanien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "American",
                "m": "American"
            },
            "fra": {
                "f": "Américaine",
                "m": "Américain"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Honduran",
                "m": "Honduran"
            },
            "fra": {
                "f": "Hondurienne",
                "m": "Hondurien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Tongan",
                "m": "Tongan"
            },
            "fra": {
                "f": "Tonguienne",
                "m": "Tonguien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Saudi Arabian",
                "m": "Saudi Arabian"
            },
            "fra": {
                "f": "Saoudienne",
                "m": "Saoudien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Surinamer",
                "m": "Surinamer"
            },
            "fra": {
                "f": "Surinamaise",
                "m": "Surinamais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Qatari",
                "m": "Qatari"
            },
            "fra": {
                "f": "Qatarienne",
                "m": "Qatarien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Saint Helenian",
                "m": "Saint Helenian"
            },
            "fra": {
                "f": "Sainte-Hélénoise",
                "m": "Sainte-Hélènois"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Gibraltar",
                "m": "Gibraltar"
            },
            "fra": {
                "f": "Gibraltarienne",
                "m": "Gibraltarien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "American",
                "m": "American"
            },
            "fra": {
                "f": "Américaine",
                "m": "Américan"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Mauritian",
                "m": "Mauritian"
            },
            "fra": {
                "f": "Mauricienne",
                "m": "Mauricien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Barbadian",
                "m": "Barbadian"
            },
            "fra": {
                "f": "Barbadienne",
                "m": "Barbadien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Réunionese",
                "m": "Réunionese"
            },
            "fra": {
                "f": "Réunionnaise",
                "m": "Réunionnais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Indian",
                "m": "Indian"
            },
            "fra": {
                "f": "",
                "m": ""
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Syrian",
                "m": "Syrian"
            },
            "fra": {
                "f": "Syrienne",
                "m": "Syrien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Egyptian",
                "m": "Egyptian"
            },
            "fra": {
                "f": "Égyptienne",
                "m": "Égyptien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Sao Tomean",
                "m": "Sao Tomean"
            },
            "fra": {
                "f": "Santoméenne",
                "m": "Santoméen"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "I-Kiribati",
                "m": "I-Kiribati"
            },
            "fra": {
                "f": "Kiribatienne",
                "m": "Kiribatien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "East Timorese",
                "m": "East Timorese"
            },
            "fra": {
                "f": "Est-timoraise",
                "m": "Est-timorais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Mosotho",
                "m": "Mosotho"
            },
            "fra": {
                "f": "Lésothienne",
                "m": "Lésothien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Solomon Islander",
                "m": "Solomon Islander"
            },
            "fra": {
                "f": "Salomonienne",
                "m": "Salomonien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Libyan",
                "m": "Libyan"
            },
            "fra": {
                "f": "Libyenne",
                "m": "Libyen"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "South Korean",
                "m": "South Korean"
            },
            "fra": {
                "f": "Sud-coréenne",
                "m": "Sud-coréen"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Liechtensteiner",
                "m": "Liechtensteiner"
            },
            "fra": {
                "f": "Liechtensteinoise",
                "m": "Liechtensteinois"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Nicaraguan",
                "m": "Nicaraguan"
            },
            "fra": {
                "f": "Nicaraguayenne",
                "m": "Nicaraguayen"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Ecuadorean",
                "m": "Ecuadorean"
            },
            "fra": {
                "f": "Équatorienne",
                "m": "Équatorien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Maldivan",
                "m": "Maldivan"
            },
            "fra": {
                "f": "Maldivienne",
                "m": "Maldivien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Algerian",
                "m": "Algerian"
            },
            "fra": {
                "f": "Algérienne",
                "m": "Algérien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Kirghiz",
                "m": "Kirghiz"
            },
            "fra": {
                "f": "Kirghize",
                "m": "Kirghize"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Finnish",
                "m": "Finnish"
            },
            "fra": {
                "f": "Finlandaise",
                "m": "Finlandais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Antarctican",
                "m": "Antarctican"
            },
            "fra": {
                "f": "Antarcticaine",
                "m": "Antarcticain"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Kenyan",
                "m": "Kenyan"
            },
            "fra": {
                "f": "Kényane",
                "m": "Kényan"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Cuban",
                "m": "Cuban"
            },
            "fra": {
                "f": "Cubaine",
                "m": "Cubain"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Montserratian",
                "m": "Montserratian"
            },
            "fra": {
                "f": "Montserratienne",
                "m": "Montserratien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Polish",
                "m": "Polish"
            },
            "fra": {
                "f": "Polonaise",
                "m": "Polonais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Ålandish",
                "m": "Ålandish"
            },
            "fra": {
                "f": "Ålandaise",
                "m": "Ålandais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Ethiopian",
                "m": "Ethiopian"
            },
            "fra": {
                "f": "Éthiopienne",
                "m": "Éthiopien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Togolese",
                "m": "Togolese"
            },
            "fra": {
                "f": "Togolaise",
                "m": "Togolais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Bosnian, Herzegovinian",
                "m": "Bosnian, Herzegovinian"
            },
            "fra": {
                "f": "Bosnienne",
                "m": "Bosnien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Uruguayan",
                "m": "Uruguayan"
            },
            "fra": {
                "f": "Uruguayenne",
                "m": "Uruguayen"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Guamanian",
                "m": "Guamanian"
            },
            "fra": {
                "f": "",
                "m": ""
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Cape Verdian",
                "m": "Cape Verdian"
            },
            "fra": {
                "f": "Cap-verdienne",
                "m": "Cap-verdien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Chadian",
                "m": "Chadian"
            },
            "fra": {
                "f": "Tchadienne",
                "m": "Tchadien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Vatican",
                "m": "Vatican"
            },
            "fra": {
                "f": "Vaticane",
                "m": "Vatican"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Palauan",
                "m": "Palauan"
            },
            "fra": {
                "f": "Paluane",
                "m": "Paluan"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Haitian",
                "m": "Haitian"
            },
            "fra": {
                "f": "Haïtienne",
                "m": "Haïtien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Yemeni",
                "m": "Yemeni"
            },
            "fra": {
                "f": "Yéménite",
                "m": "Yéménite"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Swazi",
                "m": "Swazi"
            },
            "fra": {
                "f": "Swazie",
                "m": "Swazie"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Zimbabwean",
                "m": "Zimbabwean"
            },
            "fra": {
                "f": "Zimbabwéenne",
                "m": "Zimbabwéen"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Greek",
                "m": "Greek"
            },
            "fra": {
                "f": "Grecque",
                "m": "Grec"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Israeli",
                "m": "Israeli"
            },
            "fra": {
                "f": "Israélienne",
                "m": "Israélien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Saint Martin Islander",
                "m": "Saint Martin Islander"
            },
            "fra": {
                "f": "Saint-Martinoise",
                "m": "Saint-Martinois"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Antiguan, Barbudan",
                "m": "Antiguan, Barbudan"
            },
            "fra": {
                "f": "Antiguaise et barbudienne",
                "m": "Antiguaise et barbudien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Cypriot",
                "m": "Cypriot"
            },
            "fra": {
                "f": "Chypriote",
                "m": "Chypriote"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "St. Maartener",
                "m": "St. Maartener"
            },
            "fra": {
                "f": "Saint-Martinoise",
                "m": "Saint-Martinois"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Monegasque",
                "m": "Monegasque"
            },
            "fra": {
                "f": "Monégasque",
                "m": "Monégasque"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Fijian",
                "m": "Fijian"
            },
            "fra": {
                "f": "Fidjienne",
                "m": "Fidjien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Ukrainian",
                "m": "Ukrainian"
            },
            "fra": {
                "f": "Ukrainienne",
                "m": "Ukrainien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Martinican",
                "m": "Martinican"
            },
            "fra": {
                "f": "Martiniquaise",
                "m": "Martiniquais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Hong Konger",
                "m": "Hong Konger"
            },
            "fra": {
                "f": "Hongkongaise",
                "m": "Hongkongais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Portuguese",
                "m": "Portuguese"
            },
            "fra": {
                "f": "Portugaise",
                "m": "Portugais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Bhutanese",
                "m": "Bhutanese"
            },
            "fra": {
                "f": "Bhoutanaise",
                "m": "Bhoutanais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Nepalese",
                "m": "Nepalese"
            },
            "fra": {
                "f": "Népalaise",
                "m": "Népalais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "French",
                "m": "French"
            },
            "fra": {
                "f": "Française",
                "m": "Français"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Irish",
                "m": "Irish"
            },
            "fra": {
                "f": "Irlandaise",
                "m": "Irlandais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Emirati",
                "m": "Emirati"
            },
            "fra": {
                "f": "Emirienne",
                "m": "Emirien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Channel Islander",
                "m": "Channel Islander"
            },
            "fra": {
                "f": "Guernesiaise",
                "m": "Guernesiais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Saint Lucian",
                "m": "Saint Lucian"
            },
            "fra": {
                "f": "Saint-Lucienne",
                "m": "Saint-Lucien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Dominican",
                "m": "Dominican"
            },
            "fra": {
                "f": "Dominicaine",
                "m": "Dominicain"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Serbian",
                "m": "Serbian"
            },
            "fra": {
                "f": "Serbe",
                "m": "Serbe"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Motswana",
                "m": "Motswana"
            },
            "fra": {
                "f": "Botswanaise",
                "m": "Botswanais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Ivorian",
                "m": "Ivorian"
            },
            "fra": {
                "f": "Ivoirienne",
                "m": "Ivoirien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Ghanaian",
                "m": "Ghanaian"
            },
            "fra": {
                "f": "Ghanéenne",
                "m": "Ghanéen"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Comoran",
                "m": "Comoran"
            },
            "fra": {
                "f": "Comorienne",
                "m": "Comorien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Azerbaijani",
                "m": "Azerbaijani"
            },
            "fra": {
                "f": "Azerbaïdjanaise",
                "m": "Azerbaïdjanais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "British",
                "m": "British"
            },
            "fra": {
                "f": "Britannique",
                "m": "Britannique"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Central African",
                "m": "Central African"
            },
            "fra": {
                "f": "Centrafricaine",
                "m": "Centrafricain"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Palestinian",
                "m": "Palestinian"
            },
            "fra": {
                "f": "Palestinienne",
                "m": "Palestinien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Dutch",
                "m": "Dutch"
            },
            "fra": {
                "f": "Néerlandaise",
                "m": "Néerlandais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Taiwanese",
                "m": "Taiwanese"
            },
            "fra": {
                "f": "Taïwanaise",
                "m": "Taïwanais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Pitcairn Islander",
                "m": "Pitcairn Islander"
            },
            "fra": {
                "f": "Pitcairnaise",
                "m": "Pitcairnais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Sammarinese",
                "m": "Sammarinese"
            },
            "fra": {
                "f": "Saint-Marinaise",
                "m": "Saint-Marinais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Norwegian",
                "m": "Norwegian"
            },
            "fra": {
                "f": "",
                "m": ""
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Djibouti",
                "m": "Djibouti"
            },
            "fra": {
                "f": "Djiboutienne",
                "m": "Djiboutien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Wallis and Futuna Islander",
                "m": "Wallis and Futuna Islander"
            },
            "fra": {
                "f": "",
                "m": ""
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Danish",
                "m": "Danish"
            },
            "fra": {
                "f": "Danoise",
                "m": "Danois"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Papua New Guinean",
                "m": "Papua New Guinean"
            },
            "fra": {
                "f": "Papouasienne",
                "m": "Papouasien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Malagasy",
                "m": "Malagasy"
            },
            "fra": {
                "f": "Malgache",
                "m": "Malgache"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "",
                "m": ""
            },
            "fra": {
                "f": "",
                "m": ""
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Hungarian",
                "m": "Hungarian"
            },
            "fra": {
                "f": "Hongroise",
                "m": "Hongrois"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Tokelauan",
                "m": "Tokelauan"
            },
            "fra": {
                "f": "",
                "m": ""
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Trinidadian",
                "m": "Trinidadian"
            },
            "fra": {
                "f": "Trinidadienne",
                "m": "Trinidadien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Gambian",
                "m": "Gambian"
            },
            "fra": {
                "f": "Gambienne",
                "m": "Gambien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Luxembourger",
                "m": "Luxembourger"
            },
            "fra": {
                "f": "Luxembourgeoise",
                "m": "Luxembourgeois"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Cocos Islander",
                "m": "Cocos Islander"
            },
            "fra": {
                "f": "",
                "m": ""
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Congolese",
                "m": "Congolese"
            },
            "fra": {
                "f": "Congolaise",
                "m": "Congolais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Argentine",
                "m": "Argentine"
            },
            "fra": {
                "f": "Argentine",
                "m": "Argentin"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Congolese",
                "m": "Congolese"
            },
            "fra": {
                "f": "Congolaise",
                "m": "Congolais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Greenlandic",
                "m": "Greenlandic"
            },
            "fra": {
                "f": "Groenlandaise",
                "m": "Groenlandais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Jordanian",
                "m": "Jordanian"
            },
            "fra": {
                "f": "Jordanienne",
                "m": "Jordanien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Belgian",
                "m": "Belgian"
            },
            "fra": {
                "f": "Belge",
                "m": "Belge"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Swiss",
                "m": "Swiss"
            },
            "fra": {
                "f": "Suisse",
                "m": "Suisse"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Indonesian",
                "m": "Indonesian"
            },
            "fra": {
                "f": "Indonésienne",
                "m": "Indonésien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Lebanese",
                "m": "Lebanese"
            },
            "fra": {
                "f": "Libanaise",
                "m": "Libanais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Malaysian",
                "m": "Malaysian"
            },
            "fra": {
                "f": "Malaisienne",
                "m": "Malaisien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Caymanian",
                "m": "Caymanian"
            },
            "fra": {
                "f": "Caïmanienne",
                "m": "Caïmanien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Slovak",
                "m": "Slovak"
            },
            "fra": {
                "f": "Slovaque",
                "m": "Slovaque"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Armenian",
                "m": "Armenian"
            },
            "fra": {
                "f": "Arménienne",
                "m": "Arménien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Christmas Islander",
                "m": "Christmas Islander"
            },
            "fra": {
                "f": "",
                "m": ""
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Mongolian",
                "m": "Mongolian"
            },
            "fra": {
                "f": "Mongole",
                "m": "Mongol"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Saint-Pierrais, Miquelonnais",
                "m": "Saint-Pierrais, Miquelonnais"
            },
            "fra": {
                "f": "Saint-Pierraise, Miquelonaise",
                "m": "Saint-Pierrais, Miquelonais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Japanese",
                "m": "Japanese"
            },
            "fra": {
                "f": "Japonaise",
                "m": "Japonais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "South African",
                "m": "South African"
            },
            "fra": {
                "f": "Sud-africaine",
                "m": "Sud-africain"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Filipino",
                "m": "Filipino"
            },
            "fra": {
                "f": "Philippine",
                "m": "Philippin"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Micronesian",
                "m": "Micronesian"
            },
            "fra": {
                "f": "Micronésienne",
                "m": "Micronésien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "German",
                "m": "German"
            },
            "fra": {
                "f": "Allemande",
                "m": "Allemand"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Latvian",
                "m": "Latvian"
            },
            "fra": {
                "f": "Lettone",
                "m": "Letton"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Jamaican",
                "m": "Jamaican"
            },
            "fra": {
                "f": "Jamaïcaine",
                "m": "Jamaïcain"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Macanese",
                "m": "Macanese"
            },
            "fra": {
                "f": "Macanaise",
                "m": "Macanais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Nauruan",
                "m": "Nauruan"
            },
            "fra": {
                "f": "Nauruane",
                "m": "Nauruan"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Faroese",
                "m": "Faroese"
            },
            "fra": {
                "f": "Féroïenne",
                "m": "Féroïen"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Guyanese",
                "m": "Guyanese"
            },
            "fra": {
                "f": "Guyanienne",
                "m": "Guyanien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Burkinabe",
                "m": "Burkinabe"
            },
            "fra": {
                "f": "Burkinabée",
                "m": "Burkinabé"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Sudanese",
                "m": "Sudanese"
            },
            "fra": {
                "f": "Soudanaise",
                "m": "Soudanais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Russian",
                "m": "Russian"
            },
            "fra": {
                "f": "Russe",
                "m": "Russe"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Mahoran",
                "m": "Mahoran"
            },
            "fra": {
                "f": "Mahoraise",
                "m": "Mahorais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Australian",
                "m": "Australian"
            },
            "fra": {
                "f": "Australienne",
                "m": "Australien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Liberian",
                "m": "Liberian"
            },
            "fra": {
                "f": "Libérienne",
                "m": "Libérien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Mexican",
                "m": "Mexican"
            },
            "fra": {
                "f": "Mexicaine",
                "m": "Mexicain"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Tunisian",
                "m": "Tunisian"
            },
            "fra": {
                "f": "Tunisienne",
                "m": "Tunisien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Aruban",
                "m": "Aruban"
            },
            "fra": {
                "f": "Arubaise",
                "m": "Arubais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Kazakhstani",
                "m": "Kazakhstani"
            },
            "fra": {
                "f": "Kazakhstanaise",
                "m": "Kazakhstanais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Omani",
                "m": "Omani"
            },
            "fra": {
                "f": "Omanaise",
                "m": "Omanais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Guianan",
                "m": "Guianan"
            },
            "fra": {
                "f": "Guyanaise",
                "m": "Guyanais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Nigerien",
                "m": "Nigerien"
            },
            "fra": {
                "f": "Nigérienne",
                "m": "Nigérien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Turkmen",
                "m": "Turkmen"
            },
            "fra": {
                "f": "Turkmène",
                "m": "Turkmène"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Sierra Leonean",
                "m": "Sierra Leonean"
            },
            "fra": {
                "f": "Sierra-leonaise",
                "m": "Sierra-leonais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Samoan",
                "m": "Samoan"
            },
            "fra": {
                "f": "Samoane",
                "m": "Samoan"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Senegalese",
                "m": "Senegalese"
            },
            "fra": {
                "f": "Sénégalaise",
                "m": "Sénégalais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Georgian",
                "m": "Georgian"
            },
            "fra": {
                "f": "Géorgienne",
                "m": "Géorgien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Namibian",
                "m": "Namibian"
            },
            "fra": {
                "f": "Namibienne",
                "m": "Namibien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "South Sudanese",
                "m": "South Sudanese"
            },
            "fra": {
                "f": "Sud-Soudanaise",
                "m": "Sud-Soudanais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Thai",
                "m": "Thai"
            },
            "fra": {
                "f": "Thaïlandaise",
                "m": "Thaïlandais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Bahraini",
                "m": "Bahraini"
            },
            "fra": {
                "f": "Bahreïnienne",
                "m": "Bahreïnien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Heard and McDonald Islander",
                "m": "Heard and McDonald Islander"
            },
            "fra": {
                "f": "",
                "m": ""
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Falkland Islander",
                "m": "Falkland Islander"
            },
            "fra": {
                "f": "Malouinne",
                "m": "Malouin"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Channel Islander",
                "m": "Channel Islander"
            },
            "fra": {
                "f": "Jersiaise",
                "m": "Jersiais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Vietnamese",
                "m": "Vietnamese"
            },
            "fra": {
                "f": "Vietnamienne",
                "m": "Vietnamien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Guatemalan",
                "m": "Guatemalan"
            },
            "fra": {
                "f": "Guatémaltèque",
                "m": "Guatémaltèque"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Moldovan",
                "m": "Moldovan"
            },
            "fra": {
                "f": "Moldave",
                "m": "Moldave"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Macedonian",
                "m": "Macedonian"
            },
            "fra": {
                "f": "Macédonienne",
                "m": "Macédonien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Uzbekistani",
                "m": "Uzbekistani"
            },
            "fra": {
                "f": "Ouzbèke",
                "m": "Ouzbèke"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Romanian",
                "m": "Romanian"
            },
            "fra": {
                "f": "Roumaine",
                "m": "Roumain"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Ugandan",
                "m": "Ugandan"
            },
            "fra": {
                "f": "Ougandaise",
                "m": "Ougandais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Salvadoran",
                "m": "Salvadoran"
            },
            "fra": {
                "f": "Salvadorienne",
                "m": "Salvadorien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Zambian",
                "m": "Zambian"
            },
            "fra": {
                "f": "Zambienne",
                "m": "Zambien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Gabonese",
                "m": "Gabonese"
            },
            "fra": {
                "f": "Gabonaise",
                "m": "Gabonais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Equatorial Guinean",
                "m": "Equatorial Guinean"
            },
            "fra": {
                "f": "Équato-guinéenne",
                "m": "Équato-guinéen"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Spanish",
                "m": "Spanish"
            },
            "fra": {
                "f": "Espagnole",
                "m": "Espagnol"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Dutch",
                "m": "Dutch"
            },
            "fra": {
                "f": "Néerlandaise",
                "m": "Néerlandais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Virgin Islander",
                "m": "Virgin Islander"
            },
            "fra": {
                "f": "",
                "m": ""
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Beninese",
                "m": "Beninese"
            },
            "fra": {
                "f": "Béninoise",
                "m": "Béninois"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Pakistani",
                "m": "Pakistani"
            },
            "fra": {
                "f": "Pakistanaise",
                "m": "Pakistanais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Panamanian",
                "m": "Panamanian"
            },
            "fra": {
                "f": "Panaméenne",
                "m": "Panaméen"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Turks and Caicos Islander",
                "m": "Turks and Caicos Islander"
            },
            "fra": {
                "f": "",
                "m": ""
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Angolan",
                "m": "Angolan"
            },
            "fra": {
                "f": "Angolaise",
                "m": "Angolais"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "American Samoan",
                "m": "American Samoan"
            },
            "fra": {
                "f": "Samoane",
                "m": "Samoan"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Venezuelan",
                "m": "Venezuelan"
            },
            "fra": {
                "f": "Vénézuélienne",
                "m": "Vénézuélien"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Costa Rican",
                "m": "Costa Rican"
            },
            "fra": {
                "f": "Costaricaine",
                "m": "Costaricain"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Puerto Rican",
                "m": "Puerto Rican"
            },
            "fra": {
                "f": "Portoricaine",
                "m": "Portoricain"
            }
        }
    },
    {
        "demonyms": {
            "eng": {
                "f": "Seychellois",
                "m": "Seychellois"
            },
            "fra": {
                "f": "Seychelloise",
                "m": "Seychellois"
            }
        }
    }
]

const sortedNationality = [...commonNationality, ...nationalityData.sort((a, b) => a.demonyms.eng.f.localeCompare(b.demonyms.eng.f))];

export default sortedNationality;