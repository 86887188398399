import React, { useContext } from "react";
import { composeInitialProps } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logoAIOLIA from "../assets/img/logo-aiolia.png";
import part1 from "../assets/img/part1.jpg";
import part2 from "../assets/img/part2.jpg";
import { ThemeContext } from "./Wrapper";

const Footer = () => {
  const navigate = useNavigate();
  const context = useContext(ThemeContext);
  let hotelUrlName = '';
  try {
    hotelUrlName = JSON.parse(sessionStorage.getItem("hotel")).urlName;
  } catch (err) {
    hotelUrlName = 'Colbert';
  }

  return (
    <footer className="footer" data-wow-delay="800ms" data-wow-duration="2000ms">
      <div className="container flex flexBetween">
        <div className="powered">
          Powered by <a href="#">Aiolia</a>
        </div>
        <ul className="menu-ftr">
          <li className="footerLink" onClick={() => { window.location.href = `${window.location.origin}/${hotelUrlName}/generalConditionOfSale` }}>
            {context.translate("general_selling_conditions")}
            {/* <a href="#">{context.tranlsate('general_selling_conditions')}</a> */}
          </li>
          {/* <li className="footerLink" onClick={() => { window.location.href = `${window.location.origin}/${hotelUrlName}/legalNotice` }}>
            {context.translate("legal_mentions")}
          </li> */}
        </ul>
        <div className="logo-ftr">
          {/* <a href="#" target="_blank">
            <img src={part1} alt="Pic" />
          </a> */}

          <a href="#" target="_blank">
            <img src={part2} alt="PCI" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
