import React, { useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../config/api-config";
import { ThemeContext } from "../Wrapper";
import "react-image-gallery/styles/css/image-gallery.css";
import RoomGallery from "./RoomGallery";
import RoomDetailPopup from "../../views/RoomDetail/room_detail_popup";
import { getDiffDays } from "../../services/utils";
import AdultsIcon from "./AdultsIcon";
import ChildrenIcon from "./ChildrenIcon";
import { sendImpendingReservation } from "../../services/room";
import Equipments from "./Equipments";
import CancelingPolitic from "./CancelingPolitic";
import OffresDispo from "./Parc/OffresDispo";

const RoomSearchResult = ({
  // VAOVAO
  redirectToBookingPage,
  offresDispo,
  datePayload,
  // 
  searchRoomPayload,
  rooms,
  handleChangeLocalStorage,
  isSearchRoomResultReceived,
  nightsNum,
  searchRoom,
  checkIn,
  checkOut,
  cartExpirationDate,
  dateMadagascar,
  populateCalendar,
  infoCalendar,
  printNoResult,
  totalPrice
}) => {
  // const navigate = useNavigate();
  const context = useContext(ThemeContext);

  const [showModalRoomGallery, setShowModalRoomGallery] = useState(false);
  const [showModalRoomDetail, setShowModalRoomDetail] = useState(false);
  const [roomDetail, setRoomDetail] = useState(null);
  // ex: isBtnReservLoading[0][1] => 0 is the index of room and 1 is the index of rate plan
  const [isBtnReservLoading, setIsBtnReservLoading] = useState([]);

  const [isPopupPoliticToRiseUp, setIsPopupPoliticToRiseUp] = useState(new Array(rooms.length).fill([]));
  const [isPopupRatePlanToRiseUp, setIsPopupRatePlanToRiseUp] = useState(new Array(rooms.length).fill([]));

  const showDetail = (room) => {
    setShowModalRoomDetail(true);
    setRoomDetail(room);
  };

  // const giveBtnReserveLoadingNewValues = (rooms) => {
  //   const isBtnReservLoadingTemp = [];
  //   rooms.forEach((room, i) => {
  //     isBtnReservLoadingTemp.push([]);
  //     room.tarifs.forEach((ratePlan, u) => {
  //       isBtnReservLoadingTemp[i][u] = [];
  //       ratePlan.toPayStay.forEach((numPers, v) => {
  //         isBtnReservLoadingTemp[i][u][v] = false;
  //       });
  //     });
  //   });
  //   setIsBtnReservLoading(isBtnReservLoadingTemp);
  // };

  const switchIsBtnReservLoading = (
    indexItinerary,
    indexRatePlan,
    indexNumPers,
    adultsNum,
    childrenNum
  ) => {
    const isBtnReservLoadingTemp = [...isBtnReservLoading];
    isBtnReservLoadingTemp[indexItinerary][indexRatePlan][indexNumPers] =
      !isBtnReservLoadingTemp[indexItinerary][indexRatePlan][indexNumPers];
    setIsBtnReservLoading(isBtnReservLoadingTemp);
  };

  const addAReservation = (
    room,
    rate,
    price,
    indexItinerary,
    indexRatePlan,
    indexNumPers,
    adultsNum,
    childrenNum
  ) => {
    switchIsBtnReservLoading(indexItinerary, indexRatePlan, indexNumPers, adultsNum, childrenNum);
    sendImpendingReservation({
      room_type_id: room._id,
      cart_expiration_date: cartExpirationDate ? cartExpirationDate : new Date(),
      check_in: checkIn,
      check_out: checkOut,
      cart_id: localStorage.getItem('cartId'),
    })
      .then(result => {
        if (result.data.status === 200) {
          populateCalendar({ infoCalendarArg: infoCalendar, resetCheckInCheckOut: false, });
          localStorage.setItem('cartId', result.data.cartId);
          searchRoom({
            searchRoomPayloadArg: {
              ...searchRoomPayload,
              dateDebut: checkIn,
              dateFin: checkOut,
            },
            doNotSetCalendarData: false,
          });
        }
      })
      .catch(err => alert('An error occured'));
    setTimeout(() => {
      handleChangeLocalStorage(room, rate, price);
      switchIsBtnReservLoading(indexItinerary, indexRatePlan, indexNumPers);
    }, 1000);
  };

  const switchIsPopupToRiseUp = (
    indexRoomType,
    indexRatePlan,
    stateIsPopupToRiseUp,
    setStateIsPopupToRiseUp,
    rooms,
  ) => {
    const oldValue = stateIsPopupToRiseUp
      ?.[indexRoomType]
      ?.[indexRatePlan];
    let isToRiseUpTemp = [];
    for (let i = 0; i < rooms.length; i++) {
      isToRiseUpTemp[i] = new Array(rooms[i].tarifs.length)
        .fill(false);
    }
    isToRiseUpTemp[indexRoomType][indexRatePlan] = !oldValue;
    setStateIsPopupToRiseUp(isToRiseUpTemp);
  };

  useEffect(() => {
    // giveBtnReserveLoadingNewValues(rooms);
    let isToRiseUpTemp = [];
    for (let i = 0; i < isPopupPoliticToRiseUp.length; i++) {
      isToRiseUpTemp[i] = new Array(
        isPopupPoliticToRiseUp[i].tarifs?.length
      ).fill(false);
    }
    setIsPopupPoliticToRiseUp(isToRiseUpTemp);
  }, [rooms]);

  const getPhotoFullURL = (photos) => {
    const photosTmp = [];
    photos.forEach(photo => {
      photosTmp.push({
        original: `${api.host}/${photo}`,
        thumbnail: `${api.host}/${photo}`,
      });
    });

    return photosTmp;
  };

  const lang = localStorage.getItem('lang') || 'fr';

  const test = () => {
    console.log("test rooms: ", rooms);
  }

  return (
    <>
      {/* <button onClick={test}>test rooms</button> */}
      {/* {rooms.map((room, i) => {
        return (
          <div key={room._id} className="item shadow35">
            <RoomGallery
              images={getPhotoFullURL(room.photo)}
              showModalRoomGallery={showModalRoomGallery}
              setShowModalRoomGallery={setShowModalRoomGallery}
            />
            <div className="firstInfo">
              <div
                className="contImg"
                onClick={() => setShowModalRoomGallery(true)}
              >
                <img
                  src={api.host + "/" + room.photoCrop?.[0].replace("\\", "/")}
                  alt="Room 1"
                />
              </div>
              <div className="roomInfo">
                <div className="h4">{room.nom}</div>
                <div className="max">
                  <span className="material-symbols-rounded">group</span>
                  Max: {room.nbAdulte} {context.translate("adults")} -{" "}
                  {room.nbEnfant} {context.translate("children")}
                </div>
                <div className="desc">
                  <p>{room.description}</p>
                </div>
                <Equipments equipments={room.equipements} />
                <a
                  className="moreTxt"
                  onClick={() =>
                    showDetail(room)
                  }
                >
                  détail
                </a>
                <div className="clr"></div>
              </div>
            </div>
            {room.tarifs.map((rate, u) => {
              return (
                <div key={rate._id} className="secInfo">
                  <div className="head flex flexBetween">
                    <div
                      onMouseEnter={() =>
                        context.showPopup(
                          true,
                          `${room._id}_${rate._id}`,
                          "room_search_result_rate_name"
                        )
                      }
                      onMouseLeave={() =>
                        context.showPopup(
                          false,
                          `${room._id}_${rate._id}`,
                          "room_search_result_rate_name"
                        )
                      }
                      className="rateName condition"
                    >
                      {rate.nom}
                      <span className="material-symbols-rounded">info</span>
                      <div
                        style={{
                          opacity:
                            context.isHoveringPopup.toHover ===
                              "room_search_result_rate_name" &&
                              context.isHoveringPopup.isHovered &&
                              `${room._id}_${rate._id}` ==
                              context.isHoveringPopup.index
                              ? 1
                              : 0,
                          visibility:
                            context.isHoveringPopup.toHover ===
                              "room_search_result_rate_name" &&
                              context.isHoveringPopup.isHovered &&
                              `${room._id}_${rate._id}` ==
                              context.isHoveringPopup.index
                              ? "visible"
                              : "hidden",
                        }}
                        onMouseEnter={() => {
                          switchIsPopupToRiseUp(
                            i,
                            u,
                            isPopupRatePlanToRiseUp,
                            setIsPopupRatePlanToRiseUp,
                            rooms
                          )
                        }}
                        className={`infoTips bordered`}
                      >
                        <label
                          onClick={() =>
                            context.showPopup(
                              false,
                              `${room._id}_${rate._id}`,
                              "room_search_result_rate_name"
                            )
                          }
                          className="modal_close"
                        ></label>
                        <div className="content">
                          <h4>{context.translate('fees_informations')}</h4>
                          <ul>
                            <li>{rate.descriptions[lang]}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div
                      onMouseEnter={() =>
                        context.showPopup(
                          true,
                          `${room._id}_${rate._id}`,
                          "room_search_result_rate_description"
                        )
                      }
                      onMouseLeave={() =>
                        context.showPopup(
                          false,
                          `${room._id}_${rate._id}`,
                          "room_search_result_rate_description"
                        )
                      }
                      className="condition"
                    >
                      <div className="contTxtCond">
                        <span className="material-symbols-rounded">info</span>
                        {rate.politiqueAnnulAtrb[0]?.names[lang]}
                      </div>
                      <div
                        style={{
                          opacity:
                            context.isHoveringPopup.toHover ===
                              "room_search_result_rate_description" &&
                              context.isHoveringPopup.isHovered &&
                              `${room._id}_${rate._id}` ==
                              context.isHoveringPopup.index
                              ? 1
                              : 0,
                          visibility:
                            context.isHoveringPopup.toHover ===
                              "room_search_result_rate_description" &&
                              context.isHoveringPopup.isHovered &&
                              `${room._id}_${rate._id}` ==
                              context.isHoveringPopup.index
                              ? "visible"
                              : "hidden",
                        }}
                        onMouseEnter={() => {
                          switchIsPopupToRiseUp(
                            i,
                            u,
                            isPopupPoliticToRiseUp,
                            setIsPopupPoliticToRiseUp,
                            rooms
                          )
                        }}
                        className={`infoTips bordered`}
                      >
                        <label
                          onClick={() =>
                            context.showPopup(
                              false,
                              `${room._id}_${rate._id}`,
                              "room_search_result_rate_description"
                            )
                          }
                          className="modal_close"
                        ></label>
                        <CancelingPolitic
                          politiqueAnnulAtrb={rate.politiqueAnnulAtrb}
                          searchRoomPayload={searchRoomPayload}
                          dateMadagascar={dateMadagascar}
                          context={context}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="lstBook">
                    {rate.toPayStay.map((price, v) => {
                      return (
                        <div
                          key={`${room._id}_${rate._id}_${price.adultsNum}_${price.childrenNum}_${price.prix}`}
                          className="item"
                        >
                          <div className="left flex flexBetween">
                            <div className="nb">
                              <AdultsIcon adultsNum={price.adultsNum} />
                              <ChildrenIcon childrenNum={price.childrenNum} />
                            </div>
                            <div className="price flex">
                              {
                                price.prixOriginal && <div className="oldPrice">
                                  {(
                                    price.prixOriginal * context.currency.value
                                  ).toFixed(2)}{" "}
                                  {context.currency.symbol}
                                </div>
                              }

                              <div className="newPrice">
                                {(
                                  price.prix * context.currency.value
                                ).toFixed(2)}{" "}
                                {context.currency.symbol}
                              </div>
                            </div>
                            {price.prixOriginal && (
                              <div className="discount">
                                {context.translate('discount')}:{" "}
                                {(
                                  (price.prixOriginal - price.prix) *
                                  context.currency.value
                                ).toFixed(2)}{" "}
                                {context.currency.symbol} TTC
                              </div>
                            )}
                            <div className="nightsNumInSearchResult">
                              <span className="material-symbols-rounded">nights_stay</span>
                              {context.translate("nights_stay")}: {nightsNum}
                            </div>
                          </div>
                          <a
                            className={isBtnReservLoading[i]?.[u]?.[v] ? "btn loading" : "btn"}
                            onClick={() =>
                              addAReservation(room, rate, price, i, u, v, price.adultsNum, price.childrenNum)
                            }
                          >
                            {context.translate("reserve")}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            {showModalRoomDetail && (
              <RoomDetailPopup
                roomDetail={roomDetail}
                setShowModalRoomDetail={setShowModalRoomDetail}
                setShowModalRoomGallery={setShowModalRoomGallery}
              />
            )}
          </div>
        );
      })} */}
      <OffresDispo
        // offresDispo={offresDispo}
        offresDispo={rooms}
        context={context}
        handleChangeLocalStorage={handleChangeLocalStorage}
        redirectToBookingPage={redirectToBookingPage}
        datePayload={datePayload}
      ></OffresDispo>
      {printNoResult && <p style={{ color: "red", textAlign: "center", fontSize: "20px" }}>
        {/* {isSearchRoomResultReceived && context.translate('no_results')} */}
        {isSearchRoomResultReceived && "Malheureusement, le parc est actuellement au maximum de sa capacité pour aujourd'hui. Pourriez-vous envisager de sélectionner d'autres dates qui vous conviendraient mieux ? Merci de votre compréhension et de votre flexibilité !"}
      </p>}
    </>
  )
};

export default RoomSearchResult;
