import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "./Wrapper";
import { useNavigate } from "react-router-dom";
import api from "../config/api-config";
// import banner from "../assets/img/banner.jpg";
// import ibis from "../assets/img/logo.jpg";
// import colbert from "../assets/img/colbert.png";
// import leLouvre from "../assets/img/le-louvre.png";

import nosyTanyHely from "../styles/parc/images/banner.jpg";
// import logo from "../styles/parc/images/logo.png";
import logo from "../styles/parc/images/Logo TNP.jpg";

const LeaderBoard = ({
  backgroundImage
}) => {
  const context = useContext(ThemeContext);
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [background, setBackground] = useState("none");
  const [banner, setBanner] = useState(null);
  useEffect(() => {
    if (context.getHotel().logo !== "") {
      setImage(`${api.host}/${context.getHotel().logo}`)
      setBackground(
        context.getHotel().urlName !== "ibis"
          ? context.getHotel().theme.btn.primary
          : "none"
      );
    }
    if (context.getHotel().banner !== "") {
      setBanner(`${api.host}/${context.getHotel().banner}`);
    }
  }, [context.getHotel()]);
  return (
    <section className="leaderBoard">
      <div className="banner">
        {/* <img className="banner" src={banner} alt="Hotel Banner" /> */}
        {/* <img className="banner" alt="Nosy Tanihely" src={nosyTanyHely} /> */}
        <img className="banner" alt="Nosy Tanihely" src={backgroundImage} />
      </div>
      <div className="txt-banner wow fadeIn" data-wow-delay="1000ms" data-wow-duration="3500ms">
        <h1>{context.translate("national_parc")} <br />Nosy Tanihely</h1>
      </div>

      <div className="infoBrand wow fadeInLeft" data-wow-delay="800ms" data-wow-duration="2500ms">
        <div
          className="logo"

        >
          <a href="#" onClick={() => navigate(`/${context.getHotel().urlName}`)}>
            {/* <img src={image} alt="Nosy Tanihely" /> */}
            <img src={logo} alt="Nosy Tanihely" />
          </a>
        </div>
        <ul>
          <li className="adrss">
            <a href="#">
              <span></span>
              {/* {context.getHotel().address} */}
              Lot 04BA Ex Résidence de Maire, rue Boulevard Manceau, <br />201 Nosy Be, Madagascar
            </a>
          </li>
          <li className="tel">
            {/* <a href={`tel:${context.getHotel().phoneNum}`}>
              {context.getHotel().phoneNum}
            </a> */}
            <a href="tel:+261380940061"><span></span>+261 38 09 400 61</a>
          </li>
          <li className="mail">
            {/* <a href={`https://${context.getHotel().link}`} target="_blank" rel="noopener noreferrer">
              <span className="material-symbols-rounded">language</span>
              {context.getHotel().link}
            </a> */}
            <a href="https://landisoa_dpNST@mnparks.mg" target="_blank"
              rel="noopener noreferrer">
              <span></span>landisoa_dpNST@mnparks.mg
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default LeaderBoard;
