import React from 'react';
import html2pdf from 'html2pdf.js';
import ReactDOM from 'react-dom';

const ExportComponentToPdf = (component, pdfFileName) => {

    const opt = {
        margin: 10,
        // html2canvas: { scale: 2 },
        html2canvas: {
            backgroundColor: "#fff",
            // scale: window.devicePixelRatio,
            scale: 2,
            y: 0,
            x: 0,
            scrollY: 0,
            scrollX: 0,
            // windowWidth: window.width
        },
        image: { type: 'jpeg', quality: 0.98 },
        jsPDF: { unit: 'mm', format: [800, 1600], orientation: 'portrait', precision: 32 },
        // jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    const div = document.createElement('div');
    document.body.appendChild(div);

    const componentToRender = React.cloneElement(component);

    ReactDOM.render(componentToRender, div, () => {
        html2pdf()
            .from(div)
            .set(opt)
            .save(pdfFileName);

        document.body.removeChild(div);
    });
};

export default ExportComponentToPdf;