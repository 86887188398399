import React, { useEffect } from 'react';

import checkImg from '../../../styles/parc/images/check.svg';
import logo2 from '../../../styles/parc/images/Logo TNP.jpg';
import QRCode from 'react-qr-code';
import { useState } from 'react';
// import html2pdf from 'html2pdf.js';  // Note the use of "* as" for import
import { ThemeContext } from '../../Wrapper';
import { useContext } from 'react';
import ExportComponentToPdf from './ExportComponentToPdf';
import ClientVoucherPdfComponent from './ClientVoucherPdfComponent';
import { formatNumber } from '../../../services/utils';
import { formatDate } from '../../../services/utils';

import ico1 from "../../../styles/parc/images/ico1.png";
import ico2 from "../../../styles/parc/images/ico2.png";
import ico3 from "../../../styles/parc/images/ico3.png";
import ico4 from "../../../styles/parc/images/ico4.png";
import ico5 from "../../../styles/parc/images/ico5.png";
import ico6 from "../../../styles/parc/images/ico6.png";
import ico7 from "../../../styles/parc/images/ico7.png";
import ico8 from "../../../styles/parc/images/ico8.png";

const ClientVoucherComponent = ({
    urlHotelName,
    reservationDetails
}) => {

    // console.log('reservationDetails : ', reservationDetails);

    const context = useContext(ThemeContext);
    const [total, setTotal] = useState(0);

    const initTotal = () => {
        let totalTmp = 0;

        reservationDetails.forEach(reservationDetail => {
            totalTmp += reservationDetail.tarif?.prix;
        });

        setTotal(totalTmp);
    }

    const generatePdf = () => {
        ExportComponentToPdf(
            <ClientVoucherPdfComponent context={context} reservationDetails={reservationDetails} />
            ,
            'voucher.pdf'
        );
    }

    useEffect(() => {
        initTotal();
    }, []);

    return (
        <>
            <section className="sec-breadcrumb">
                <div className="container">
                    <div className="breadcrumb wow fadeIn" data-wow-delay="800ms" data-wow-duration="1000ms">
                        <nav>
                            <span>Vous êtes ici : </span>
                            <ul>
                                <li><a href={`/${urlHotelName}`}>Réservation de votre visite</a></li>
                                <li>Confirmation</li>
                                <li className='current'><span>Résumé de votre réservation</span></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>

            <section className="contentPage pageInt creation-reservation ok">

                <div class="deco">
                    <div class="ico-para ico1 trigger_parallax01">
                        <img src={ico1} />
                    </div>
                    <div class="ico-para ico2 trigger_parallax01">
                        <img src={ico2} />
                    </div>
                    <div class="ico-para ico3 trigger_parallax01">
                        <img src={ico3} />
                    </div>
                    <div class="ico-para ico4 trigger_parallax01">
                        <img src={ico4} />
                    </div>
                    <div class="ico-para ico5 trigger_parallax01">
                        <img src={ico5} />
                    </div>
                    <div class="ico-para ico6 trigger_parallax01">
                        <img src={ico6} />
                    </div>
                    <div class="ico-para ico7 trigger_parallax01">
                        <img src={ico7} />
                    </div>
                    <div class="ico-para ico8 trigger_parallax01">
                        <img src={ico8} />
                    </div>
                </div>

                <div className="container">
                    <h2 className="wow fadeInUp" data-wow-delay="800ms" data-wow-duration="2000ms">{context.translate('reservation_confirmed')}</h2>

                    <div className="blcFormulaire  wow fadeInUp" >
                        <form >
                            <div className="txt-center">
                                <span className="ico">
                                    <img src={checkImg} alt="img" />
                                </span>
                                <h3 >{context.translate('congratulations')}!</h3>
                                <p>{context.translate('booking_confirmed')}.</p>
                                <p>{context.translate('your_confirmation_number_was_emailed_at_time_of_booking')}.</p>
                            </div>
                            <div className="bloc-chp">
                                {
                                    reservationDetails.map((reservationDetail, index) =>
                                        <div key={index} className="det-resa">
                                            <div className="blcLeft">
                                                <a href="#" className="logo-int"><img src={logo2} alt="Aiolia" /></a>
                                                <h3>{context.translate('destination')} Nosy Tanihely</h3>
                                                <ul className="DetLstReservation">
                                                    <li>{context.translate('your_reservation_number')} : <strong>{reservationDetail.numero}</strong></li>
                                                    <li>{context.translate('client')} : <strong>{reservationDetail.nom} {reservationDetail.prenom}</strong></li>
                                                </ul>
                                                <h4>{reservationDetail.tarif?.nomTypeChambre}</h4>
                                                <h5>{reservationDetail.tarif?.nomTarif}</h5>
                                                <br />
                                                <div className="contTxt">
                                                    <p>{reservationDetail.tarif?.description}</p>
                                                </div>
                                                <ul className="lst-resrvation">
                                                    {/* <li className="enfant">Enfant(s)</li> */}
                                                    <li className={reservationDetail.tarif?.categorieAge}>
                                                        {
                                                            reservationDetail.tarif?.categorieAge === 'enfant' ?
                                                                <>{context.translate('children')}</> :
                                                                <>{context.translate('adults')}</>
                                                        }
                                                    </li>
                                                </ul>

                                                <div className="total flex flexBetween">
                                                    <span>{context.translate('price')}</span>
                                                    <div className="prix">{formatNumber(reservationDetail.tarif?.prix)}<sup>AR</sup></div>
                                                </div>
                                            </div>
                                            <div className="blcRight">
                                                <div className="blcCodeQr">
                                                    <div className="ref">{reservationDetail.numero}</div>
                                                    <ul className="lst-resrvation">
                                                        <li className="date">
                                                            {
                                                                context.translate('entry_date')
                                                            }: <strong>{formatDate(reservationDetail.dateEntree)}</strong>
                                                        </li>
                                                    </ul>
                                                    <div className="date"></div>
                                                    <div className="codeQr">
                                                        <QRCode value={`${reservationDetail._id}`}></QRCode>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <br />
                            <div className="total flex flexBetween">
                                <span>{context.translate("total")}</span>
                                <div className="prix">{formatNumber(total)}<sup>AR</sup></div>
                            </div>
                            <br />
                            <span onClick={() => generatePdf()} className="btn-primary" title="Télécharger en pdf"> télécharger en pdf</span>
                        </form>
                    </div>

                </div>

            </section>
        </>
    );
};

export default ClientVoucherComponent;