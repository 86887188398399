import React, { useContext } from "react";

import { register } from "../../../services/account";
import EnterConfirmationCode from "../../account/EnterConfirmationCode";
import { ThemeContext } from "../../Wrapper";

const CreateAccount = ({
  handleChange,
  customerInformation,
  customerInformationError,
  createAccountError,
  isEnterConfirmationCodeOpen,
  setIsEnterConfirmationCodeOpen,
  clientId,
  setClientId,
  validateRegisterCodeAndLogin,
  confirmationCode,
  setConfirmationCode,
  setCreateAccountError,
}) => {
  const context = useContext(ThemeContext);

  const createAccount = () => {
    const {
      booker_name,
      booker_first_name,
      booker_email,
      booker_phone,
      booker_professional_phone,
      password,
      confirm_password,
    } = customerInformation;

    const payload = {
      isPartner: false,
      name: booker_name,
      first_name: booker_first_name,
      email: booker_email,
      backupEmail: null,
      password: password,
      confirmed_password: confirm_password,
      phone: booker_phone,
      professional_phone: booker_professional_phone,
      country: null,
      address_1: null,
      address_type: null,
      town: null,
      postal_code: null,
    };

    register(payload)
      .then((result) => {
        if (result.data.status === 200) {
          setCreateAccountError(null);
          setIsEnterConfirmationCodeOpen(true);
          setClientId(result.data.client_id);
        } else {
          setCreateAccountError(result.data.msg);
        }
      })
      .catch((error) => {
        context.showLoader(false);
        context.showError(false);
      });
  };

  return (
    <>
      <div className="blcChp col-100 blccheck">
        <p><strong>
          {context.translate("reserve_faster")} (
          {context.translate("in_option")})
        </strong></p>
        <div className="authentication-account-access-item">
          <div className="input-checkbox">
            <input
              type="checkbox"
              id="creerCompte1"
              name="create_account"
              onChange={handleChange}
            />
            <label htmlFor="creerCompte1">{context.translate("i_would_like_to_create_an_account")}.</label>
          </div>
        </div>
      </div>

      {customerInformation?.create_account && (
        <>
          <div className="bloc-chp col-100">
            <div className="blcChp col-50">
              <label>{context.translate('password')} *</label>
              <div className="password">
                <input
                  className={
                    customerInformationError.password
                      ? "form-control errorForm"
                      : "form-control"
                  }
                  name="password"
                  onChange={handleChange}
                  type="password"
                />
                <span className="material-symbols-rounded pointer toggle-password"></span>
              </div>
            </div>
            <div className="blcChp password col-50">
              <label>{context.translate('confirm_your_password')} *</label>
              <div className="password">
                <input
                  className={
                    customerInformationError.confirm_password
                      ? "form-control errorForm"
                      : "form-control"
                  }
                  name="confirm_password"
                  onChange={handleChange}
                  type="password"
                />
                <span className="material-symbols-rounded pointer toggle-password"></span>
              </div>
            </div>
            <div className="blcChp col-100">
              <input
                type="button"
                className="btn-primary"
                value={context.translate('create_an_account')}
                onClick={createAccount}
              />
            </div>
            {createAccountError && (
              <div>
                <p style={{ color: "red", textAlign: "center" }}>
                  {createAccountError}
                </p>
              </div>
            )}
            {isEnterConfirmationCodeOpen && (
              <EnterConfirmationCode
                isEnterConfirmationCodeOpen={isEnterConfirmationCodeOpen}
                setIsEnterConfirmationCodeOpen={setIsEnterConfirmationCodeOpen}
                clientId={clientId}
                callValidationCodeAPI={validateRegisterCodeAndLogin}
                confirmationCode={confirmationCode}
                setConfirmationCode={setConfirmationCode}
                preventNavigation={true}
              />
            )}
          </div>
        </>
      )}

      {/* <div className="foot">
        <div className="infoTips">
          <h4>
            {context.translate("reserve_faster")} (
            {context.translate("in_option")})
          </h4>
          <div className="pure-checkbox">
            <input
              name="create_account"
              onChange={handleChange}
              id="typeCustomer"
              type="checkbox"
            />
            <label htmlFor="typeCustomer">
              {context.translate("i_would_like_to_create_an_account")}.
            </label>
          </div>
          {customerInformation?.create_account && (
            <>
              <div className="contFormControl">
                <input
                  className={
                    customerInformationError.password
                      ? "form-control errorForm"
                      : "form-control"
                  }
                  name="password"
                  onChange={handleChange}
                  type="password"
                  placeholder="Mot de passe *"
                />
                {customerInformationError.password && (
                  <div className="textError">
                    {customerInformationError.password}
                  </div>
                )}
              </div>
              <div className="contFormControl">
                <input
                  className={
                    customerInformationError.confirm_password
                      ? "form-control errorForm"
                      : "form-control"
                  }
                  name="confirm_password"
                  onChange={handleChange}
                  type="password"
                  placeholder="Confirmer votre mot de passe *"
                />
                {customerInformationError.confirm_password && (
                  <div className="textError">
                    {customerInformationError.confirm_password}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {customerInformation?.create_account && (
          <div className="contFormControl">
            <a
              style={{ width: "100%" }}
              className="form-control btn"
              onClick={createAccount}
            >
              {context.translate("create_an_account")}
            </a>
          </div>
        )}
        {createAccountError && (
          <div className="contFormControl">
            <p style={{ color: "red", textAlign: "center" }}>
              {createAccountError}
            </p>
          </div>
        )}
        {isEnterConfirmationCodeOpen && (
          <EnterConfirmationCode
            isEnterConfirmationCodeOpen={isEnterConfirmationCodeOpen}
            setIsEnterConfirmationCodeOpen={setIsEnterConfirmationCodeOpen}
            clientId={clientId}
            callValidationCodeAPI={validateRegisterCodeAndLogin}
            confirmationCode={confirmationCode}
            setConfirmationCode={setConfirmationCode}
            preventNavigation={true}
          />
        )}
      </div> */}
    </>
  );
};

export default CreateAccount;
