import React, { useState, useEffect, useRef, useContext } from 'react';
import { ThemeContext } from '../Wrapper';
import TimeUp from "../../components/TimeUp";
import { revertInventory } from '../../services/room';
import { storageChange } from '../../services/dispatch';

let intervalRefreshTimerId = null;

export default function Timer({
  timerEnd,
  modalTimeUpAlreadyOpened,
  setModalTimeUpAlreadyOpened
}) {
  const context = useContext(ThemeContext)
  const [minutes, setMinutes] = useState(15);
  const [seconds, setSeconds] = useState(0);
  const [isModalTimeUpOpened, setIsModalTimeUpOpened] = useState(false);

  // const navigate = useNavigate();

  function getTimerLeft() {
    const date1 = new Date().getTime();
    const date2 = timerEnd?.getTime();
    if (Math.sign(date2 - date1) === 1) {
      let delta = Math.abs(date2 - date1) / 1000;

      const days = Math.floor(delta / 86400);
      delta -= days * 86400;

      const hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      const minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      const seconds = delta % 60;

      setMinutes(minutes);
      setSeconds(Math.round(seconds));
    } else {
      setMinutes(-1);
      setSeconds(0);
      localStorage.removeItem("timerDateCreation");
    }
  }

  const getLastItineraryFromCart = () => {
    const cart = JSON.parse(localStorage.getItem("cart"));
    const lastItinerary = cart.slice(-1);
    if (lastItinerary.length > 0) {
      lastItinerary[0].tarifReserves = [];
      lastItinerary[0].totalPrice = 0;
    }
    return lastItinerary;
  }

  const buildRevertData = (cart) => {
    const reserves = []
    cart[0].tarifReserves.forEach(tarifReserve => {
      reserves.push({
        idTypeChambre: tarifReserve.idTypeChambre,
        date: cart[0].datePayload,
        change: tarifReserve.nb,
      })
    })
    return reserves
  }


  useEffect(() => {
    if (!modalTimeUpAlreadyOpened) {
      clearInterval(intervalRefreshTimerId);
      intervalRefreshTimerId = setInterval(getTimerLeft, 1000);
    }
  }, [timerEnd]);

  useEffect(() => {
    if (minutes < 0 && seconds <= 0) {
      clearInterval(intervalRefreshTimerId);

      const cartTmp = JSON.parse(localStorage.getItem("cart"));
      if (cartTmp?.length > 0) {
        const payload = {
          reserves: buildRevertData(cartTmp),
        }
        revertInventory(payload)
          .then(result => {
            // console.log("revertInventory: ", result)
            localStorage.setItem("cart", JSON.stringify(getLastItineraryFromCart()));
            // localStorage.removeItem("cart");
            // storageChange();
          })
      }
      let cartExpirationDate = new Date();
      cartExpirationDate.setMinutes(cartExpirationDate.getMinutes() + 15);
      // cartExpirationDate.setSeconds(cartExpirationDate.getSeconds() + 60);
      cartExpirationDate = cartExpirationDate.toISOString();
      localStorage.setItem("cartExpirationDate", cartExpirationDate);
      setIsModalTimeUpOpened(true);
      setModalTimeUpAlreadyOpened(true);
    }
  }, [minutes, seconds]);

  useEffect(() => {
    if (!localStorage.getItem("cart")) {
      clearInterval(intervalRefreshTimerId);
      localStorage.removeItem("cartExpirationDate");
    }
  });

  return (
    <>
      <div className="timing flex">
        <div className="h3"><strong>{context.translate('room_booked_for')} 15 min</strong></div>
        <div className="remain">
          <span className="material-symbols-rounded">hourglass_top</span>
          <span className="hour">00</span>
          :
          <span className="min">{minutes}mn</span>
          :
          <span className="sec">{seconds}s</span>
        </div>
      </div>
      {
        (minutes < 0 && seconds === 0)
          ? <TimeUp
            isModalTimeUpOpened={isModalTimeUpOpened}
            setIsModalTimeUpOpened={setIsModalTimeUpOpened}
          />
          : null
      }
    </>
  );
}
