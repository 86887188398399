import React, { useState, useEffect, useRef, useContext } from 'react';
import { ThemeContext } from '../Wrapper';

export default function ThreeDSecure({
  jwt
}) {
  const url = process.env.REACT_APP_ENV_CYBERSOURCE === 'Prod' ? 'https://centinelapi.cardinalcommerce.com/V2/Cruise/StepUp' : 'https://centinelapistag.cardinalcommerce.com/V2/Cruise/StepUp';
  useEffect(() => {
    document.querySelector('#step_up_iframe')
      .contentDocument.write(`<form id="step_up_form" name="stepup" method="POST" action="${url}">` +
        '<input id="step_up_form_jwt_input" type="hidden" name="JWT" value="' + jwt + '">' +
        '<button style="display:none;" type="submit">Valider</button></form>' +
        '</form>');
    document.querySelector('#step_up_iframe')
      .contentDocument.getElementsByTagName('button')[0].click()
  }, []);

  const iframe = '<iframe id="step_up_iframe" style="height:100%; width: 100%;border: none; margin-left: auto; margin-right: auto; display: block;"></iframe>';


  return (
    <>
      <div style={{ width: '100%', height: '100%' }} className="cont_modal center" dangerouslySetInnerHTML={{ __html: iframe }}></div>
    </>
  );
}
