import React, { useContext } from "react";
import Confirmation from "../components/Confirmation";
import { ThemeContext } from "../components/Wrapper";

const SuccessConfirmationCode = () => {
  const context = useContext(ThemeContext)
  const your_account_has_been_activated = context.translate('your_account_has_been_activated')
  const you_can_now_close_this_window = context.translate('you_can_now_close_this_window') 
  return <Confirmation
    title={`${your_account_has_been_activated}.`}
    message={`${you_can_now_close_this_window}.`}
  />
};

export default SuccessConfirmationCode;