const crypto = require('crypto');

export function encrypt (textToEncrypt) {
  try {
    const publicKey = process.env.REACT_APP_ENCRYPTION_PUBLIC_KEY;
    let encrypted = crypto.publicEncrypt(publicKey, Buffer.from(textToEncrypt));

    return encrypted.toString('hex')
  } catch (err) {
    console.error(err);
  }
}