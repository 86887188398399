import React, { useState, createContext, useEffect } from "react";
import { Dimmer, Loader, Message } from "semantic-ui-react";
import Cookies from "universal-cookie";
import { getCurrencyEuroTo } from "../services/currency";
import { useTranslation } from "react-i18next";
import '../index.css'
import './loader.css'
export const ThemeContext = createContext({
  showLoader: null,
  showThemeLoader: null,
  showError: null,
  cookies: null,
  showLogin: null,
  setIsTokenValid: null,
  isTokenValid: null,
  gehotel: null,
  setHotel: null,
  isRedirectFromBookingForm: false,
  setIsRedirectFromBookingForm: (isRedirect) => { }
});

const Wrapper = ({ children }) => {
  const { t: translate, i18n } = useTranslation();

  const checkCartOwner = () => {
    if (localStorage.getItem('cartOwner') !== getHotelName()) {
      localStorage.removeItem('cartOwner');
      localStorage.removeItem('cart');
      localStorage.removeItem('cartExpirationDate');
      localStorage.removeItem('cartId');
    }

  };

  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "fr")
    }
    i18n.changeLanguage(localStorage.getItem("lang") || "fr");
    localStorage.removeItem('hotel');
    checkCartOwner();
  }, []);

  const currencyOptions = [
    { symbol: "€", value: "euro", text: "Euro" },
    { symbol: "$", value: "usd", text: "Usd" },
  ];

  const recommendedCurrencies = [
    {
      symbol: 'MGA',
      code: 'MGA',
      shortName: 'MGA',
      fullName: 'Ariary Madagascar',
    },
    {
      symbol: '€',
      code: 'EURO',
      shortName: 'eur',
      fullName: 'Devise européene'
    },
    {
      symbol: '$',
      code: 'USD',
      shortName: 'usd',
      fullName: 'Dollar américain',
    },
    {
      symbol: '£',
      code: 'GBP',
      shortName: 'gbp',
      fullName: 'Pound Sterling'
    },
    {
      symbol: 'C$',
      code: 'CAD',
      shortName: 'cad',
      fullName: 'Canadian Dollar'
    }
  ];

  const currencies = [
    {
      symbol: 'MGA',
      code: 'MGA',
      shortName: 'mga',
      fullName: 'Ariary Madagascar',
    },
    {
      symbol: '€',
      code: 'EURO',
      shortName: 'eur',
      fullName: 'Devise européene'
    },
    {
      symbol: '$',
      code: 'USD',
      shortName: 'usd',
      fullName: 'Dollar américain',
    },
    {
      symbol: '£',
      code: 'GBP',
      shortName: 'gbp',
      fullName: 'Pound Sterling'
    },
    {
      symbol: 'C$',
      code: 'CAD',
      shortName: 'cad',
      fullName: 'Canadian Dollar'
    }
  ];

  const [hotelState, setHotelState] = useState({
    _id: "",
    link: "",
    phoneNum: "",
    emailAddress: "",
    vignette: "",
    photo: [],
    isTVAIncluded: "",
    checkIn: "",
    checkOut: "",
    address: "",
    name: "change_this_to_the_hotel_name",
    maxBabyAge: "",
    minBabyAge: "",
    minKidAge: "",
    maxKidAge: "",
    location: {
      "lat": "",
      "lng": ""
    },
    logo: "",
    banner: "",
    theme: {
      typography: {
        h1: "Montserrat",
        h2: "Montserrat",
        h3: "Montserrat",
      },
      btn: {
        primary: "",
        secondary: "",
      },
    },
  });
  const [isTokenValidState, setIsTokenValidState] = useState(false);
  const [isHovering, setIsHovering] = useState({ isHovered: false, email: "" });
  const [isHoveringLogout, setIsHoveringLogout] = useState({
    isHovered: false,
  });
  const [isHoveringPopup, setIsHoveringPopup] = useState({
    isHovered: false,
    index: null,
    toHover: "",
  });
  const [disable, setDisable] = useState(false);
  const [themeLoader, setThemeLoader] = useState(false);
  const [error, setError] = useState(true);
  const [handleChangeCookie, setHandleChangeCookie] = useState(false);
  const [currency, setCurrency] = useState(
    JSON.parse(localStorage.getItem("currency"))
  );
  const [roomDetail, setRoomDetail] = useState(null);
  const [isRedirectFromBookingForm, setIsRedirectFromBookingForm] = useState(false);
  const cookies = new Cookies();

  const showLoader = (show) => {
    setDisable(show);
  };

  const showThemeLoader = (show) => {
    setThemeLoader(show);
  };

  const showError = (show) => {
    setError(show);
  };

  const refresh = () => {
    setHandleChangeCookie(!handleChangeCookie);
  };

  const showLogin = (isHovered, email) => {
    setIsHovering({ isHovered: isHovered, email: email });
  };

  const showLogout = (isHovered) => {
    setIsHoveringLogout({ isHovered: isHovered });
  };

  const showPopup = (isHovered, index, toHover) => {
    setIsHoveringPopup({
      isHovered: isHovered,
      index: index,
      toHover: toHover,
    });
  };

  const onChangeCurrency = ({ symbol, shortName, }) => {
    showLoader(true);
    getCurrencyEuroTo()
      .then((results) => {
        console.log(results);
        setCurrency({
          ...currency,
          name: shortName.toLowerCase(),
          value: results.data.eur[shortName.toLowerCase()],
          symbol
        });
        localStorage.setItem(
          "currency",
          JSON.stringify({
            name: shortName.toLowerCase(),
            value: results.data.eur[shortName.toLowerCase()],
            symbol,
          })
        );
        showLoader(false);
      })
      .catch((e) => {
        throw e;
      });
  };
  const onChangeLanguage = (newLanguage) => {
    localStorage.setItem("lang", newLanguage);
    window.location.reload();
  };
  const setIsTokenValid = (tokenValid, source) => {
    // console.log("setIsTokenValid: ", tokenValid, source);
    setIsTokenValidState(tokenValid);
  };

  const getHotel = () => {
    const hotelTemp = JSON.parse(sessionStorage.getItem('hotel'));
    if (hotelTemp !== null) {
      return hotelTemp;
    }
    return hotelState;
  };
  const setHotel = (newHotel) => {
    const hotelTemp = newHotel;
    setHotelState({ ...hotelTemp });
  }
  const getLanguage = () => {
    return localStorage.getItem('lang') || 'fr'
  }

  const getHotelName = () => {
    const url = window.location.href.split('/');
    const hotelName = decodeURI(url[3].replace('%20', ' ')).split('#')[0];

    return hotelName;
  };

  return (
    <ThemeContext.Provider
      value={{
        showLoader,
        showThemeLoader,
        showError,
        cookies,
        refresh,
        showLogin,
        isHovering,
        setIsHovering,
        setIsTokenValid,
        isTokenValid: isTokenValidState,
        onChangeCurrency,
        currency,
        setCurrency,
        currencyOptions,
        showLogout,
        isHoveringLogout,
        showPopup,
        isHoveringPopup,
        onChangeLanguage,
        getHotel,
        hotelState,
        setHotel,
        translate,
        getLanguage,
        roomDetail,
        setRoomDetail,
        i18n,
        recommendedCurrencies,
        currencies,
        getHotelName,
        isRedirectFromBookingForm,
        setIsRedirectFromBookingForm,
      }}
    >
      {disable && <div className="loaderL loaderLOverlay"></div>}
      {/* {disable &&<div className="loader"></div>} */}

      {/* {disable && <p>GGGGOOOOOOO</p>} */}
      {/* <Message negative hidden={error}>
        <Message.Header>
          An error occurred on the server when processing the URL. Please
          contact the system administrator
        </Message.Header>
      </Message> */}
      {children}
    </ThemeContext.Provider>
  );
};

export default Wrapper;
