import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ModifyBooking from "../../components/booking/ModifyBooking";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import LeaderBoard from "../../components/LeaderBoard";
import { ThemeContext } from "../../components/Wrapper";
import { getBookingSummaryById } from "../../services/booking";

const ModifyBookingPage = () => {
  const [bookingSummaries, setBookingSummaries] = useState([]);
  const [customerInformation, setCustomerInformation] = useState({});
  const [booker, setBooker] = useState({});
  const [modificationTimeline, setModificationTimeline] = useState([]);
  const [isReservationCanceled, setIsReservationCanceled] = useState(false);

  const params = useParams();
  const context = useContext(ThemeContext);

  const giveCustomerInformationValue = (itineraires) => {
    const matchedFields = {
      prenom: "occupier_name",
      nom: "occupier_first_name",
      email: "occupier_email_confirmation",
      professional_phone: "occupier_professional_phone",
      tel: "occupier_phone",
    };

    const customerInformationTemp = {};
    itineraires.forEach((itineraire, i) => {
      itineraire.tarifReserves.forEach((tarifReserve, u) => {
        const incomingFields = Object.keys(tarifReserve.reservateurWithEmail);
        incomingFields.forEach((incomingField) => {
          customerInformationTemp[`${matchedFields[incomingField]}-${i}_${u}`] =
            tarifReserve.reservateurWithEmail[incomingField];
        });
      });
    });
    console.log(customerInformationTemp);
    setCustomerInformation(customerInformationTemp);
  };

  const getBookingSummaryByBookingId = () => {
    context.showError(true);
    context.showLoader(true);
    getBookingSummaryById(params.bookingId)
      .then((results) => {
        console.log(results.data.reservation);
        if (results.data.reservation.itineraires.length > 0) {
          setModificationTimeline(results.data.reservation.infoEtat);
          setBookingSummaries(results.data.reservation.itineraires);
          giveCustomerInformationValue(results.data.reservation.itineraires);
          setBooker({
            booker_name: results.data.reservation.reservateur.nom,
            booker_first_name: results.data.reservation.reservateur.prenom,
            booker_email: results.data.reservation.reservateur.email,
            booker_professional_phone:
              results.data.reservation.reservateur.telPro,
            booker_phone: results.data.reservation.reservateur.tel,
          });
          setIsReservationCanceled(results.data.reservation.etat === 0);
        } else {
          setBookingSummaries([]);
        }
        context.showLoader(false);
      })
      .catch((error) => {
        context.showLoader(false);
        context.showError(false);
      });
  };

  useEffect(() => {
    getBookingSummaryByBookingId();
  }, []);

  return (
    <>
      <Header />
      <main>
        <LeaderBoard />
        <ModifyBooking
          bookingSummaries={bookingSummaries}
          getBookingSummaryByBookingId={getBookingSummaryByBookingId}
          modificationTimeline={modificationTimeline}
          booker={booker}
          isReservationCanceled={isReservationCanceled}
          bookingId={params.bookingId}
          customerInformation={customerInformation}
          setCustomerInformation={setCustomerInformation}
        />
      </main>
      <Footer />
    </>
  );
};

export default ModifyBookingPage;
