import React, { useEffect, useState, useContext } from "react";
import { buildDate } from "../../services/utils";
import CalendarAndOccupationForm from "./CalendarAndOccupationForm";
import { ThemeContext } from "../Wrapper";

const RoomSearchForm = ({
  handleChangeRoomSearchForm,
  searchRoom,
  rooms,
  setRooms,
  searchRoomPayload,
  handleChangeOccupation,
  filtersForSearchForRooms,
  daysFirstMonth,
  daysSecondMonth,
  areDaysToBookReloading,
  checkIn,
  setCheckIn,
  checkOut,
  setCheckOut,
  infoCalendar,
  setInfoCalendar,
  populateCalendar,
  isCalendarContentLoading,
  fillMissingWeekDaysAtMonthStart,
  // VAOVAO
  setDatePayload,
  datePayload,
  setOffresDispo,
  offresDispo,
  handleChangeNbPersonneTarif,
  nbPersonneTarif,
  setNbPersonneTarif,
  prixTotalReservation,
  setPrixTotalReservation,
  offres,
  sousTotalPrix,
  setSousTotalPrix
}) => {
  const context = useContext(ThemeContext);

  /*
    const day = {
      date: '2022-09-15'
      num_day: 1,
      to_pay: 0,
      to_pay_without_promotion: 0,
      promotion_name: 'promo test',
      promotion_value: 4,
      is_promotion_percentage: false,
    };
  */

  const getAllDaysInMonth = (year, month) => {
    const date = new Date(year, month, 1);

    const dates = [];

    while (date.getMonth() === month) {
      dates.push({
        date: buildDate(date),
        num_day: date.getDate(),
        to_pay: null,
        to_pay_without_promotion: null,
        promotion_name: null,
        promotion_value: null,
        is_promotion_percentage: null,
      });
      date.setDate(date.getDate() + 1);
    }
    fillMissingWeekDaysAtMonthStart(dates);

    return dates;
  };

  useEffect(() => {
    const firstDayOfFirstMonth = new Date(infoCalendar.firstDayOfFirstMonth);
    const firstDayOfSecondMonth = new Date(infoCalendar.firstDayOfSecondMonth);
    const infoCalendarTemp = {
      ...infoCalendar,
      daysOfFirstMonth: getAllDaysInMonth(
        firstDayOfFirstMonth.getFullYear(),
        firstDayOfFirstMonth.getMonth()
      ),
      daysOfSecondMonth: getAllDaysInMonth(
        firstDayOfSecondMonth.getFullYear(),
        firstDayOfSecondMonth.getMonth()
      ),
    };
    setInfoCalendar(infoCalendarTemp);

    populateCalendar({ infoCalendarArg: infoCalendar });
  }, []);

  const lang = localStorage.getItem('lang') || 'fr';

  const getLabel = (label) => {
    if (typeof label === 'string') {
      return label;
    }
    return label?.[lang];
  }

  return (
    <>
      <CalendarAndOccupationForm
        searchRoom={searchRoom}
        searchRoomPayload={searchRoomPayload}
        handleChangeOccupation={handleChangeOccupation}
        handleChangeRoomSearchForm={handleChangeRoomSearchForm}
        infoCalendar={infoCalendar}
        setInfoCalendar={setInfoCalendar}
        isCalendarContentLoading={isCalendarContentLoading}
        populateCalendar={populateCalendar}
        daysFirstMonth={daysFirstMonth}
        daysSecondMonth={daysSecondMonth}
        areDaysToBookReloading={areDaysToBookReloading}
        checkIn={checkIn}
        setCheckIn={setCheckIn}
        checkOut={checkOut}
        setCheckOut={setCheckOut}
        setRooms={setRooms}
        // VAOVAO
        setDatePayload={setDatePayload}
        datePayload={datePayload}
        setOffresDispo={setOffresDispo}
        // offresDispo={offresDispo}
        handleChangeNbPersonneTarif={handleChangeNbPersonneTarif}
        nbPersonneTarif={nbPersonneTarif}
        setNbPersonneTarif={setNbPersonneTarif}
        prixTotalReservation={prixTotalReservation}
        setPrixTotalReservation={setPrixTotalReservation}
        offres={offres}
        sousTotalPrix={sousTotalPrix}
        setSousTotalPrix={setSousTotalPrix}
      />
    </>
  );
};

export default RoomSearchForm;
