import axios from "axios";
import api from "../config/api-config";

export const createCaptureContext = (payload) => {
    return axios.get(`${api.host}/payment/createCaptureContext`, payload, {
        /* headers: {
                Authorization: token,
            }, */
    });
};

export const capture = (payload) => {
    return axios.post(`${api.host}/payment/capture`, payload, {});
};
