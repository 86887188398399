import React, { useContext, useEffect } from "react";
import { getFormatedDate } from "../../services/utils";
import { ThemeContext } from "../Wrapper";
import Calendar from "./Calendar";

const CalendarAndOccupationForm = ({
  searchRoom,
  searchRoomPayload,
  handleChangeOccupation,
  handleChangeRoomSearchForm,
  infoCalendar,
  setInfoCalendar,
  isCalendarContentLoading,
  populateCalendar,
  daysFirstMonth,
  daysSecondMonth,
  areDaysToBookReloading,
  checkIn,
  setCheckIn,
  checkOut,
  setCheckOut,
  setRooms,
  // VAOVAO
  setDatePayload,
  datePayload,
  handleChangeNbPersonneTarif,
  nbPersonneTarif,
  setNbPersonneTarif,
  prixTotalReservation,
  setPrixTotalReservation,
  offres,
  sousTotalPrix,
  setSousTotalPrix
}) => {

  const context = useContext(ThemeContext);

  let kidAge = { min: 0, max: 0 };
  if (sessionStorage.getItem('hotel')) {
    const hotel = JSON.parse(sessionStorage.getItem('hotel'));
    kidAge.min = hotel.minKidAge;
    kidAge.max = hotel.maxKidAge;
  }


  return (
    // VAOVAO
    <div className="blcCalandar">
      <h2>{context.translate('calendar_of_park')} Nosy Tanihely</h2>
      {infoCalendar.show && (
        <Calendar
          // VAOVAO
          setDatePayload={setDatePayload}
          datePayload={datePayload}
          searchRoom={searchRoom}
          searchRoomPayload={searchRoomPayload}
          infoCalendar={infoCalendar}
          setInfoCalendar={setInfoCalendar}
          isCalendarContentLoading={isCalendarContentLoading}
          populateCalendar={populateCalendar}
          daysFirstMonth={daysFirstMonth}
          daysSecondMonth={daysSecondMonth}
          areDaysToBookReloading={areDaysToBookReloading}
          checkIn={checkIn}
          setCheckIn={setCheckIn}
          checkOut={checkOut}
          setCheckOut={setCheckOut}
          setRooms={setRooms}
        />
      )}

    </div>
  );
};

export default CalendarAndOccupationForm;
