import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ThemeContext } from "../Wrapper";

import api from "../../config/api-config";
import ModificationTimelineForMyBookingItem from "../booking/ModificationTimelineForMyBookingItem";
import { getFormatedDate } from "../../services/utils";

const MyBookingItem = ({ bookings }) => {

  const navigate = useNavigate();
  const context = useContext(ThemeContext);

  return bookings?.length > 0 ? (
    bookings?.map((booking) => {
      return booking.reserved_room.map((reservedPrices, index) => {
        return (
          <div
            key={`${reservedPrices.confirmation_num}_${index}`}
            className="item"
          >
            <div className="content">
              <div className="h3">
                {reservedPrices.hotel_name} - {reservedPrices.room_type_name}
              </div>
              <div className="contImg">
                <img
                  className="cover"
                  src={
                    api.host +
                    "/" +
                    reservedPrices.room_type_croped_picture.replace("\\", "/")
                  }
                  alt="room"
                />
              </div>
              <div className="pad25 contConf">
                <div className="flex flexBetween">
                  <div className="listConf">
                    <div className="item">
                      {context.translate('your_itinerary_number')} :{" "}
                      <strong>#{booking?.itinerary_num}</strong>
                    </div>
                    <div className="item">
                      {context.translate('room')} {index + 1} {context.translate('confirmation')}:
                      <strong>#{reservedPrices.confirmation_num}</strong>
                    </div>
                    <ModificationTimelineForMyBookingItem
                      modificationTimeline={booking.modification_timeline}
                    />
                  </div>
                </div>
              </div>
              <div className="puce">
                <ul>
                  <li>
                    {context.translate('name')} :{" "}
                    <strong>
                      {reservedPrices.occupier_first_name}{" "}
                      {reservedPrices.occupier_last_name}
                    </strong>
                  </li>
                  <li>
                    {context.translate('phone')} : <strong> {reservedPrices.occupier_tel}</strong>
                  </li>
                  <li>
                    {context.translate('email_address')} :{" "}
                    <a href="#">
                      <strong>{reservedPrices.occupier_email}</strong>
                    </a>
                  </li>
                  <li>
                    {context.translate("arrival_date")} : <strong>{getFormatedDate(booking.checkIn)} </strong>
                  </li>
                  <li>
                    {context.translate('exit_date')} : <strong>{getFormatedDate(booking.checkOut)}</strong>
                  </li>
                  <li>
                    {context.translate('number_of_persons')} :{" "}
                    <strong>{reservedPrices.adults_num} {context.translate('adults')}</strong>
                  </li>
                </ul>
                <a href="#" onClick={() => navigate(`/${context.getHotel().name}/booking-summary/${booking.reservation_id}`)} className="btn">
                  {context.translate('show_informations')}
                </a>
              </div>
            </div>
          </div>
        );
      });
    })
  ) : (
    // <p style={{ color: "red", textAlign: "center" }}>{context.translate('no_results')}</p>
    <p style={{ color: "red", textAlign: "center" }}>Malheureusement, le parc est actuellement au maximum de sa capacité pour aujourd'hui. Pourriez-vous envisager de sélectionner d'autres dates qui vous conviendraient mieux ? Merci de votre compréhension et de votre flexibilité !</p>
  );
};

export default MyBookingItem;
