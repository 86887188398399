import React, { useContext } from "react";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { ThemeContext } from "../../Wrapper";

const InformationContact = ({
  customerInformationError,
  customerInformation,
  handleChange,
}) => {
  const context = useContext(ThemeContext);

  const setBookerProfessionalPhoneNumber = (phoneNumber) => {
    handleChange({
      target: {
        name: 'booker_professional_phone',
        value: phoneNumber,
      }
    });
  };

  const setBookerPhoneNumber = (phoneNumber) => {
    handleChange({
      target: {
        name: 'booker_phone',
        value: phoneNumber,
      }
    });
  };

  return (
    <>
      <div className="blcChp col-50">
        <label>{context.translate("name")} *</label>
        <input
          id="booker_name"
          className={
            customerInformationError.booker_name
              ? "form-control errorForm"
              : "form-control"
          }
          value={customerInformation?.booker_name}
          name="booker_name"
          onChange={handleChange}
          type="text"
        />
        {customerInformationError.booker_name && (
          <div className="textError">
            {customerInformationError.booker_name}
          </div>
        )}
      </div>
      <div className="blcChp col-50">
        <label>{context.translate("first_name")} *</label>
        <input
          id="booker_first_name"
          className={
            customerInformationError.booker_first_name
              ? "form-control errorForm"
              : "form-control"
          }
          value={customerInformation?.booker_first_name}
          name="booker_first_name"
          onChange={handleChange}
          type="text"
        />
        {customerInformationError.booker_first_name && (
          <div className="textError">
            {customerInformationError.booker_first_name}
          </div>
        )}
      </div>
      <div className="blcChp col-100">
        <label>{context.translate("email_address")} *</label>
        <input
          id="booker_email"
          className={
            customerInformationError.booker_email
              ? "form-control errorForm"
              : "form-control"
          }
          value={customerInformation?.booker_email}
          name="booker_email"
          onChange={handleChange}
          type="text"
        />
        {customerInformationError.booker_email && (
          <div className="textError">
            {customerInformationError.booker_email}
          </div>
        )}
        <p className="info">
          {context.translate(
            "this_is_the_email_address_to_which_your_confirmation_will_be_sent"
          )}{" "}
          .
        </p>
      </div>

      <div className="blcChp tel col-50">
        <label>{context.translate("portable_phone")}</label>
        <PhoneInput
          id="booker_phone"
          className={
            customerInformationError.booker_phone
              ? "form-control errorForm"
              : "form-control"
          }
          value={customerInformation?.booker_phone}
          name="booker_phone"
          onChange={(phoneNumber) => setBookerPhoneNumber(phoneNumber)}
        />
        {customerInformationError.booker_phone && (
          <div className="textError">
            {customerInformationError.booker_phone}
          </div>
        )}
      </div>
      {/* 
      <div className="content">
        <div className="h3">{context.translate("contact_informations")}</div>
        <div className="contFormControl">
          <input
            className={
              customerInformationError.booker_name
                ? "form-control errorForm"
                : "form-control"
            }
            value={customerInformation?.booker_name}
            name="booker_name"
            onChange={handleChange}
            type="text"
            placeholder={`${context.translate("name")} *`}
          />
          {customerInformationError.booker_name && (
            <div className="textError">
              {customerInformationError.booker_name}
            </div>
          )}
        </div>
        <div className="contFormControl">
          <input
            className={
              customerInformationError.booker_first_name
                ? "form-control errorForm"
                : "form-control"
            }
            value={customerInformation?.booker_first_name}
            name="booker_first_name"
            onChange={handleChange}
            type="text"
            placeholder={`${context.translate("first_name")}`}
          />
          {customerInformationError.booker_first_name && (
            <div className="textError">
              {customerInformationError.booker_first_name}
            </div>
          )}
        </div>
        <div className="contFormControl">
          <input
            className={
              customerInformationError.booker_email
                ? "form-control errorForm"
                : "form-control"
            }
            value={customerInformation?.booker_email}
            name="booker_email"
            onChange={handleChange}
            type="text"
            placeholder={`${context.translate("email_address")}`}
          />
          {customerInformationError.booker_email && (
            <div className="textError">
              {customerInformationError.booker_email}
            </div>
          )}
          <div className="notefield">
            {context.translate(
              "this_is_the_email_address_to_which_your_confirmation_will_be_sent"
            )}{" "}
            .
          </div>
        </div>
        <div className="form2Cols">
          <div className="contFormControl">
            <PhoneInput
              className={
                customerInformationError.booker_professional_phone
                  ? "form-control errorForm"
                  : "form-control"
              }
              value={customerInformation?.booker_professional_phone}
              placeholder={context.translate('phone_during_the_day')}
              name="professional_phone"
              onChange={(phoneNumber) => setBookerProfessionalPhoneNumber(phoneNumber)}
            />
            {customerInformationError.booker_professional_phone && (
              <div className="textError">
                {customerInformationError.booker_professional_phone}
              </div>
            )}
          </div>
          <div className="contFormControl">
            <PhoneInput
              className={
                customerInformationError.booker_phone
                  ? "form-control errorForm"
                  : "form-control"
              }
              value={customerInformation?.booker_phone}
              placeholder={context.translate("portable_phone")}
              name="booker_phone"
              onChange={(phoneNumber) => setBookerPhoneNumber(phoneNumber)}
            />
            {customerInformationError.booker_phone && (
              <div className="textError">
                {customerInformationError.booker_phone}
              </div>
            )}
          </div>
        </div>
      </div> */}
    </>

  );
};

export default InformationContact;
