import React from "react";

const Confirmation = ({title, message}) => {
  return (
    <div className="modal open">
      <div className="modal_inner">
        <div className="modal_content">
          <div className="head_modal center">
            <img src="images/waiting.png" alt="" />
            <div className="h5">{ title }</div>
          </div>
          <div className="cont_modal center">
            <p>{ message }</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;