import React, { useState, useEffect, useRef, useContext } from 'react';
import { ThemeContext } from '../Wrapper';

export default function DataCollection({
  jwt
}) {

  const url = process.env.REACT_APP_ENV_CYBERSOURCE === 'Prod' ? 'https://centinelapi.cardinalcommerce.com/V1/Cruise/Collect' : 'https://centinelapistag.cardinalcommerce.com/V1/Cruise/Collect';
  useEffect(() => {
    if(jwt != null) {
      document.querySelector('#cyberiframe')
      .contentDocument.write(`<form id="collectionForm" name="devicedata" method="POST" action="${url}">` +
        '<input type="hidden" name="JWT" value="' + jwt + '" /><button type="submit">tesete</button>' +
        '</form>');
      document.querySelector('#cyberiframe')
      .contentDocument.getElementsByTagName('button')[0].click()
    }
  }, []);

  const iframe = '<iframe id="cyberiframe" height="1" width="1" style="display: none;"></iframe>';

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: iframe }}></div>
    </>
  );
}
