import React, { useEffect, useState } from 'react';
import { formatNumber, getDiffDays } from '../../../services/utils';
import { formatDate } from '../../../services/utils';
import { stepUpdateInventory } from '../../../services/room';

const OffresDispo = ({
    offresDispo,
    context,
    handleChangeLocalStorage,
    redirectToBookingPage,
    datePayload,
}) => {

    // console.log('offresDispo : ', offresDispo);
    // console.log('context : ', context);

    const [message, setMessage] = useState('');

    const [sousTotalPrix, setSousTotalPrix] = useState([]);
    // const setTotalPrixV = ()=>{
    //     if(localStorage.getItem("total") !==undefined){
    //         setTotalPrix(localStorage.getItem("total"));
    //     }else{
    //         setSousTotalPrix(0)
    //     }
    // }
    const cart = localStorage.getItem("cart");

    const [totalPrix, setTotalPrix] = useState(cart ? JSON.parse(cart)[0].totalPrice : 0);
    const [nbPersonneActivite, setNbPersonneActivite] = useState([]);




    const handleSousTotalChange = async () => {
        let total = 0;
        sousTotalPrix.forEach(sousTotal => {
            total += sousTotal.prix;
        });
        // console.log("rzddzdfzdf")
        await getTotal();
        setTotalPrix(total);
    }

    const handleNbPersonneChange = () => {

        // console.log('nbPersonne Activite : ', nbPersonneActivite);

        // console.log('sousTotalPrix : ', sousTotalPrix);

        let sousTotalPrixTmp = JSON.parse(JSON.stringify(sousTotalPrix));

        // console.log('sousTotal Tmp BEFORE : ', sousTotalPrixTmp);

        // offresDispo.activites.forEach(activite => {
        //     activite.offres.forEach((offre) => {
        //         // console.log('offre : ', offre);

        //         let nbAdulte = nbPersonneActivite.find(nbPersonneActivite => nbPersonneActivite.nom === `activite-${activite._id}-offre-${offre._id}-adulte`);
        //         let nbEnfant = nbPersonneActivite.find(nbPersonneActivite => nbPersonneActivite.nom === `activite-${activite._id}-offre-${offre._id}-enfant`);
        //         let sousTotal = sousTotalPrixTmp.find(sousTotalPrixTmp => sousTotalPrixTmp.nom === `activite-${activite._id}-offre-${offre._id}`)

        //         // console.log('sousTotal : ', sousTotal);

        //         if (sousTotal) {
        //             sousTotal.prix = (offre.prixAdulte * nbAdulte.nb) + (offre.prixEnfant * nbEnfant.nb);
        //         }

        //         // console.log('sous total prix : ', sousTotal);

        //     })
        // });

        offresDispo.forEach(activite => {
            activite.tarifs.forEach(offre => {
                let nbAdulte = nbPersonneActivite.find(nbPersonneActivite => nbPersonneActivite.nom === `activite-${activite._id}-offre-${offre._id}-adulte`);
                let nbEnfant = nbPersonneActivite.find(nbPersonneActivite => nbPersonneActivite.nom === `activite-${activite._id}-offre-${offre._id}-enfant`);
                let prixAdulte = 0;
                let prixEnfant = 0;
                let sousTotal = sousTotalPrixTmp.find(sousTotalPrixTmp => sousTotalPrixTmp.nom === `activite-${activite._id}-offre-${offre._id}`)
                if (sousTotal) {
                    sousTotal.prix = 0;
                    // console.log(`prixAdulte ${activite._id}-${offre._id} : `, offre.prixAdulte);
                    // console.log(`prixEnfant ${activite._id}-${offre._id} : `, offre.prixEnfant);
                    prixAdulte = offre.prixAdulte ? offre.prixAdulte : 0;
                    prixEnfant = offre.prixEnfant ? offre.prixEnfant : 0;
                    sousTotal.prix += (prixAdulte * nbAdulte.nb) + (prixEnfant * nbEnfant.nb);
                }
            })
        })

        // console.log('sousTotal Tmp AFTER : ', sousTotalPrixTmp);

        setSousTotalPrix(sousTotalPrixTmp);
    }

    const initSousTotalPrix = async () => {
        // let sousTotalPrixTmp = sousTotalPrix;

        let sousTotalPrixTmp = [];
        // console.log("initSousTotalPrix offresDispo: ", offresDispo);
        offresDispo.forEach(activite => {
            // console.log("initSousTotalPrix activite: ", activite);
            activite.tarifs.forEach(offre => {
                sousTotalPrixTmp.push({
                    nom: `activite-${activite._id}-offre-${offre._id}`,
                    prix: 0
                });
            });
        });
        if (localStorage.getItem("cart")) {
            // console.log('efa misy cart, miova sous total');

            let cartFromStorage = JSON.parse(localStorage.getItem("cart"));

            let tarifStorage = cartFromStorage[0].tarifReserves;

            // console.log('tarifStorage : ', tarifStorage);

            // console.log('sousTotal tmp : ', sousTotalPrixTmp);

            // console.log('offresDispo : ', offresDispo);

            // console.log(tarifStorage)
            tarifStorage.forEach(tarifReserve => {
                let activite = offresDispo.find((activite) => activite.nom === tarifReserve.nomTypeChambre);

                // console.log('activite : ', activite);
                // console.log('activite tarifs : ', activite && activite.tarifs);

                let offre = activite && activite.tarifs.find((tarif) => tarif.nom === tarifReserve.nomTarif);

                if (activite && offre) {
                    let sousTotal = sousTotalPrixTmp.find((sousTotal) => sousTotal.nom === `activite-${activite._id}-offre-${offre._id}`);
                    console.log(" TARIF RESERVE : " + tarifReserve.nb)
                    console.log("TARIF PRIX :" + tarifReserve.prix)
                    if (sousTotal) {
                        sousTotal.prix += tarifReserve.nb * tarifReserve.prix
                    }
                    // else {
                    //     console.log('makato tena');
                    //     sousTotalPrixTmp.push({
                    //         nom: `activite-${activite._id}-offre-${offre._id}`,
                    //         prix: tarifReserve.nb * tarifReserve.prix
                    //     });
                    // }
                }
            });
        }
        setSousTotalPrix(sousTotalPrixTmp);
        // console.log("jhhbcbsdbvzdh")
    }

    const initNbPersonneActivite = async () => {
        let nbPersonneActiviteTmp = [];
        offresDispo.forEach(activite => {
            activite.tarifs.forEach(offre => {
                nbPersonneActiviteTmp.push({
                    nom: `activite-${activite._id}-offre-${offre._id}-adulte`,
                    nb: 0
                });
                nbPersonneActiviteTmp.push({
                    nom: `activite-${activite._id}-offre-${offre._id}-enfant`,
                    nb: 0
                });
            });
        });

        if (localStorage.getItem("cart")) {
            let cartFromStorage = JSON.parse(localStorage.getItem("cart"));
            // console.log('nbPersonneActiviteTmp : ', nbPersonneActiviteTmp);
            let idActivite = '', idOffre = '';
            // console.log('offresDispo : ', offresDispo);
            // console.log('misy cart : ', cartFromStorage);

            cartFromStorage.forEach(cart => {
                cart.tarifReserves.forEach(cartElement => {
                    let activiteCart = offresDispo.find((activite) => activite.nom === cartElement.nomTypeChambre);

                    // console.log('activiteCart : ', activiteCart);

                    idActivite = activiteCart && activiteCart._id;

                    offresDispo.forEach(activite => {
                        let offreCart = activite.tarifs.find((offre) => offre.nom === cartElement.nomTarif);
                        // console.log('offreCart : ', offreCart);

                        idOffre = offreCart && offreCart._id;

                        let updateNbPersonne = nbPersonneActiviteTmp.find((nbPersonne) => nbPersonne.nom == `activite-${idActivite}-offre-${idOffre}-${cartElement.categorieAge}`);

                        if (updateNbPersonne) updateNbPersonne.nb = cartElement.nb;

                        // console.log('updateNbPersonne : ', updateNbPersonne);
                    });
                });
            });
        }

        setNbPersonneActivite(nbPersonneActiviteTmp);
    }




    const sousTotalfixe = async () => {
        console.log(nbPersonneActivite)
        console.log(sousTotalPrix)
        // sousTotalPrix.find()
    }

    const SetTotalPrice = async (total) => {
        const local = localStorage.getItem("cart");
        if (local) {
            const carte = JSON.parse(local);
            carte[0].totalPrice = total;
            localStorage.setItem("cart", JSON.stringify(carte));
        }
        // console.log('dfvefgfg === === == ')
    }

    let isRequestInProgress = false;
    let queuedRequests = [];

    const processNextRequest = async () => {
        if (queuedRequests.length > 0) {
            const nextRequest = queuedRequests.shift();
            await allProcessforChange(...nextRequest);
        }
    };

    const handleOccupationChange = async (idActivite, idOffre, age, valueToAdd) => {

        let nbPersonneActiviteTmp = [...nbPersonneActivite];

        let nbPersonne = nbPersonneActiviteTmp.find(nbPersonneActivite => nbPersonneActivite.nom === `activite-${idActivite}-offre-${idOffre}-${age}`)

        if (!valueToAdd) {
            nbPersonne.nb = 0;
        } else {
            nbPersonne.nb += valueToAdd;
        }

        if (nbPersonne.nb <= 0) {
            nbPersonne.nb = 0;
        }

        setNbPersonneActivite(nbPersonneActiviteTmp);
    }

    const allProcessforChange = async (activite, offre, categorieAge, newChange) => {
        if (isRequestInProgress) {
            queuedRequests.push([activite, offre, categorieAge, newChange]);
            return;
        }

        isRequestInProgress = true;
        let change = newChange || 0;

        const payload = {
            idTypeChambre: activite._id,
            date: datePayload,
            change: change,
        };

        try {
            await stepUpdateInventory(payload);
            setMessage('');
            await handleChangeLocalStorage(
                activite,
                offre,
                {
                    adultsNum: 0,
                    childrenNum: 0,
                    prix: categorieAge === 'adulte' ? offre.prixAdulte : categorieAge === 'enfant' ? offre.prixEnfant : '',
                },
                categorieAge,
                change
            );
            await handleOccupationChange(activite._id, offre._id, categorieAge, change);
        } catch (error) {
            setMessage(error.response.data.message);
        } finally {
            isRequestInProgress = false;
            processNextRequest();
            if (document.activeElement && document.activeElement.tagName === 'BUTTON'){
                setTimeout(() => document.activeElement.blur(), 200);
            }
        }
    };

    const getDispo = async (source) => {
        // console.log("getDispo " + source);
        await initSousTotalPrix();
        // console.log("getDispo offresDispo: ", offresDispo);
        // console.log("getDispo datePayload: ", datePayload);
        await initNbPersonneActivite();
        // await sousTotalfixe();
        // await SetTotalPrice()
        await getTotal()
    }

    // useEffect(() => {
    // console.log("Effect");
    // window.addEventListener('storageChange', () => getDispo("event"));
    // }, []);

    useEffect(() => {
        getDispo("effect");
    }, [offresDispo]);

    useEffect(() => {
        handleSousTotalChange();
    }, [sousTotalPrix]);

    useEffect(() => {
        handleNbPersonneChange();
    }, [nbPersonneActivite]);


    let s_t = [];

    const getTotal = async () => {
        let total = 0;

        for (let i = 0; i < s_t.length; i++) {
            total += s_t[i];
            // console.log(s_t[i])
        } if (s_t.length != 0 && total != 0) {
            await SetTotalPrice(total);
        }
        setTotalPrix(total);
    }

    useState(() => {
        // getTotal();
        console.log('useState');
        if (cart) {
            setTotalPrix(JSON.parse(cart)[0]?.totalPrice)
            console.log(JSON.parse(cart)[0]?.totalPrice)
        }
    }, [])

    useEffect(() => {
        console.log('useEffect');
        setMessage('')
    }, [datePayload])

    const cartContainsDate = () => {
        // console.log('cartContainsDate');
        // let result = true;
        let result = false;

        let cartFromLocalStorage = JSON.parse(localStorage.getItem("cart"));

        if (cartFromLocalStorage) {
            let cart = cartFromLocalStorage[0];

            // console.log('cartFrom cartContainsDate : ', cart);

            if (cart.datePayload) {
                if (cart.datePayload !== "") {
                    let datePayload = new Date(cart.datePayload);

                    // console.log('datePayload : ', datePayload);

                    let dateNow = new Date();

                    // console.log('dateNow : ', dateNow);

                    if (getDiffDays(dateNow, datePayload) >= 0) {
                        result = true;
                    }
                }
            }
        }

        return result;
    }

    const handleInputChange = async (activite, offre, categorieAge, newChange) => {
        const resultatFind = nbPersonneActivite.find(nbPersonneActivite => nbPersonneActivite.nom === `activite-${activite._id}-offre-${offre._id}-${categorieAge}`);
        const old = Number(resultatFind.nb)
        const newValue = Number(newChange);
        const change = (old >= 0 && newValue >= 0) ? newValue - old : 0 - old;
        await allProcessforChange(activite, offre, categorieAge, change)
    }

    const test = () => {
        console.log("test offresDispo: ", offresDispo);
        console.log("test nbPersonneActivite: ", nbPersonneActivite);
    }

    return (
        <div className='blcReservation'>
            {/* <button onClick={test}>test</button> */}
            <h2>{context.translate('results')} {formatDate(datePayload)}</h2>

            <div className='textError'>{message}</div>
            <div className="lstReservation">
                {offresDispo.map((activite, index) =>
                    <div
                        className="item"
                        key={index}>
                        <div className='inner'>
                            <h3>{activite.nom}</h3>

                            {activite.tarifs.map((offre, j) =>
                                <div
                                    key={j}
                                >
                                    <div className="head flex flexBetween">
                                        <h4>{offre.nom}</h4>
                                        <div
                                            onMouseEnter={() =>
                                                context.showPopup(
                                                    true,
                                                    `${offre._id}_${activite._id}`,
                                                    "room_search_result_rate_name"
                                                )
                                            }
                                            onMouseLeave={() =>
                                                context.showPopup(
                                                    false,
                                                    `${offre._id}_${activite._id}`,
                                                    "room_search_result_rate_name"
                                                )
                                            }
                                            className="rateName condition"
                                        >
                                            <span className="material-symbols-rounded">info</span>
                                            <div
                                                style={{
                                                    opacity:
                                                        context.isHoveringPopup.toHover ===
                                                            "room_search_result_rate_name" &&
                                                            context.isHoveringPopup.isHovered &&
                                                            `${offre._id}_${activite._id}` ==
                                                            context.isHoveringPopup.index
                                                            ? 1
                                                            : 0,
                                                    visibility:
                                                        context.isHoveringPopup.toHover ===
                                                            "room_search_result_rate_name" &&
                                                            context.isHoveringPopup.isHovered &&
                                                            `${offre._id}_${activite._id}` ==
                                                            context.isHoveringPopup.index
                                                            ? "visible"
                                                            : "hidden",
                                                }}
                                                // onMouseEnter={() => {
                                                //     switchIsPopupToRiseUp(
                                                //         i,
                                                //         u,
                                                //         isPopupRatePlanToRiseUp,
                                                //         setIsPopupRatePlanToRiseUp,
                                                //         rooms
                                                //     )
                                                // }}
                                                className="infoTips bordered"
                                            >
                                                <label
                                                    onClick={() =>
                                                        context.showPopup(
                                                            false,
                                                            `${offre._id}_${activite._id}`,
                                                            "room_search_result_rate_name"
                                                        )
                                                    }
                                                    className="modal_close"
                                                ></label>
                                                <div className="content">
                                                    <h4>{context.translate('fees_informations')}</h4>
                                                    <ul>
                                                        <li>{offre.description}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lst-ite">
                                        <div className="ite flex">
                                            {
                                                offre.prixAdulte > 0 &&
                                                <>
                                                    <strong>{context.translate('adults')} <span>+12 {context.translate('years')}</span></strong>
                                                    <div
                                                        className="numbers-row"
                                                    >
                                                        <button className="dec button" onClick={async () => {
                                                            await allProcessforChange(activite, offre, 'adulte', -1);
                                                        }}>
                                                            <span> - </span>
                                                        </button>

                                                        <input
                                                            type="text"
                                                            readOnly
                                                            name="qtt"
                                                            id="qtt"
                                                            className="qtt"
                                                            // onChange={async (e) => await handleInputChange(activite, offre, 'adulte', e.target.value)}
                                                            value={
                                                                (() => {
                                                                    const resultatFind = nbPersonneActivite.find(nbPersonneActivite => nbPersonneActivite.nom === `activite-${activite._id}-offre-${offre._id}-adulte`);
                                                                    return resultatFind && (resultatFind.nb);
                                                                })()
                                                            }
                                                        />

                                                        <button className="inc button" onClick={async () => {
                                                            await allProcessforChange(activite, offre, 'adulte', 1);
                                                        }
                                                        }>
                                                            <span > + </span>
                                                        </button>
                                                    </div>
                                                    <div className="prix">
                                                        {
                                                            (() => {
                                                                const resultatFind = nbPersonneActivite.find(nbPersonneActivite => nbPersonneActivite.nom === `activite-${activite._id}-offre-${offre._id}-adulte`);
                                                                let result = 0;
                                                                if (resultatFind && (resultatFind.nb > 0)) {
                                                                    result = offre.prixAdulte * resultatFind.nb;
                                                                    // console.log(result)
                                                                    // setS_t(s_t+result)
                                                                    // s_t += result
                                                                }
                                                                return formatNumber(result) + " AR";
                                                            })()
                                                        }
                                                    </div>
                                                </>
                                            }

                                        </div>

                                        <div className="ite flex">
                                            {
                                                offre.prixEnfant > 0 &&
                                                <>
                                                    <strong>{context.translate('children')} <span>+2 {context.translate('years')} - 11 {context.translate('years')}</span></strong>
                                                    <div className="numbers-row">
                                                        <button className="dec button" onClick={async () => {
                                                            await allProcessforChange(activite, offre, 'enfant', -1)
                                                        }}><span>-</span></button>

                                                        <input
                                                            type="text"
                                                            readOnly
                                                            name="qtt"
                                                            id="qtt"
                                                            className="qtt"
                                                            // onChange={async (e) => await handleInputChange(activite, offre, 'enfant', e.target.value)}
                                                            value={
                                                                (() => {
                                                                    const resultatFind = nbPersonneActivite.find(nbPersonneActivite => nbPersonneActivite.nom === `activite-${activite._id}-offre-${offre._id}-enfant`);
                                                                    return resultatFind && (resultatFind.nb);
                                                                })()
                                                            }
                                                        />

                                                        <button className="inc button" onClick={async () => {
                                                            await allProcessforChange(activite, offre, 'enfant', 1);
                                                        }}><span>+</span></button>
                                                    </div>
                                                    <div className='prix'>
                                                        {
                                                            (() => {
                                                                const resultatFind = nbPersonneActivite.find(nbPersonneActivite => nbPersonneActivite.nom === `activite-${activite._id}-offre-${offre._id}-enfant`);
                                                                let result = 0;
                                                                if (resultatFind && (resultatFind.nb > 0)) {
                                                                    result = offre.prixEnfant * resultatFind.nb;
                                                                    // s_t += result
                                                                    // setS_t(s_t+result)
                                                                    // console.log(result)
                                                                }
                                                                return formatNumber(result) + " AR";
                                                            })()
                                                        }
                                                    </div>
                                                </>
                                            }

                                        </div>

                                    </div>

                                    <div className="s-total flex flexBetween">
                                        <span>{context.translate("subtotal")}</span>
                                        <div className='prix'>
                                            {
                                                (() => {
                                                    const resultatFindP = nbPersonneActivite.find(nbPersonneActivite => nbPersonneActivite.nom === `activite-${activite._id}-offre-${offre._id}-enfant`);
                                                    const resultatFindP2 = nbPersonneActivite.find(nbPersonneActivite => nbPersonneActivite.nom === `activite-${activite._id}-offre-${offre._id}-adulte`);
                                                    const resultatFind = sousTotalPrix.find(sousTotalPrix => sousTotalPrix.nom === `activite-${activite._id}-offre-${offre._id}`);
                                                    let t = 0;
                                                    // console.log("dvedv")
                                                    // console.log(totalPrix)
                                                    if (resultatFindP && (resultatFindP.nb > 0)) {
                                                        t += offre.prixEnfant * resultatFindP.nb;
                                                    }
                                                    if (resultatFindP2 && (resultatFindP2.nb > 0)) {
                                                        t += offre.prixAdulte * resultatFindP2.nb;
                                                    }
                                                    s_t.push(t);
                                                    // sousTotalPrix.find(sousTotalPrix => sousTotalPrix.nom === `activite-${activite._id}-offre-${offre._id}`).prix = t; 
                                                    // console.log("SOUS - TOTAL"+t)
                                                    // console.log(t +offre.prixAdulte* resultatFindP2.nb)
                                                    return resultatFind && formatNumber(t);
                                                })()
                                                // s_t
                                            } <sup>AR</sup>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            )}

                        </div>
                    </div>
                )}
            </div>

            <div className="total flex flexBetween">
                <span>{context.translate("total")}</span>
                {/* cart?JSON.parse(cart)[0]?.totalPrice */}
                <div className="prix">{cart ? formatNumber(JSON.parse(cart)[0]?.totalPrice) : formatNumber(totalPrix)}<sup>AR</sup></div>
            </div>
            <button
                className={`${localStorage.getItem("cart") && JSON.parse(localStorage.getItem("cart"))[0].tarifReserves.length > 0 && cartContainsDate() ? "btn-reserver" : "myDisable"}`}
                // disabled={localStorage.getItem("cart") ? false : true}
                disabled={localStorage.getItem("cart") && JSON.parse(localStorage.getItem("cart"))[0].tarifReserves.length > 0 && cartContainsDate() ? false : true}
                onClick={() => { redirectToBookingPage && redirectToBookingPage(totalPrix) }}
            >
                {context.translate("continue_reservation")}

            </button>



        </div>
    );
};

export default OffresDispo;