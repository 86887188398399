import axios from "axios";
import api from "../config/api-config";

export const applyBooking = (payload) => {
  return axios.post(
    `${api.host}/reservation/applyBooking 
  `,
    payload,
    {
      /* headers: {
            Authorization: token,
        }, */
    }
  );
}

export const getBookingSummaryById = (bookingId) => {
  return axios.get(`${api.host}/reservation/details/${bookingId}`);
};

export const validateBooking = (payload) => {
  //console.log({payload})
  return axios.post(
    `${api.host}/reservation/applyWithEmail 
  `,
    payload,
    {
      /* headers: {
            Authorization: token,
        }, */
    }
  );
};

export const requestPayment = (payload) => {
  //console.log({payload})
  return axios.post(
    `${api.host}/reservation/requestPayment 
  `,
    payload,
    {
      /* headers: {
            Authorization: token,
        }, */
    }
  );
};

export const searchBookingByAccount = (payload) => {
  return axios.post(
    `${api.host}/reservation/researchC 
  `,
    payload,
    {
      /* headers: {
            Authorization: token,
        }, */
    }
  );
};

export const searchBookingByItinerary = (payload) => {
  return axios.post(
    `${api.host}/reservation/researchN
  `,
    payload,
    {
      /* headers: {
            Authorization: token,
        }, */
    }
  );
};


export const deleteBookingById = (payload) => {
  return axios.post(
    `${api.host}/reservation/cancel
  `,
    payload,
    {
      /* headers: {
            Authorization: token,
        }, */
    }
  );
};

export const cancelARoom = (payload) => {
  return axios.post(
    `${api.host}/reservation/annuler/tarif
  `,
    payload,
    {
      /* headers: {
            Authorization: token,
        }, */
    }
  );
};

export const sendUpdateReservation = (payload) => {
  return axios.post(
    `${api.host}/reservation/updateReservation
  `,
    payload,
    {
      /* headers: {
            Authorization: token,
        }, */
    }
  );
};

export const deleteImpendingReservation = (payload) => {
  return axios.post(
    `${api.host}/reservation/deleteImpendingReservation`,
    payload
  );
};

export const postponeBooking = (payload) => {
  console.log("postponeBooking payload: ", payload);
  return axios.post(
    `${api.host}/reservation/postpone`,
    payload
  );
}