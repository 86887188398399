import React, {useContext} from "react";
import { getDateYYYYMMDDHHMMSS } from "../../../services/utils";
import { ThemeContext } from "../../Wrapper";

const ModificationTimeline = ({ modificationTimeline }) => {
  const context = useContext(ThemeContext)
  return (
    <div className="shadow35 pad25 mb40 contConf">
      {modificationTimeline.length > 0 && (
        <>
          <h3>{context.translate('status_of_the_reservation')} :</h3>
          {modificationTimeline.map((etat) => {
            return (
              <p key={`${etat.label} ${etat.date}`}>
                <strong>
                  {context.translate(etat.label)} {" "} {context.translate('the').toLowerCase()} {" "} {getDateYYYYMMDDHHMMSS(etat.date)}
                </strong>
              </p>
            );
          })}
        </>
      )}
    </div>
  );
};

export default ModificationTimeline;
