import React, { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "../../Wrapper";
import { postponeBooking } from "../../../services/booking";
import { dateToFullString } from "../../../services/utils";

const ReservationReportModal = ({
    reservations,
    setReservations,
    reservationDetail,
    handleClose,
}) => {
    const context = useContext(ThemeContext);

    const [message, setMessage] = useState('');

    const [newDate, setNewDate] = useState(reservationDetail.dateReport || reservationDetail.dateEntree);

    const modalRef = useRef(null); // Ref to hold the modal element

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            handleClose(); // Call your handleClose function
        }
    };

    const postpone = () => {
        const payload = {
            newDate,
            reservationDetail: reservationDetail,
        }
        postponeBooking(payload)
            .then(result => {
                try {
                    setMessage('');
                    // navigate(`/${urlHotelName}/search-booking`);
                    const newReservationsDetail = {
                        ...reservationDetail,
                        dateReport: newDate,
                        dispoReport: (reservationDetail.dispoReport || 2) - 1,
                        errorMessage: `Ce ticket a été reporté pour le: ${dateToFullString(newDate)}`
                    };
                    console.log("postponeBooking result: ", result);
                    const reservationsTmp = [...reservations];
                    const index = reservationsTmp.findIndex(detail => detail._id === reservationDetail._id);
                    reservationsTmp[index] = newReservationsDetail;
                    setReservations(reservationsTmp);
                    handleClose();
                } catch (error) {
                    console.log("catch error: ", error);
                    throw error;
                }
                // console.log("postponeBooking result: ", result);
            })
            .catch(error => {
                // console.log("postponeBooking error: ", JSON.stringify(error));
                // Object.keys(error).forEach(key => console.log("key: ", key))
                setMessage(error.response.data.message);
                // setMessage("error");
            })
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, [handleClose]);

    return (
        <>
            <div className="modal open">
                <div className="pp-code-confirm" id="ppCodeConfirm"  ref={modalRef}>
                    <div class="content">
                        <form>
                            <h3>{context.translate("postpone")} ticket {reservationDetail.numero}</h3>
                            <div className="bloc-chp">

                                <div className="blcChp col-100">
                                    <label>Nouvelle date</label>
                                    <input
                                        className="form-control"
                                        value={newDate}
                                        onChange={(e) => setNewDate(e.target.value)}
                                        type="date"
                                        style={{
                                            width: "100%"
                                        }}
                                    />
                                </div>

                                <div className="blcChp col-100">
                                    <div style={{ color: 'red' }}>{message}</div>
                                    <input
                                        type="button"
                                        className="btn-primary"
                                        value={context.translate('postpone')}
                                        onClick={postpone}
                                    />
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReservationReportModal;