import React, { useContext } from "react";
import { ThemeContext } from "../Wrapper";
import { getFormatedDate } from "../../services/utils";

const PriceBreakDown = ({ priceList, adultsNum, childrenNum, }) => {

  const context = useContext(ThemeContext);

  let priceForXAdultsAndYChildren = [];
  const isEuro = JSON.parse(localStorage.getItem("currency"))?.name === "euro"
  for (let i = 0; i < priceList.length; i++) {
    for (let u = 0; u < priceList[i].versions.length; u++) {
      if(priceList[i].versions[u].adultsNum === adultsNum
      && priceList[i].versions[u].childrenNum === childrenNum) {
        priceForXAdultsAndYChildren.push({
          date: priceList[i].date.split('T')[0],
          price: isEuro
            ? priceList[i].versions[u].prix.toFixed(2) + ' €'
            : (priceList[i].versions[u].prix * context.currency.value).toFixed(2) + ' $',
        });
        break;
      }
    }
  }

  return (
      priceForXAdultsAndYChildren.map(({ date, price }) => <div>{getFormatedDate(date)} : {price}</div>)
  );
};

export default PriceBreakDown;