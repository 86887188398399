import React, { useState, useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ThemeContext } from "../Wrapper";

const EnterConfirmationCode = ({
  setIsEnterConfirmationCodeOpen,
  clientId,
  preventNavigation,
  callValidationCodeAPI,
  error,
  setError,
  confirmationCode,
  setConfirmationCode,
}) => {
  const context = useContext(ThemeContext);
  const navigate = useNavigate();
  const modalRef = useRef(null); // Ref to hold the modal element

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsEnterConfirmationCodeOpen(false); // Call your handleClose function
    }
  };

  const [registerError, setRegisterError] = useState({
    showRegisterError: false,
    message: "",
  });

  const validateConfirmationCode = () => {
    callValidationCodeAPI({
      client_id: clientId,
      confirmation_code: confirmationCode,
    })
      .then((results) => {
        if (results.data.status === 200) {
          setIsEnterConfirmationCodeOpen(false);
          !preventNavigation && navigate(-1);
        } else {
          setRegisterError({
            showRegisterError: true,
            message: results.data.error,
          });
        }
        context.showLoader(false);
      })
      .catch((error) => {
        context.showLoader(false);
        context.showError(false);
      });
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [setIsEnterConfirmationCodeOpen]);

  return (
    <>
      <div className="modal open">
        <div className="pp-code-confirm" id="ppCodeConfirm" ref={modalRef}>
          <div className="content">

            {/* <div className="blcChp col-100"> */}

            <form>
              <h3>{context.translate("code_validation")}</h3>
              <p>{context.translate("email_confirmation_by_code")}</p>
              <div className="bloc-chp">
                <div className="blcChp col-100">
                  <label>Code</label>
                  <input
                    className={error ? "form-control errorForm" : "form-control"}
                    type="text"
                    name="code"
                    value={confirmationCode}
                    onChange={(e) => {
                      setConfirmationCode(e.target.value);
                      setError && setError(null);
                    }}
                  />
                </div>
                {registerError.showRegisterError && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {registerError.message}
                  </p>
                )}
                <div className="blcChp col-100">
                  <input
                    type="button"
                    name=""
                    value={context.translate('validate')}
                    className="btn-primary"
                    onClick={validateConfirmationCode}
                  />
                </div>
              </div>
            </form>

            {/* </div> */}

          </div>
        </div>
      </div>

      {/* <div className="modal open">
        <div className="modal_inner">
          <label
            className="modal_close"
            htmlFor="modal-1"
            onClick={() => {
              setIsEnterConfirmationCodeOpen(false);
              !preventNavigation && navigate(`/${context.getHotel().name}`);
            }}
          ></label>
          <div className="modal_content">
            <div className="head_modal center">
              <img src="images/waiting.png" alt="" />
              <div className="h5">{context.translate("code_validation")}</div>
            </div>
            <div className="cont_modal center">
              {registerError.showRegisterError && (
                <p style={{ color: "red", textAlign: "center" }}>
                  {registerError.message}
                </p>
              )}
              <p>{context.translate("email_confirmation_by_code")}</p>
              <div className="contFormControl">
                <input
                  className={error ? "form-control errorForm" : "form-control"}
                  type="text"
                  placeholder="Code"
                  name="code"
                  value={confirmationCode}
                  onChange={(e) => {
                    setConfirmationCode(e.target.value);
                    setError && setError(null);
                  }}
                />
                {error && <div className="textError">{error}</div>}
              </div>
              <a className="btn w100" onClick={validateConfirmationCode}>
                {context.translate('validate')}
              </a>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default EnterConfirmationCode;
