
import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from './Wrapper';

const DefaultHotel = () => {
    const context = useContext(ThemeContext)
    const navigate = useNavigate();
    useEffect(() => {
        // navigate('/Colbert');
        navigate('/nosymanga');
    }, []);
    return (
        <>
            {/* <h1>{`${context.translate('please_choose_a_hotel')}, ${context.translate('example')} : `}/colbert</h1> */}
            <h1>{`${context.translate('please_choose_a_hotel')}, ${context.translate('example')} : `}/nosymanga</h1>
            <p>.</p>
        </>
    );
};

export default DefaultHotel;