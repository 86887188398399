import React,{useContext} from "react";
import { Fragment } from "react";
import { ThemeContext } from "../../Wrapper";
import { getFormatedDate } from "../../../services/utils";

const ModifyPolitic = ({ rate, itinerary, dateMadagascar, }) => {
  const context = useContext(ThemeContext)
  const lang = localStorage.getItem('lang') || 'fr';
  return (
    <div className="foot">
      <div className="infoTips">
        <h4>{context.translate('warranty_politic')}</h4>
        <ul>
          <li>
          {context.translate('details_warranty_policy')}
          </li>
        </ul>
        {rate.politiqueAnnulAtrb && rate.politiqueAnnulAtrb[0] && (
          <h4>{context.translate('cancellation_politic')} : {rate.politiqueAnnulAtrb[0].names[lang]}</h4>
        )}
        <ul>
          {
            rate.politiqueAnnulAtrb[0]?.remboursable
            && !rate.politiqueAnnulAtrb[0]
              ?.cancelingInfoWithPreciseDate[0]
              .gracePeriodEnded 
            && (
              <li key="start">
                {context.translate(
                  "you_can_freely_cancel_your_reservation_before"
                )}{" "}
                {
                  getFormatedDate(
                    rate.politiqueAnnulAtrb[0]
                    .cancelingInfoWithPreciseDate[0].date
                  )
                }{" "}
                {context.translate("at_noon")}
              </li>
            )
          }
          {rate.politiqueAnnulAtrb[0]?.remboursable &&
            rate.politiqueAnnulAtrb[0]?.cancelingInfoWithPreciseDate.map(
              (cancelingInfoWithPreciseDate, index) => {
                
                if (cancelingInfoWithPreciseDate.gracePeriodEnded 
                  && !cancelingInfoWithPreciseDate.isCheckIn) {
                  return (
                    <li key={`${cancelingInfoWithPreciseDate.date}_${cancelingInfoWithPreciseDate.pourcentage}`}>
                      {context.translate("In_the_event_of_cancellation,_the_cancellation_costs_will_be")}
                      {cancelingInfoWithPreciseDate.pourcentage}{" "}%.
                    </li>
                  );
                }

                if (!cancelingInfoWithPreciseDate.isCheckIn) {
                  return (
                    <li key={`${cancelingInfoWithPreciseDate.date}_${cancelingInfoWithPreciseDate.pourcentage}`}>
                      {context.translate('in_case_an_annulation_occures_after')}{" "}
                      {getFormatedDate(cancelingInfoWithPreciseDate.date)} 
                      {context.translate('at_noon_your_card_will_be_debited_by')}{" "}
                      {cancelingInfoWithPreciseDate.pourcentage} %.
                    </li>
                  );
                }
              }
            )}
            <li key="end">
              {context.translate('your_card_will_be_debited_by_the_reservation_s_total_price_in_your_arrival_in_unless')}
            </li>
        </ul>
      </div>
    </div>
  );
};

export default ModifyPolitic;
