import React, { useContext } from 'react';
import { ThemeContext } from '../Wrapper';

const CartTimeUp = ({
  isModalTimeUpOpened,
  setIsModalTimeUpOpened,
}) => {
  const context = useContext(ThemeContext)
  return (
    <>
      {isModalTimeUpOpened ? (
        <div className="modal open">
          <div className="modal_inner">
            <label
              className="modal_close"
              htmlFor="modal-1"
              onClick={() => {
                setIsModalTimeUpOpened(false);
              }}
            ></label>
            <div className="modal_content">
              <div className="head_modal center">
                <img src="images/waiting.png" alt="" />
                <div className="h5">{context.translate('your_reservation_will_expire_in')} 15 {context.translate('minutes')}.</div>
              </div>
              {/* <div className="cont_modal center">
                <p>Souhaitez-vous mettre à jour votre recherche?</p>
                <a
                  className="btn"
                  href="#"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  vérifier la disponibilité
                </a>
              </div> */}
            </div>
          </div>
        </div>
      ) : null}{" "}
    </>
  );
};

export default CartTimeUp;