import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../Wrapper";
import sortedNationality from "../../../data/nationality";
import IndividualInfoForm from "./IndividualInfoForm";
import { formatDate, formatNumber } from "../../../services/utils";

const ActivitySummary = ({
    itinerary,
    indexItinerary,
    customers,
    handleChange,
    setOccupierInformationToBooker,
    occupierInformationErrors,
    customerInformation,
    setCustomerInformation,
    removeItemFromCart,

    occupierIds,
    setOccupierIds,
}) => {
    const context = useContext(ThemeContext);

    const setOccupierPhoneNumber = (phoneNumCategory, phoneNumber) => {
        handleChange({
            target: {
                name: phoneNumCategory,
                value: phoneNumber,
            }
        });
    };

    const lang = localStorage.getItem('lang') || 'fr';

    const [data, setData] = useState(
        [
            {
                "nomTarif": "NATIONAUX",
                "date": "2024-01-22",
                "activites": [
                    {
                        "nom": "ENTREE PARC",
                        "description": "Optez pour notre offre spéciale comprenant le petit déjeuner gastronomique et le transfert aéroport pour une expérience de voyage sans souci. Lors de votre séjour dans nos chambres de luxe, profitez du confort et du raffinement. Commencez chaque journée en savourant notre petit déjeuner varié, des pâtisseries fraîches aux plats chauds personnalisés. De plus, notre service de transfert aéroport vous assure une arrivée et un départ en toute sérénité. Laissez-nous gérer les détails logistiques pour que vous puissiez profiter pleinement de votre voyage, que ce soit pour les affaires ou les loisirs. Réservez dès maintenant pour une escapade sans tracas.",
                        "nombre": [
                            {
                                "type": "adulte",
                                "nb": 2
                            },
                            {
                                "type": "enfant",
                                "nb": 1
                            }
                        ]
                    }
                ]
            },
            {
                "nomTarif": "ETRANGER",
                "date": "2024-01-22",
                "activites": [
                    {
                        "nom": "ENTREE PARC",
                        "description": "Lorem",
                        "nombre": [
                            {
                                "type": "adulte",
                                "nb": 2
                            }
                        ]
                    }
                ]
            }
        ]
    );

    const transformData = (source) => {
        if (!source || typeof source !== 'object') {
            console.error("transformData: Invalid source object");
            return []; // or handle this case according to your needs
        }
        const transformedData = [];
        const reservation = source;
        // Check if the reservation object has the expected structure
        if (reservation && reservation.tarifReserves && Array.isArray(reservation.tarifReserves)) {
            reservation.tarifReserves.forEach((tarif) => {
                let currentTarif = undefined;
                const activite = {
                    nom: tarif.nomTypeChambre,
                    description: tarif.description,
                    nombre: [
                        {
                            type: tarif.categorieAge.toLowerCase(),
                            nb: tarif.nb,
                        },
                    ],
                };
                const existingEntry = transformedData.find((entry) => entry.nomTarif === tarif.nomTarif);
                if (existingEntry) {
                    const existingActivity = existingEntry.activites.find((act) => act.nom === activite.nom)

                    if (existingActivity) {
                        existingActivity.nombre.push({
                            type: tarif.categorieAge.toLowerCase(),
                            nb: tarif.nb,
                        })
                    } else {
                        existingEntry.activites.push(activite);
                    }
                } else {
                    // Otherwise, create a new entry
                    currentTarif = {
                        nomTarif: tarif.nomTarif,
                        date: reservation.dateSejour.debut,
                        activites: [activite],
                    };
                }
                if (currentTarif) transformedData.push(currentTarif);
            })
        } else {
            console.error(`transformData: Invalid reservation object`);
        }

        return transformedData;
    };

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                if (itinerary) {
                    const cartTmp = { ...itinerary };
                    const transformedData = transformData(cartTmp);
                    // Check if the component is still mounted before updating the state
                    if (isMounted) {
                        // setBuiltData(transformedData);
                        setData(transformedData);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
        // Cleanup function to set isMounted to false when the component is unmounted
        return () => {
            isMounted = false;
        };
    }, [itinerary]);

    const test = () => {
        console.log("test customerInformation :", customerInformation);
    }

    return (
        <>
            {/* <button onClick={test}>test</button> */}
            {itinerary?.tarifReserves?.map((rate, indexReservedPrice) => {
                const categoriePers = rate.categorieAge;
                const nbPers = rate.nb;
                return (
                    <div key={indexReservedPrice}>
                        {Array.from({ length: nbPers }, (_, indexPers) => (
                            <div className="blcFormulaire" key={indexPers}>
                                <form >
                                    <div className="blc-txt">
                                        <h4>{rate.nomTarif} ({categoriePers})</h4>
                                        <p>{rate.description}</p>
                                        <ul className="lst-resrvation">
                                            <li className="date">
                                                {
                                                    context.translate('entry_date')
                                                }: <strong>{formatDate(itinerary.datePayload)}</strong>
                                            </li>
                                        </ul>
                                        <div className="total flex flexBetween">
                                            <span>{context.translate('price')}</span>
                                            <div className="prix">{formatNumber(rate.prix)}<sup>AR</sup></div>
                                        </div>
                                        <div className="blcChp col-100 blccheck radio">
                                            <p><strong>{context.translate("who_are_you_booking_for")}</strong></p>
                                            <div className="authentication-account-access-item">
                                                {customers.map((customer) => {
                                                    return (
                                                        <div key={customer.id} className="input-checkbox">
                                                            <input
                                                                name={`${customer.name}-${indexItinerary}_${indexReservedPrice}_${indexPers}`}
                                                                // onChange={(e) => {
                                                                onClick={(e) => {
                                                                    handleChange(
                                                                        e,
                                                                        () => {
                                                                            setOccupierInformationToBooker(
                                                                                `${indexItinerary}_${indexReservedPrice}_${indexPers}`,
                                                                                `${rate._id}_${itinerary._id}`,
                                                                                false
                                                                            );
                                                                        },
                                                                        () => {
                                                                            setOccupierInformationToBooker(
                                                                                `${indexItinerary}_${indexReservedPrice}_${indexPers}`,
                                                                                `${rate._id}_${itinerary._id}`,
                                                                                true
                                                                            );
                                                                        },
                                                                        rate.nbPers
                                                                    );
                                                                }}
                                                                id={`${customer.id}-${indexItinerary}_${indexReservedPrice}_${indexPers}`}
                                                                // type="radio"
                                                                type="checkbox"
                                                            />
                                                            <label
                                                                htmlFor={`${customer.id}-${indexItinerary}_${indexReservedPrice}_${indexPers}`}
                                                            >
                                                                {customer.label}
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <IndividualInfoForm
                                        categoriePers={categoriePers}
                                        rate={rate}
                                        itinerary={itinerary}
                                        indexItinerary={indexItinerary}
                                        indexReservedPrice={indexReservedPrice}
                                        indexPers={indexPers}
                                        occupierInformationErrors={occupierInformationErrors}
                                        customerInformation={customerInformation}
                                        setCustomerInformation={setCustomerInformation}
                                        handleChange={handleChange}
                                        setOccupierPhoneNumber={setOccupierPhoneNumber}

                                        occupierIds={occupierIds}
                                        setOccupierIds={setOccupierIds}
                                    />

                                </form>
                            </div>
                        ))}
                    </div>
                );
            })}
            {/* <div className="total flex flexBetween">
                <span>Total</span>
                <div className="prix">{itinerary.totalPrice}<sup>AR</sup></div>
            </div> */}
        </>
    );
}

export default ActivitySummary;