import React, { useContext, useState } from "react";
import ClickAwayListener from 'react-click-away-listener';
import { ThemeContext } from "../Wrapper";
import { Fragment } from "react";
import moment from "moment";
import { buildDate } from "../../services/utils";
import { migrateDateInventory } from "../../services/room";

const Calendar = ({
  searchRoom,
  searchRoomPayload,
  infoCalendar,
  setInfoCalendar,
  isCalendarContentLoading,
  populateCalendar,
  daysFirstMonth,
  daysSecondMonth,
  areDaysToBookReloading,
  checkIn,
  setCheckIn,
  checkOut,
  setCheckOut,
  setRooms,
  // VAOVAO
  setDatePayload,
  datePayload,
}) => {
  const context = useContext(ThemeContext);
  const [message, setMessage] = useState('');

  // console.log('checkIn Calendar : ', checkIn);
  // console.log('checkOut Calendar : ', checkOut);

  const getFrenchDate = (stringDate) => {
    const dateElements = stringDate.split(' ');
    return `${context.translate(dateElements[0])} ${dateElements[1]}`;
  };

  const monthsDetails = [
    {
      firstDay: "firstDayOfFirstMonth",
      days: "daysFirstMonth",
    }, {
      firstDay: "firstDayOfSecondMonth",
      days: "daysSecondMonth",
    }
  ];
  const months = {
    firstDayOfFirstMonth: infoCalendar.firstDayOfFirstMonth,
    firstDayOfSecondMonth: infoCalendar.firstDayOfSecondMonth,
    daysFirstMonth,
    daysSecondMonth,
  };

  const isBetweenCheckInAndCheckOut = (date) => {
    const dateTemp = new Date(date);
    const tempCheckIn = new Date(checkIn);
    const tempCheckOut = new Date(checkOut);
    if (
      tempCheckIn <= dateTemp
      && dateTemp <= tempCheckOut
    ) {
      return true;
    }
  };

  const buildRevertData = (cart) => {
    const reserves = []
    if (cart && cart[0]) {
      cart[0].tarifReserves.forEach(tarifReserve => {
        reserves.push({
          idTypeChambre: tarifReserve.idTypeChambre,
          idTarif: tarifReserve._id,
          date: cart[0].datePayload,
          change: tarifReserve.nb,
        })
      })
    }
    return reserves
  }

  const setCheckInOrCheckOut = (day) => {
    const date = day.date;

    const cartTmp = JSON.parse(localStorage.getItem("cart"));
    const payload = {
      reserves: buildRevertData(cartTmp),
      newDate: date,
    }
    migrateDateInventory(payload)
      .then(result => {
        console.log("migrateDateInventory result: ", result);
        setMessage('');
        if ((new Date(date) < new Date(dateNow))

          || ((day.to_pay === 0 || !day.to_pay) && new Date(day.date) >= dateNow)
        ) {
          return
        };

        let checkInTemp = checkIn;
        let checkOutTemp = checkOut;
        let searchRoomPayloadArg = null;

        if (!checkInTemp || (checkInTemp && checkOutTemp && (checkInTemp !== checkOutTemp))) {
          checkInTemp = date;
          checkOutTemp = date;
          // console.log("CONSOLE 1") 
          shiftOneMonth(0);
          setRooms([]);
        } if (checkInTemp === checkOutTemp) {
          // console.log(checkOutTemp + " == " + checkInTemp);
          checkOutTemp = date;

          searchRoomPayloadArg = {
            ...searchRoomPayload,
            dateDebut: new Date(checkInTemp) > new Date(checkOutTemp) ? checkOutTemp : checkInTemp,
            dateFin: new Date(checkInTemp) > new Date(checkOutTemp) ? checkInTemp : checkOutTemp,
            // VAOVAO
            datePayload: date
          };
          searchRoom({
            searchRoomPayloadArg: searchRoomPayloadArg
          });
        };

        setInfoCalendar({ ...infoCalendar });

        if (checkInTemp && checkOutTemp && new Date(checkInTemp) > new Date(checkOutTemp)) {
          // console.log('makato !!');

          setCheckIn(checkOutTemp);
          // NIALA
          // setCheckOut(checkInTemp);
          setCheckOut(checkOutTemp);
          return;
        }
        // console.log('checkInTemp : ', checkInTemp);
        // console.log('checkOutTemp : ', checkOutTemp);
        setCheckIn(checkInTemp);
        setCheckOut(checkOutTemp);
      })
      .catch(error => {
        setMessage(error.response.data.message);
      });
  };

  const dateNow = new Date(buildDate(new Date()));

  const getDateShiftedOneMonth = (date, monthNum) => {
    let dateOneMonthBefore = new Date(date);
    dateOneMonthBefore.setMonth(dateOneMonthBefore.getMonth() + monthNum);
    dateOneMonthBefore = buildDate(dateOneMonthBefore);

    return dateOneMonthBefore;
  };

  const shiftOneMonth = (monthNum) => {
    let firstDayOfFirstMonth = getDateShiftedOneMonth(
      infoCalendar.firstDayOfFirstMonth,
      monthNum
    );
    let firstDayOfSecondMonth = getDateShiftedOneMonth(
      infoCalendar.firstDayOfSecondMonth,
      monthNum
    );

    const infoCalendarTemp = {
      ...infoCalendar,
      firstDayOfFirstMonth,
      firstDayOfSecondMonth
    };

    const dateNow = new Date();
    dateNow.setDate(1);
    if (new Date(firstDayOfFirstMonth) >= dateNow
      || new Date(firstDayOfSecondMonth) >= dateNow
    ) {
      populateCalendar({
        infoCalendarArg: infoCalendarTemp,
        resetCheckInCheckOut: false,
      });
    }

  };

  const isDateBetweenCheckInAndCheckOut = (date) => {
    const dateTemp = new Date(date);
    if (new Date(checkIn) <= dateTemp
      && dateTemp <= new Date(checkOut)
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="textError">{message}</div>
      <ClickAwayListener onClickAway={() => setInfoCalendar({ ...infoCalendar })}>
        <div className="calandar">
          <div className="head">
            <div className="prevList"></div>
            <div className="nextList"></div>
          </div>
          <div className="contentCalandar">
            <div className="navCalandar">
              <div className="prevDate" onClick={() => { shiftOneMonth(-1) }}></div>
              <div className="nextDate" onClick={() => shiftOneMonth(1)}></div>
            </div>
            {monthsDetails.map((monthDetails) => {
              return (
                <div
                  key={monthDetails.firstDay}
                  className="itemMonth"
                >
                  <div className="contentWrappMoth">
                    <div className="headItem">
                      {getFrenchDate(moment(months[monthDetails.firstDay]).format("MMMM YYYY"))}
                    </div>
                    <div className="wrappMonth">
                      <div className="headMonth">
                        <div>{context.translate('sun')}</div>
                        <div>{context.translate('mon')}</div>
                        <div>{context.translate('tue')}</div>
                        <div>{context.translate('wed')}</div>
                        <div>{context.translate('thu')}</div>
                        <div>{context.translate('fri')}</div>
                        <div>{context.translate('sat')}</div>
                      </div>
                      <div className="bodyMonth">
                        {months[monthDetails.days].map((day) => {
                          return new Date(months[monthDetails.firstDay]).getMonth()
                            === new Date(day.date).getMonth()
                            ? (
                              <div
                                key={day.date}
                                className={
                                  "itemNumb"
                                  + (new Date(day.date) < dateNow ? " lastDate" : "")
                                  + (
                                    (day.to_pay === 0 || !day.to_pay)
                                      && new Date(day.date) >= dateNow
                                      && !day.promotion_name
                                      ? " locked"
                                      : ""
                                  )
                                  + (isBetweenCheckInAndCheckOut(day.date) ? " active" : "")
                                  // NIALA
                                  // + (day.promotion_value ? " sold" : "")
                                }
                                onClick={() => setCheckInOrCheckOut(day)}
                              >
                                <div className="content">
                                  <div className="day">{day.num_day}</div>
                                  {
                                    (isCalendarContentLoading
                                      || (
                                        areDaysToBookReloading
                                        && isDateBetweenCheckInAndCheckOut(day.date)
                                      )
                                    )
                                    && <div className="loading"></div>
                                  }
                                </div>
                              </div>
                            )
                            : <div key={day.date} className="itemNumb"></div>;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="legCal">
            <ul>
              <li className="locked">{context.translate('closed')}</li>
              <li className="dSelect">{context.translate('date_selected')}</li>
            </ul>
          </div>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default Calendar;