import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import LeaderBoard from "../../components/LeaderBoard";
import { ThemeContext } from "../../components/Wrapper";
import NavigationLocation from "../../components/NavigationLocation";
import EnterConfirmationCode from "../../components/account/EnterConfirmationCode";
import {
  resetPasswordByEmail,
  sendEmailForResetPassword,
  verifyCodeToResetPassword,
} from "../../services/account";
import RequestResetPasswordCode from "../../components/account/RequestResetPasswordCode";
import ProvideNewPassword from "../../components/account/ProvideNewPassword";

let clientId = null;

const PasswordReset = () => {
  const [passwordResetMessage, setPasswordResetMessage] = useState({
    isError: false,
    message: "",
  });

  const [isEnterConfirmationCodeOpen, setIsEnterConfirmationCodeOpen] = useState(false);

  const context = useContext(ThemeContext);

  const navigate = useNavigate();

  const [customerInformation, setCustomerInformation] = useState({});

  const [wasConfirmationCodeVerified, setWasConfirmationCodeVerified] = useState(false);

  const [confirmationCode, setConfirmationCode] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCustomerInformation((item) => ({
      ...item,
      [name]: value,
    }));
  };

  const sendEmail = () => {
    context.showError(true);
    context.showLoader(true);
    customerInformation.isPartner = false;

    sendEmailForResetPassword(customerInformation)
      .then((results) => {
        if (results.data.status === 200) {
          setIsEnterConfirmationCodeOpen(true);
          setCustomerInformation({ _id: results.data.user_id });
        } else {
          setPasswordResetMessage({
            isError: true,
            message: results.data.message,
          });
        }
        context.showLoader(false);
      })
      .catch((error) => {
        context.showLoader(false);
        context.showError(false);

        setPasswordResetMessage({
          isError: true,
          message: context.translate('an_error_occured_during_the_treatment_of_your_request')
        });
        throw error;
      });
  };

  const resetPassword = () => {
    const payload = {
      _id: customerInformation._id,
      new_password: customerInformation.new_password,
      confirm_password: customerInformation.confirm_password,
      code: confirmationCode,
      is_partner: false,
    };
    resetPasswordByEmail(payload)
      .then((results) => {
        if (results.data.status === 200) {
          navigate(`/${context.getHotel().name}`);
        } else {
          setPasswordResetMessage({
            isError: true,
            message: results.data.message,
          });
        }
        context.showLoader(false);
      })
      .catch((error) => {
        context.showLoader(false);
        context.showError(false);
        setPasswordResetMessage({
          isError: true,
          message: context.translate('an_error_occured_during_the_treatment_of_your_request')
        });
      });
  };

  const cancel = () => {
    context.showError(true);
    context.showLoader(true);
    setPasswordResetMessage({
      isError: false,
      message: "",
    });
    setCustomerInformation({});
    setTimeout(() => {
      context.showLoader(false);
      context.showError(false);
    }, 500);
  };

  const verifyResetPasswordConfirmationCode = ({
    client_id,
    confirmation_code,
  }) => {
    return new Promise((resolve, reject) => {
      context.showLoader(true);
      verifyCodeToResetPassword({
        client_id,
        confirmation_code,
      })
        .then((results) => {
          if (results.data.status === 200) {
            setPasswordResetMessage({
              isError: false,
              message: null,
            });
            setWasConfirmationCodeVerified(true);
          } else {
            setPasswordResetMessage({
              isError: true,
              message: results.data.message,
            });
          }
          context.showLoader(false);

          resolve(results);
        })
        .catch((error) => {
          context.showLoader(false);
          context.showError(false);
          setPasswordResetMessage({
            isError: true,
            message: context.translate('an_error_occured_during_the_treatment_of_your_request')
          });
          reject(error);
        });
    });
  };

  return (
    <main>
      <LeaderBoard />
      <section className="internalPage">
        <div className="wrapper">
          <NavigationLocation>

            <li>{context.translate('login')}</li>

          </NavigationLocation>
          <div className="boxCenter w810 mxAuto">
            <div className="bordered boxLogin">
              <div className="h3 center">{context.translate('reset_the_password')}</div>
              <p>
                {`${context.translate('enter_the_new_password_to_reset_your_password')} .`}
              </p>
              {
                wasConfirmationCodeVerified
                  ? <ProvideNewPassword
                    handleChange={handleChange}
                    passwordResetMessage={passwordResetMessage}
                    resetPassword={resetPassword}
                    cancel={cancel}
                  />
                  : <RequestResetPasswordCode
                    handleChange={handleChange}
                    passwordResetMessage={passwordResetMessage}
                    sendEmail={sendEmail}
                    cancel={cancel}
                  />
              }
            </div>
          </div>
        </div>
      </section>
      {isEnterConfirmationCodeOpen &&
        <EnterConfirmationCode
          setIsEnterConfirmationCodeOpen={setIsEnterConfirmationCodeOpen}
          clientId={customerInformation._id}
          preventNavigation={true}
          callValidationCodeAPI={verifyResetPasswordConfirmationCode}
          error={passwordResetMessage.isError && passwordResetMessage.message}
          setError={(newErrorVal) => {
            setPasswordResetMessage({
              isError: !!newErrorVal,
              message: newErrorVal,
            })
          }}
          confirmationCode={confirmationCode}
          setConfirmationCode={setConfirmationCode}
        />}
    </main>
  );
};

export default PasswordReset;
