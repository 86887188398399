import React, { useContext, useState } from "react";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { register } from "../../services/account";
import { validateRegisterConfirmationCode } from "../../services/account";
import { ThemeContext } from "../Wrapper";
import NavigationLocation from "../NavigationLocation";
import EnterConfirmationCode from "./EnterConfirmationCode";

import ico1 from "../../styles/parc/images/ico1.png";
import ico3 from "../../styles/parc/images/ico3.png";
import ico4 from "../../styles/parc/images/ico4.png";

let clientId = null;

const RegisterForm = ({
  urlHotelName
}) => {
  const context = useContext(ThemeContext);

  const [errors, setErrors] = useState({});

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmed_password: false,
  });

  const [
    isEnterConfirmationCodeOpen,
    setIsEnterConfirmationCodeOpen,
  ] = useState(false);

  const [customerInformation, setCustomerInformation] = useState({
    name: "",
    first_name: "",
    email: "",
    password: "",
    confirmed_password: "",
    professional_phone: "+2610000000",
    phone: "+2610000000",
    address_1: "Adresse",
    town: "Ville",
    postal_code: "Code postal",
  });

  const [confirmationCode, setConfirmationCode] = useState("");

  const [registerError, setRegisterError] = React.useState({
    showRegisterError: false,
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCustomerInformation((item) => ({
      ...item,
      [name]: value,
    }));
    validate({ [name]: value });
    formIsValid({
      ...customerInformation,
      [name]: value,
    });
  };

  const signUp = () => {
    validate(customerInformation);
    if (formIsValid(customerInformation)) {
      context.showError(true);
      context.showLoader(true);
      customerInformation.isPartner = false;
      register(customerInformation)
        .then((results) => {
          console.log(results.data);
          if (results.data.status === 200) {
            setIsEnterConfirmationCodeOpen(true);
            clientId = results.data.client_id;
          } else {
            if (results.data.isEmailAlreadyExist) {
              context.showLogin(true, customerInformation.email);
              setRegisterError({
                showRegisterError: true,
                message: results.data.errors.email,
              });
            } else {
              setRegisterError({
                showRegisterError: true,
                message:
                  "Une erreur s'est produite lors du traitement de votre demande",
              });
            }
          }
          context.showLoader(false);
        })
        .catch((error) => {
          context.showLoader(false);
          context.showError(false);
        });
    }
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("name" in fieldValues) {
      temp.name =
        fieldValues.name && fieldValues.name.trim() !== ""
          ? ""
          : context.translate('this_field_is_required')
    }

    if ("first_name" in fieldValues)
      temp.first_name =
        fieldValues.first_name && fieldValues.first_name.trim()
          ? ""
          : context.translate('this_field_is_required')

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : context.translate('this_field_is_required')
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : context.translate('invalid_email')
    }

    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : context.translate('this_field_is_required')
    }

    if ("confirmed_password" in fieldValues) {
      temp.confirmed_password = fieldValues.confirmed_password
        ? ""
        : context.translate('this_field_is_required')
      if (fieldValues.confirmed_password) {
        temp.confirmed_password =
          customerInformation.password === fieldValues.confirmed_password
            ? ""
            : "Les mots de passe ne sont pas conformes.";
      }
    }

    // NIALA
    // if ("professional_phone" in fieldValues) {
    //   temp.professional_phone =
    //     fieldValues.professional_phone &&
    //       fieldValues.professional_phone.trim() !== ""
    //       ? ""
    //       : context.translate('this_field_is_required')
    // }

    // if ("phone" in fieldValues)
    //   temp.phone =
    //     fieldValues.phone && fieldValues.phone.trim() !== ""
    //       ? ""
    //       : context.translate('this_field_is_required')

    // // if ("address_type" in fieldValues)
    // //   temp.address_type = fieldValues.address_type
    // //     ? ""
    // //     : "Ce champ est requis.";

    // // if ("country" in fieldValues)
    // //   temp.country = fieldValues.country ? "" : "Ce champ est requis.";

    // if ("address_1" in fieldValues) {
    //   temp.address_1 =
    //     fieldValues.address_1 && fieldValues.address_1.trim() !== ""
    //       ? ""
    //       : context.translate('this_field_is_required')
    // }

    // if ("town" in fieldValues)
    //   temp.town = fieldValues.town ? "" : context.translate('this_field_is_required')

    // if ("postal_code" in fieldValues)
    //   temp.postal_code = fieldValues.postal_code ? "" : context.translate('this_field_is_required')

    setErrors({
      ...temp,
    });
  };

  const formIsValid = (customerInformation) => {
    const isValid =
      customerInformation.name &&
      customerInformation.first_name &&
      customerInformation.email &&
      customerInformation.password &&
      customerInformation.confirmed_password
    // NIALA
    // &&
    // customerInformation.professional_phone &&
    // customerInformation.phone &&
    // // customerInformation.address_type &&
    // // customerInformation.country &&
    // customerInformation.address_1 &&
    // customerInformation.town &&
    // customerInformation.postal_code &&
    Object.values(errors).every((x) => x === "");
    return isValid;
  };

  const setPhoneNumber = (phoneNumber) => {
    setCustomerInformation({
      ...customerInformation,
      phone: phoneNumber
    });
  };

  const setProfessionalPhoneNumber = (phoneNumber) => {
    setCustomerInformation({
      ...customerInformation,
      professional_phone: phoneNumber
    });
  };

  return (
    <>
      <NavigationLocation urlHotelName={urlHotelName}>
        <li><span>
          {context.translate('account_creation')}
        </span></li>
      </NavigationLocation>
      <section className="contentPage pageInt creation-compte">
        <div class="deco">
          <div class="ico-para ico1 trigger_parallax01">
            <img src={ico1} />
          </div>
          <div class="ico-para ico5 trigger_parallax01">
            <img src={ico3} />
          </div>
          <div class="ico-para ico4 trigger_parallax01">
            <img src={ico4} />
          </div>
        </div>
        <div className="container">
          <h2 class="wow fadeInUp" data-wow-delay="800ms" data-wow-duration="2000ms">{context.translate('account_creation')}</h2>
          <div className="blcFormulaire wow fadeInUp" data-wow-delay="800ms" data-wow-duration="2000ms">
            <h3>{context.translate('contact_informations')}</h3>

            <div className="bloc-chp">
              <div className="blcChp col-50">
                <label>{context.translate('name')} *</label>
                <input
                  className={
                    errors.name ? "form-control errorForm" : "form-control"
                  }
                  type="text"
                  placeholder={`${context.translate('name')} *`}
                  name="name"
                  onChange={handleChange}
                />
                {errors.name && <div className="textError">{errors.name}</div>}
              </div>


              <div className="blcChp col-50">
                <label>{context.translate('first_name')} *</label>
                <input
                  className={
                    errors.first_name ? "form-control errorForm" : "form-control"
                  }
                  type="text"
                  name="first_name"
                  onChange={handleChange}
                  placeholder={context.translate('first_name')}
                />
                {errors.first_name && (
                  <div className="textError">{errors.first_name}</div>
                )}
              </div>


              <div className="blcChp col-100">
                <label>{context.translate('email')} *</label>
                <input
                  className={
                    errors.email ? "form-control errorForm" : "form-control"
                  }
                  type="email"
                  name="email"
                  onChange={handleChange}
                  placeholder={context.translate('email')}
                />
                <div className="notefield">
                  {context.translate('this_is_the_email_address_to_which_your_confirmation_will_be_sent')}.
                </div>
                {errors.email && <div className="textError">{errors.email}</div>}
              </div>

              <div className="blcChp col-50">
                <label>{context.translate('password')} *</label>
                <div className="password">
                  <input
                    className={
                      errors.password ? "form-control errorForm" : "form-control"
                    }
                    type={showPassword.password ? "text" : "password"}
                    name="password"
                    onChange={handleChange}
                    placeholder={context.translate('password')}
                  />
                  {errors.password && (
                    <div className="textError">{errors.password}</div>
                  )}
                  <span
                    onClick={() => {
                      setShowPassword({
                        ...showPassword,
                        password: !showPassword.password,
                      });
                    }}
                    className="material-symbols-rounded pointer"
                  >
                    visibility
                  </span>
                </div>
              </div>

              <div className="blcChp password col-50">
                <label>{context.translate('confirm_your_password')} *</label>
                <div className="password">
                  <input
                    className={
                      errors.confirmed_password
                        ? "form-control errorForm"
                        : "form-control"
                    }
                    type={showPassword.confirmed_password ? "text" : "password"}
                    name="confirmed_password"
                    onChange={handleChange}
                    placeholder={context.translate('confirm_your_password')}
                  />
                  {errors.confirmed_password && (
                    <div className="textError">{errors.confirmed_password}</div>
                  )}
                  <span
                    onClick={() => {
                      setShowPassword({
                        ...showPassword,
                        confirmed_password: !showPassword.confirmed_password,
                      });
                    }}
                    className="material-symbols-rounded pointer"
                  >
                    visibility
                  </span>
                </div>
              </div>
            </div>

            {/* <div className="form2Cols">
              <div className="contFormControl">
                <PhoneInput
                  className={
                    errors.professional_phone
                      ? "form-control errorForm"
                      : "form-control"
                  }
                  placeholder={context.translate('phone_during_the_day')}
                  name="professional_phone"
                  onChange={(phoneNumber) => setProfessionalPhoneNumber(phoneNumber)}
                />
                {errors.professional_phone && (
                  <div className="textError">{errors.professional_phone}</div>
                )}
              </div>
              <div className="contFormControl">
                <PhoneInput
                  className={
                    errors.phone ? "form-control errorForm" : "form-control"
                  }
                  placeholder={context.translate('portable_phone')}
                  name="phone"
                  onChange={(phoneNumber) => setPhoneNumber(phoneNumber)}
                />
                {errors.phone && (
                  <div className="textError">{errors.phone}</div>
                )}
              </div>
            </div>
            <div className="h3 center">Adresse</div>
            <div className="form2Cols">
              <div className="contFormControl">
                <select
                  name="address_type"
                  onChange={handleChange}
                  className="form-control"
                // className={
                //   errors.address_type
                //     ? "form-control errorForm"
                //     : "form-control"
                // }
                >
                  <option value="address_type_1">{context.translate('address_type')} 1 </option>
                  <option value="address_type_2">{context.translate('address_type')} 2</option>
                </select>
                {errors.address_type && (
                  <div className="textError">{errors.address_type}</div>
                )}
              </div>
              <div className="contFormControl">
                <select
                  name="country"
                  onChange={handleChange}
                  className="form-control"
                // className={
                //   errors.country ? "form-control errorForm" : "form-control"
                // }
                >
                  <option value="country_1">{context.translate('country')} 1</option>
                  <option value="country_2">{context.translate('country')} 2 </option>
                </select>
                {errors.country && (
                  <div className="textError">{errors.country}</div>
                )}
              </div>
            </div>
            <div className="contFormControl">
              <input
                name="address_1"
                onChange={handleChange}
                className={
                  errors.address_1 ? "form-control errorForm" : "form-control"
                }
                type="text"
                placeholder={`${context.translate('address')} 1`}
              />
              {errors.address_1 && (
                <div className="textError">{errors.address_1}</div>
              )}
            </div>
            <div className="form2Cols">
              <div className="contFormControl">
                <input
                  name="town"
                  onChange={handleChange}
                  className={
                    errors.town ? "form-control errorForm" : "form-control"
                  }
                  type="text"
                  placeholder="Ville"
                />
                {errors.town && <div className="textError">{errors.town}</div>}
              </div>
              <div className="contFormControl">
                <input
                  name="postal_code"
                  onChange={handleChange}
                  className={
                    errors.postal_code
                      ? "form-control errorForm"
                      : "form-control"
                  }
                  type="text"
                  placeholder={context.translate('postal_code')}
                />
                {errors.postal_code && (
                  <div className="textError">{errors.postal_code}</div>
                )}
              </div>
            </div> */}

            <br />

            <div className="blcChp col-100">
              <a className="btn-primary fancybox" onClick={signUp} title={context.translate('register')}>
                {context.translate('register')}
              </a>
            </div>
            {registerError.showRegisterError && (
              <p style={{ color: "red", textAlign: "center" }}>
                {registerError.message}
              </p>
            )}
            {/* <div className="clr"></div> */}
            {isEnterConfirmationCodeOpen && (
              <EnterConfirmationCode
                isEnterConfirmationCodeOpen={isEnterConfirmationCodeOpen}
                setIsEnterConfirmationCodeOpen={setIsEnterConfirmationCodeOpen}
                clientId={clientId}
                callValidationCodeAPI={validateRegisterConfirmationCode}
                confirmationCode={confirmationCode}
                setConfirmationCode={setConfirmationCode}
              />
            )}
          </div>
        </div>
      </section >

    </>
  );
};

export default RegisterForm;
