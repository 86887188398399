import React, { useState, useContext } from "react";
import { ThemeContext } from "../Wrapper";
import { useNavigate } from "react-router-dom";

const SearchBookingByItinerary = ({ urlHotelName, setParamsForSearchBooking, searchBookingError }) => {
  const [itineraryInformation, setItineraryInformation] = useState({
    email: "",
    itinerary_number: "",
  });
  const context = useContext(ThemeContext);
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setItineraryInformation((item) => ({
      ...item,
      [name]: value,
    }));

    validate({ [name]: value });
    formIsValid({
      ...itineraryInformation,
      [name]: value,
    });
  };

  const searchBooking = () => {
    validate(itineraryInformation);
    if (formIsValid(itineraryInformation)) {
      setParamsForSearchBooking(
        "by-itinerary",
        itineraryInformation.email,
        itineraryInformation.itinerary_number
      );
    }
    // navigate(
    //   `/my-booking/by-itinerary/${itineraryInformation.email}/${itineraryInformation.itinerary_number}`
    // );
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : "Ce champ est requis.";
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email invalide.";
    }

    if ("itinerary_number" in fieldValues) {
      temp.itinerary_number = fieldValues.itinerary_number
        ? ""
        : "Ce champ est requis.";
    }

    setErrors({
      ...temp,
    });
  };

  const formIsValid = (newItineraryInformation) => {
    const isValid =
      newItineraryInformation.email &&
      newItineraryInformation.itinerary_number &&
      Object.values(errors).every((x) => x === "");
    return isValid;
  };

  return (
    <div className="contentBox numero wow fadeInRight" data-wow-delay="800ms" data-wow-duration="2000ms">
      <h3 className="h3">{context.translate('with_confirmation_or_reservation_number')}</h3>
      <form>
        <div className="bloc-chp">
          <div className="blcChp col-100">
            <label>{context.translate('email')} *</label>
            <input
              onChange={handleChange}
              type="email"
              name="email"
              className={errors.email ? "form-control errorForm" : "form-control"}
              placeholder={context.translate("email")}
            />
            {errors.email && <div className="textError">{errors.email}</div>}
          </div>

          <div className="blcChp col-100">
            <label>{context.translate('your_reservation_number')}</label>
            <input
              onChange={handleChange}
              name="itinerary_number"
              className={
                errors.itinerary_number ? "form-control errorForm" : "form-control"
              }
              type="text"
              placeholder={context.translate("your_reservation_number")}
            />
            {errors.itinerary_number && (
              <div className="textError">{errors.itinerary_number}</div>
            )}
          </div>
          {searchBookingError.showSearchBookingError && !searchBookingError.isErrorFindByAccount ? (
            <p style={{ color: "red", textAlign: "center" }}>
              {searchBookingError.message}
            </p>
          ) : null}
          <div className="blcChp col-100">
            <input
              onClick={() => searchBooking()}
              type="button"
              name=""
              className="btn-primary"
              value={context.translate('login')} />
          </div>
          <div className="blcChp col-100">
            <p><strong>{context.translate('you_don_t_know_your_confirmation_number')}</strong></p>
            <p>{context.translate('your_confirmation_number_was_emailed_at_time_of_booking')}<br />{context.translate('please_consult_this_email_to_find_the_number')}</p>
          </div>
        </div>

      </form>

    </div>
  );
};

export default SearchBookingByItinerary;
