import React, {useContext} from 'react';
import { ThemeContext } from '../Wrapper';

const ProvideNewPassword = ({
  handleChange,
  passwordResetMessage,
  resetPassword,
  cancel
}) => {
  const context = useContext(ThemeContext)
  return (
    <>
      <div className="contFormControl">
        <div className="contFormControl">
          <input
            name="new_password"
            onChange={handleChange}
            className="form-control"
            type="text"
            placeholder={`${context.translate('new_password')} *`}
          />
        </div>
        <div className="contFormControl">
          <input
            name="confirm_password"
            onChange={handleChange}
            className="form-control"
            type="text"
            placeholder={`${context.translate('confirm_your_password')} *`}
          />
        </div>
      </div>
      <div className="center">
        <p
          style={{
            color: passwordResetMessage.isError ? "red" : "green",
          }}
        >
          {passwordResetMessage.message}
        </p>
        <a className="btn w100" onClick={resetPassword}>
        {`${context.translate('reinitialize_my_password')} *`}
        </a>
        
        <div className="clr"></div>
        <a
          className="link uppercase bold noLine colorSecondary"
          onClick={cancel}
        >
          {`${context.translate('cancel')} *`}
        </a>
      </div>
    </>
  );
};

export default ProvideNewPassword;