import React, { useMemo, useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../Wrapper";
import NavigationLocation from "../NavigationLocation";
import Timer from "./Timer";
import DataCollection from "./DataCollexion";
import { requestPayment, validateBooking } from "../../services/booking";
import {
  login,
  validateRegisterConfirmationCode,
  verifyToken,
} from "../../services/account";
import InformationContact from "./validate-booking/InformationContact";
import Itinerary from "./validate-booking/Itinerary";
import CreateAccount from "./validate-booking/CreateAccount";
import { useLocation } from "react-router-dom";
import { getDateMadagascar } from "../../services/date";
import NavigationSteps from "../NavigationSteps";
import PaymentForm from "./validate-booking/PaymentForm";
import { encrypt } from "./validate-booking/Encryption";
import ActivitySummary from "./Parc/ActivitySummary";
import imgVisa from "../../styles/parc/images/visa.png";
import imgMasterCard from "../../styles/parc/images/master-card.png";
import imgPaypal from "../../styles/parc/images/paypal.png";
import imgAmercanExpress from "../../styles/parc/images/amercanexpress.png";
import { extractFieldString, isValidCIN } from "../../services/utils";
import { formatNumber } from "../../services/utils";
import { error } from "jquery";
import MyPaymentForm from "./validate-booking/MyPaymentForm";

import ico1 from "../../styles/parc/images/ico1.png";
import ico2 from "../../styles/parc/images/ico2.png";
import ico3 from "../../styles/parc/images/ico3.png";
import ico4 from "../../styles/parc/images/ico4.png";
import ico5 from "../../styles/parc/images/ico5.png";
import ico6 from "../../styles/parc/images/ico6.png";
import ico7 from "../../styles/parc/images/ico7.png";
import ico8 from "../../styles/parc/images/ico8.png";
import FastAction from "./Parc/FastAction";

function getNewTimerEnd() {
  const cartExpirationDate = localStorage.getItem("cartExpirationDate");
  const cart = localStorage.getItem('cart');
  let tmpTimerEnd = null;
  if (cartExpirationDate && cart && JSON.parse(cart)[0].tarifReserves.length > 0) {
    tmpTimerEnd = new Date(cartExpirationDate);
  } else {
    tmpTimerEnd = new Date();
    tmpTimerEnd.setMinutes(tmpTimerEnd.getMinutes() - 14);
    tmpTimerEnd.setSeconds(tmpTimerEnd.getSeconds() - 58);
    localStorage.setItem("cartExpirationDate", tmpTimerEnd.toISOString());
  }

  return tmpTimerEnd;
}

const occupierInFrench = {
  occupier_name: "nom occupant",
  occupier_first_name: "prenom occupant",
  occupier_email_confirmation: "email occupant",
  occupier_phone: "téléphone occupant",
  occupier_professional_phone: "téléphone professionnel occupant",
};

const matchBankAccountError = {
  // cardNumber: 'card_number',
  expirationDate: 'expiration_date',
  // securityCode: 'security_code',
  // VAOVAO
  cardFullname: 'card_fullname'
};

const ValidateBookingForm = ({ cart, urlHotelName }) => {
  const [cartTemp, setCartTemp] = useState([]);
  const [acceptCondition, setAcceptCondition] = useState(false);

  const navigate = useNavigate();

  const context = useContext(ThemeContext);

  const customers = useMemo(
    () => [
      // {
      //   name: "is_principal_client",
      //   id: "mainCustomer",
      //   label: context.translate('I_am_the_main_client'),
      // },
      // {
      //   name: "is_principal_client",
      //   id: "anotherCustomer",
      //   label: context.translate("booking_for_another_client"),
      // },
      {
        name: "is_principal_client",
        id: "mainCustomer",
        label: context.translate('booking_group'),
      },
    ],
    []
  );

  const [occupierIds, setOccupierIds] = useState({});
  const [occupierInformationErrors, setOccupierInformationErrors] = useState(
    {}
  );

  const [validateBookingError, setValidateBookingError] = React.useState({
    showValidateBookingError: false,
    message: "",
  });

  const location = useLocation();

  const [createAccountError, setCreateAccountError] = React.useState(null);
  const [
    isEnterConfirmationCodeOpen,
    setIsEnterConfirmationCodeOpen,
  ] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");

  const [customerInformation, setCustomerInformation] = useState({});
  const [customerInformationError, setCustomerInformationError] = useState({
    booker_name: null,
    booker_first_name: null,
    booker_email: null,
    booker_phone: null,
    booker_professional_phone: null,
    create_account: null,
    password: null,
    confirm_password: null,
  });

  const [bankAccount, setBankAccount] = useState({
    // cardNumber: '',
    expirationDate: '',
    // securityCode: '',
    // VAOVAO
    cardFullname: ''
  });
  const [bankAccountError, setBankAccountError] = useState({
    // card_number: null,
    expiration_date: null,
    // security_code: null,
    // VAOVAO
    card_fullname: null
  });

  const [timerEnd, setTimerEnd] = useState(null);

  const [modalTimeUpAlreadyOpened, setModalTimeUpAlreadyOpened] = useState(
    false
  );
  const [closeErrors, setCloseErrors] = useState(false);

  const [clientId, setClientId] = useState(null);

  const [dateMadagascar, setDateMadagascar] = useState(new Date());

  // const matchBankAccountErrorMessage = {
  //   cardNumber: 'Le champ numéro de carte est requis',
  //   expirationDate: 'Le champ date d\'expiration est requis',
  //   securityCode: 'Le champ code de sécurité est requis'
  // };

  // The following states are infos about payment
  const [reservationPayload, setReservationPayload] = useState(null);
  const [nameOnTheCard, setNameOnTheCard] = useState('');
  const [selectedExpMonth, setSelectedExpMonth] = useState(null);
  const [transientToken, setTransientToken] = useState('');
  const [selectedExpYear, setSelectedExpYear] = useState(null);
  const [microform, setMicroform] = useState(null);
  const [capturedContext, setCaptureContext] = useState('');
  const [jwt_token, setJwtToken] = useState(null);
  const [jwt3d, setJwt3DToken] = useState(null);
  const [requesting, setRequesting] = useState(false)
  const [messageAvailable, setMessageAvailable] = useState(false)
  const [goVoucher, setGoVoucher] = useState('none');
  const matchBankAccountErrorMessage = {
    // cardNumber: context.translate("the_card_number_field_is_required"),
    expirationDate: context.translate("the_expiration_date_field_is_required"),
    // securityCode: context.translate('the_security_code_field_is_required'),
    cardFullname: 'Le champ nom complet sur la carte est requis',
  };

  const manageBookerInputErrors = (inputName, inputValue) => {
    if (!inputValue || inputValue.trim() === "") {
      setCustomerInformationError({
        ...customerInformationError,
        [inputName]: "Le champ '" + [inputName] + "' du reservateur est requis",
      });
    } else if (customerInformationError[inputName]) {
      setCustomerInformationError({
        ...customerInformationError,
        [inputName]: null,
      });
    }

    if (
      inputName === "booker_email" &&
      !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(inputValue)
    ) {
      setCustomerInformationError({
        ...customerInformationError,
        [inputName]: "Email invalide",
      });
    }
  };

  const manageOccupierInputErrors = (inputName, inputValue) => {
    setOccupierInformationErrors((prevOccupierInformationErrors) => {
      const occupierInfoErrors = {
        ...prevOccupierInformationErrors,
        [inputName]: null,
      };

      if (
        !inputName.includes("is_principal_client") &&
        !inputName.includes("cin") &&
        !inputValue &&
        inputValue.trim() === ""
      ) {
        occupierInfoErrors[inputName] =
          "Le champ '" + context.translate(extractFieldString(inputName)) + "' est requis";
      }
      if (
        inputName.includes("email") &&
        !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(inputValue)
      ) {
        occupierInfoErrors[inputName] =
          "Email invalid";
      }
      if (inputName.includes("cin") && !isValidCIN(inputValue)) {
        occupierInfoErrors[inputName] =
          "Le champ '" + extractFieldString(inputName) + "' doit contenir 12 nombres";
      }

      return occupierInfoErrors;
    });
  };

  const manageCreateAccountInputErrors = (inputName, inputValue) => {
    const customerInfo = {
      ...customerInformation,
      [inputName]: inputValue,
    };
    const passwordErrors = {
      password: null,
      confirm_password: null,
    };
    if (customerInfo.create_account) {
      ["password", "confirm_password"].forEach((passwordElt) => {
        if (
          customerInfo.create_account &&
          (!customerInfo[passwordElt] ||
            customerInfo[passwordElt].trim() === "")
        ) {
          passwordErrors[passwordElt] =
            "Le champ '" + passwordErrors[passwordElt] + "' est requis";
        }
      });
    }
    setCustomerInformationError({
      ...customerInformationError,
      ...passwordErrors,
    });
  };

  const manageErrorInput = (inputName, inputValue) => {
    const normalInputs = [
      "booker_name",
      "booker_first_name",
      "booker_email",
      "booker_phone",
      "booker_professional_phone",
    ];

    if (normalInputs.includes(inputName)) {
      manageBookerInputErrors(inputName, inputValue);
    } else if (inputName.includes("occupier")) {
      manageOccupierInputErrors(inputName, inputValue);
    } else {
      manageCreateAccountInputErrors(inputName, inputValue);
    }
  };

  const handleChange = (e, cbMainCustomer, cbAnotherCustomer) => {
    // console.log('handleChange event : ', e);
    // console.log('handleChange cbMainCustomer : ', cbMainCustomer);
    // console.log('handleChange cbAnotherCustomer : ', cbAnotherCustomer);
    let valueAndChecked = getValueAndCheckedById(
      e.target,
      cbMainCustomer,
      cbAnotherCustomer
    );

    // console.log('valueAndChecked : ', valueAndChecked);

    const { name } = e.target;

    if (!valueAndChecked) valueAndChecked = '';
    manageErrorInput(name, valueAndChecked, e.target);

    setCustomerInformation((item) => ({
      ...item,
      [name]: valueAndChecked,
    }));

    setTimerEnd(getNewTimerEnd);
  };

  const removeItemFromCart = (itineraryIndex, ratePlanIndex) => {
    context.showLoader(true);
    const cartInCookiesTemp = cartTemp;

    cartInCookiesTemp[itineraryIndex].tarifReserves.splice(ratePlanIndex, 1);
    if (cartInCookiesTemp.length === 0) {
      localStorage.removeItem("cart");
    }

    setCartTemp(cartInCookiesTemp);
    setTimeout(() => {
      context.showLoader(false);
    }, 500);
  };

  const getValueAndCheckedById = (
    target,
    cbMainCustomer,
    cbAnotherCustomer
  ) => {
    if (target.name === "create_account") {
      return target.checked;
    } else if (target.name.includes("is_principal_client")) {
      // Rey's modif
      if (!target.checked) {
        cbAnotherCustomer();
        return target.checked;
      } else {
        cbMainCustomer();
        return !target.checked;
      }
      if (target.id.includes("mainCustomer")) {
        cbMainCustomer();
        return target.checked;
      } else {
        cbAnotherCustomer();
        return !target.checked;
      }
    } else {
      return target.value;
    }
  };

  const receiveMessage = (event) => {
    //console.log(typeof event.data)
    if (event && event.data && (typeof event.data == 'string') && event.data.indexOf("profile.completed") >= 0) {
      var sessionId = JSON.parse(event.data);
      sessionId = sessionId.SessionId;
      console.log('completed profile sessionId : ' + sessionId);
      validateBookings(sessionId);
    }
    if (event && event.data && (typeof event.data == 'object') && event.data.success != undefined && event.data.success == true) {
      setGoVoucher('ok');
    }
    if (event && event.data && (typeof event.data == 'object') && event.data.success != undefined && event.data.success == false) {
      setGoVoucher('nonok')
    }
  }

  const doesBankAccountErrorExist = () => {
    const bankAccountErrorTemp = {
      // card_number: null,
      expiration_date: null,
      // security_code: null,
      // VAOVAO
      card_fullname: null
    };
    let isErrorExist = false;
    const keys = Object.keys(bankAccount);
    for (let i = 0; i < keys.length; i++) {
      if (!bankAccount[keys[i].trim()]) {
        isErrorExist = true;
        bankAccountErrorTemp[matchBankAccountError[keys[i]]] =
          matchBankAccountErrorMessage[keys[i]];
        setValidateBookingError({
          ...validateBookingError,
          showValidateBookingError: true,
          message: context.translate("all_payment_information_fields_must_be_completed"),
        });
      }
    }
    setBankAccountError(bankAccountErrorTemp);

    return isErrorExist;
  };

  const isErrorExisting = () => {
    // const bankAccountErrorFields = Object.keys(bankAccountError);
    // for (let i = 0; i < bankAccountErrorFields.length; i++) {
    //   if (bankAccountError[bankAccountErrorFields[i]]) {
    //     return true;
    //   }
    // }
    // const errorMessage = "Ce champs est requis";
    if (!customerInformation) {
      setCustomerInformationError({
        booker_name: "Le champ 'booker_name' est requis",
        booker_first_name: "Le champ 'booker_first_name' est requis",
        booker_email: "Le champ 'booker_email' est requis",
        booker_phone: "Le champ 'booker_phone' est requis",
      });
      return "booker_name";
    }

    const occupierErrorId = isOccupierInfoErrorExisting(customerInformation);
    if (occupierErrorId) {
      // console.log('occupierErrorId : ', occupierErrorId);
      setValidateBookingError({
        ...validateBookingError,
        showValidateBookingError: true,
        message: "Tous les champs doivent être remplis",
      });
      return occupierErrorId;
    }

    if (doesBankAccountErrorExist()) {
      console.log("doesBankAccountErrorExist : ", doesBankAccountErrorExist());
      return true;
    }

    const normalInputErrors = {
      booker_name: null,
      booker_first_name: null,
      booker_email: null,
      booker_phone: null,
    };
    const normalInputs = Object.keys(normalInputErrors);

    normalInputs.forEach((normalInput) => {
      normalInputErrors[normalInput] =
        !customerInformation[normalInput] ||
          customerInformation[normalInput].trim() === ""
          ? "Le champ '" + normalInput + "' du reservateur est requis"
          : null;
    });

    const passwordErrors = {
      password: null,
      confirm_password: null,
    };
    if (customerInformation.create_account) {
      ["password", "confirm_password"].forEach((passwordElt) => {
        if (
          customerInformation.create_account &&
          (!customerInformation[passwordElt] ||
            customerInformation[passwordElt].trim() === "")
        ) {
          passwordErrors[passwordElt] =
            "Le champ '" + passwordElt + "' est requis";
        }
      });
    }
    const customerInputInfoErrors = {
      ...normalInputErrors,
      ...passwordErrors,
    };

    setCustomerInformationError(customerInputInfoErrors);
    // console.log("customerInputInfoErrors: ",customerInputInfoErrors);

    // return Object.keys(customerInputInfoErrors).some((errorName) => {
    //   return !!customerInputInfoErrors[errorName];
    // });
    for (const errorName in customerInputInfoErrors) {
      if (customerInputInfoErrors[errorName] !== null) {
        return errorName;
      }
    }
    return null;
  };

  const addNumberOfOccupier = (payload) => {
    const itineraries = JSON.parse(localStorage.getItem("cart"));
    itineraries.forEach((itinerary, i) => {
      itinerary.tarifReserves.forEach((roomBooked, u) => {
        payload
          .itineraries[i]
          .room_types_with_rate_plans_booked[u]
          .occupier_nb_adults = roomBooked.adultsNum;
        payload
          .itineraries[i]
          .room_types_with_rate_plans_booked[u]
          .occupier_nb_children = roomBooked.childrenNum;
      });
    });
  };

  const checkBankAccountError = () => {
    let keysBankAccountError = Object.keys(bankAccountError);
    for (let index = 0; index < keysBankAccountError.length; index++) {
      const elementBankAccountError = bankAccountError[keysBankAccountError[index]];
      if (elementBankAccountError) {
        setValidateBookingError({
          showValidateBookingError: true,
          message: elementBankAccountError,
        });
        context.showError(true);
        return true;
      }
    }

    setValidateBookingError({
      showValidateBookingError: false,
      message: '',
    });
    context.showError(true);

    return false;
  }

  const generateTransientToken = () => {
    const options = {
      expirationMonth: `${selectedExpMonth < 10 ? '0' + selectedExpMonth : selectedExpMonth}`,
      expirationYear: `${selectedExpYear}`
    };
    //console.log({options})

    const tokenPromise = new Promise((resolve, reject) => {
      microform.createToken(options, function (err, token) {
        if (err) {
          reject(err);
        } else {
          resolve(token);
        }
      });
    });

    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => {
        // context.showLoader(false);
        // setValidateBookingError({
        //   showValidateBookingError: true,
        //   message: context.translate('payment_refused'),
        // });
        // context.showError(true);
        // reject(new Error(context.translate('payment_refused')));
      }, 20000); // Replace YOUR_TIMEOUT_IN_MILLISECONDS with your desired timeout value
    });

    // Use Promise.race to return the first settled promise (either token or timeout)
    return Promise.race([tokenPromise, timeoutPromise]);
  };

  const requestPayments = () => {
    const errorId = isErrorExisting()
    if (errorId) {
      const errorElement = document.getElementById(errorId);
      if (errorElement) {
        errorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center', // You can use 'center' or 'end' as well
          // inline: 'nearest', // You can use 'start' or 'end' as well
        });
      }
      return;
    }

    if (!acceptCondition) {
      setValidateBookingError({
        showValidateBookingError: true,
        message: "Vous avez oublié d'accepter la politique de confidentialité et les conditions de réservation",
      });
      context.showError(true);
      return;
    }

    context.showError(true);
    context.showLoader(true);
    if (checkBankAccountError()) {
      return;
    }

    // console.log("validateBooking payload: ", newPayload);
    // console.log("validateBooking payload: ", JSON.stringify(newPayload));
    setRequesting(true);
    generateTransientToken().then((token) => {
      var payload = {};
      setTransientToken(token);
      payload.transient_token = token;
      payload.captured_context = capturedContext;
      requestPayment(payload)
        .then((results) => {
          if (results.data.jwt_token) {
            if (!messageAvailable) {
              setMessageAvailable(true);
              window.addEventListener("message", receiveMessage, true);
            }
            setJwtToken(results.data.jwt_token);
            //alert(results.data.jwt_token);
            //context.showLoader(false);
          } else {
            //context.showLoader(false);
            //alert(results.data.jwt_token);
            setJwtToken(null);
            setRequesting(false)
            context.showLoader(false);
            context.showError(true);
            setValidateBookingError({
              showValidateBookingError: true,
              message: "Une erreur s'est produite lors de votre paiement, veuillez réessayer",
            });
          }
          // console.log("validateBookings success");
        })
        .catch((error) => {
          setJwtToken(null);
          setRequesting(false)
          context.showLoader(false);
          context.showError(true);
          setValidateBookingError({
            showValidateBookingError: true,
            message: "Une erreur s'est produite lors de votre paiement, veuillez réessayer",
          });
        });
    }).catch((e) => {
      console.log('generateTransientToken Error : ', e);
      setJwtToken(null);
      setRequesting(false)
      context.showLoader(false);
      context.showError(true);
      setValidateBookingError({
        showValidateBookingError: true,
        message: "Une erreur s'est produite lors de votre paiement, veuillez réessayer",
      });
      return;
    })
  }
  const validateBookings = (sessionId) => {
    // console.log("validateBookings start");
    const dayOfStayAndnbOfAdultsAndChildren = getDayOfStayAndnbOfAdultsAndChildren();
    // console.log("validateBooking customerInformation: ", customerInformation);
    // console.log("validateBooking dayOfStayAndnbOfAdultsAndChildren: ", dayOfStayAndnbOfAdultsAndChildren);
    console.log('----')
    const payload = {
      ...customerInformation,
      ...dayOfStayAndnbOfAdultsAndChildren,
      bank_account: bankAccount,
    };
    const newPayload = transformPayloadToSend(payload);
    newPayload.url_hotel_name = urlHotelName;
    newPayload.name_on_the_card = nameOnTheCard;
    newPayload.transient_token = document.getElementById('transient_token').value;
    newPayload.captured_context = capturedContext;
    newPayload.sessionId = sessionId;
    setReservationPayload(newPayload);

    validateBooking(newPayload)
      .then((results) => {
        console.log('results validate booking : ', results);
        if (results.data.jwt_token_3d) {
          setJwt3DToken(results.data.jwt_token_3d);
          newPayload.transactionId = results.data.transactionId;
          setReservationPayload(newPayload);
          context.showLoader(false);
          //alert(results.data.jwt_token);
          //context.showLoader(false);
        } else {
          //context.showLoader(false);
          //alert(results.data.jwt_token);
          context.showError(true);
          context.showLoader(false);
          setRequesting(false)
          setJwtToken(null);
          setValidateBookingError({
            showValidateBookingError: true,
            message: "Une erreur s'est produite lors de votre paiement, veuillez réessayer",
          });
        }
        // console.log("validateBookings success");
      })
      .catch((error) => {
        context.showLoader(false);
        context.showError(true);
        setJwtToken(null);
        setRequesting(false)
        setValidateBookingError({
          showValidateBookingError: true,
          message: "Une erreur s'est produite lors de votre paiement, veuillez réessayer",
        });
        // console.log("validateBookings fail");
      });

  };

  const isOccupierInfoErrorExisting = (occupierInfo) => {
    const occupierInfoErrors = {};
    // const errorMessage = "Ce champs est requis";
    let errorExists = false;
    const inputNames = Object.keys(occupierInfo);

    Object.keys(occupierIds).forEach(occupierId => {
      let normalInput = [
        'occupier_nationality',
        'occupier_name',
        'occupier_first_name',
        'occupier_email_confirmation',
        'occupier_phone',
      ]
      // si occupier nationaux
      if (occupierIds[occupierId]) normalInput.push('occupier_cin')
      normalInput.forEach(input => {
        const inputName = input + '-' + occupierId;
        if (
          !occupierInfo[inputName]
          || (typeof occupierInfo[inputName] === "string"
            && occupierInfo[inputName]?.trim() === "")
        ) {
          occupierInfoErrors[inputName] =
            "Le champ  '" + extractFieldString(inputName) + " est requis";
        }
        if (
          inputName.includes("email") &&
          !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(occupierInfo[inputName])
        ) {
          occupierInfoErrors[inputName] =
            "Email invalid";
        }
        if (inputName.includes("cin") && !isValidCIN(occupierInfo[inputName])) {
          occupierInfoErrors[inputName] =
            "Le champ '" + extractFieldString(inputName) + "' doit contenir 12 nombres";
          errorExists = true;
        }
      })
    })

    //mila jerena oe aon no igerena anty
    //le izy ra vo tsis le occupier info
    // const occupierInfoExists = inputNames.some((inputName) => {
    //   if (inputName.includes("occupier")) {
    //     return true;
    //   }
    // });


    setOccupierInformationErrors(occupierInfoErrors);

    return Object.keys(occupierInfoErrors)[0];
  };

  const setOccupierInformationToBooker = (index, rate, isInformationEmpty) => {
    setCustomerInformation(oldCustomerInfo => {
      const occupierInfo = {
        ...oldCustomerInfo,
        [`occupier_name-${index}`]:
          !oldCustomerInfo || isInformationEmpty
            ? ""
            : oldCustomerInfo.booker_name,
        [`occupier_first_name-${index}`]:
          !oldCustomerInfo || isInformationEmpty
            ? ""
            : oldCustomerInfo.booker_first_name,
        [`occupier_email_confirmation-${index}`]:
          !oldCustomerInfo || isInformationEmpty
            ? ""
            : oldCustomerInfo.booker_email,
        // [`occupier_professional_phone-${index}`]:
        //   !oldCustomerInfo || isInformationEmpty
        //     ? ""
        //     : oldCustomerInfo.booker_professional_phone,
        [`occupier_phone-${index}`]:
          !oldCustomerInfo || isInformationEmpty
            ? ""
            : oldCustomerInfo.booker_phone,
      };

      isOccupierInfoErrorExisting(occupierInfo);

      return occupierInfo;
    });

  };

  const setAllNationality = (nationality) => {
    const cartTemp = JSON.parse(localStorage.getItem('cart'));
    if (cartTemp) {
      cartTemp.forEach((itinerary, indexItinerary) => {
        itinerary.tarifReserves.forEach((rate, indexReservedPrice) => {
          if (!rate.isNationaux) {
            Array.from({ length: rate.nb }, (_, indexPers) => {
              handleChange({
                target: {
                  name: `occupier_nationality-${indexItinerary}_${indexReservedPrice}_${indexPers}`,
                  value: nationality,
                }
              })
            })
          }
        })
      })
    }
  }

  const setAllOccupierInformation = () => {
    const cartTemp = JSON.parse(localStorage.getItem('cart'));
    if (cartTemp) {
      cartTemp.forEach((itinerary, indexItinerary) => {
        itinerary.tarifReserves.forEach((rate, indexReservedPrice) => {
          Array.from({ length: rate.nb }, (_, indexPers) => {
            console.log("setAllOccupierInformation: ", `${indexItinerary}_${indexReservedPrice}_${indexPers}`);
            setOccupierInformationToBooker(
              `${indexItinerary}_${indexReservedPrice}_${indexPers}`,
              rate,
              false,
            )
          })
        })
      })
    }
  }

  const getDayOfStayAndnbOfAdultsAndChildren = () => {
    let cart = localStorage.getItem("cart");
    if (cart) {
      cart = JSON.parse(cart);
      let dayOfStayAndnbOfAdultsAndChildren = {};
      cart.map((itinerary, indexItinerary) => {
        // dayOfStayAndnbOfAdultsAndChildren[`num_adults-${index}`] =
        //   itinerary.nbAdulte;
        // dayOfStayAndnbOfAdultsAndChildren[`num_kids-${index}`] =
        //   itinerary.nbEnfant;
        dayOfStayAndnbOfAdultsAndChildren[`check_in`] =
          // itinerary.dateSejour.debut;
          itinerary.datePayload;
        dayOfStayAndnbOfAdultsAndChildren[`check_out`] =
          itinerary.dateSejour.fin;

        // itinerary.tarifReserves.map((rate, indexRate) => {
        //   dayOfStayAndnbOfAdultsAndChildren[
        //     `occupier_nb_person-${indexItinerary}_${indexRate}`
        //   ] = rate.nbPers;
        // });
      });
      return dayOfStayAndnbOfAdultsAndChildren;
    }
  };

  const transformPayloadToSend = (payload) => {
    let newPayload = {};
    newPayload.itineraries = [];
    let itineraries = [];
    for (const key in payload) {
      if (key.includes("-")) {
        // if (!("itineraries" in newPayload)) {
        //   newPayload.itineraries = [];
        // }

        const keySeparateFromIndex = key.split("-");
        // const separateIndex = keySeparateFromIndex[1].split("_");

        if (!itineraries[keySeparateFromIndex[1]]) {
          itineraries[keySeparateFromIndex[1]] = {};
        }

        if (
          !(
            "room_types_with_rate_plans_booked" in
            itineraries[keySeparateFromIndex[1]]
          )
        ) {
          itineraries[
            keySeparateFromIndex[1]
          ].room_types_with_rate_plans_booked = [];
        }

        if (keySeparateFromIndex[1]) {
          if (
            !itineraries[keySeparateFromIndex[1]]
              .room_types_with_rate_plans_booked[keySeparateFromIndex[1]]
          ) {
            itineraries[
              keySeparateFromIndex[1]
            ].room_types_with_rate_plans_booked[keySeparateFromIndex[1]] = {};
          }

          itineraries[
            keySeparateFromIndex[1]
          ].room_types_with_rate_plans_booked[keySeparateFromIndex[1]][
            keySeparateFromIndex[0]
          ] = payload[key];
          // if (separateIndex[2] && separateIndex[3]) {
          //   newPayload.itineraries[
          //     keySeparateFromIndex[1]
          //   ].room_types_with_rate_plans_booked[keySeparateFromIndex[1]][
          //     "id_rate_plan"
          //   ] = separateIndex[2];
          //   newPayload.itineraries[
          //     keySeparateFromIndex[1]
          //   ].room_types_with_rate_plans_booked[keySeparateFromIndex[1]][
          //     "id_room_type"
          //   ] = separateIndex[3];
          // }
        } else {
          itineraries[keySeparateFromIndex[1]][keySeparateFromIndex[0]] =
            payload[key];
        }
      } else {
        newPayload[key] = payload[key];
      }
    }

    let itinerariesTmp = {
      room_types_with_rate_plans_booked: [],
      check_in: payload.check_in,
      check_out: payload.check_out,
    };
    for (const key in itineraries) {
      itinerariesTmp.room_types_with_rate_plans_booked.push(itineraries[key].room_types_with_rate_plans_booked[key]);
    }
    newPayload.itineraries.push(itinerariesTmp);

    newPayload.cart_id = localStorage.getItem('cartId');

    // on crypte bankAccount
    // NIALA
    // newPayload.bank_account = encrypt(JSON.stringify(payload.bank_account));

    return newPayload;
  };

  useEffect(() => {
    console.log(context.isTokenValid);
    context.showLoader(true);
    setCartTemp(cart);
    setTimerEnd(getNewTimerEnd());
    getDateMadagascar()
      .then(date => {
        setDateMadagascar(date)
        context.showLoader(false);
      });
  }, []);

  useEffect(() => {
    setCustomerInformation({
      ...customerInformation,
      ...JSON.parse(localStorage.getItem("user_attribute")),
    });
  }, [localStorage.getItem("user_attribute")]);

  useEffect(() => {
    if (!localStorage.getItem("cart")) {
      navigate(`/${context.getHotel().urlName}/`);
    }
  }, [location]);

  const validateRegisterCodeAndLogin = () => {
    return new Promise((resolve, reject) => {
      validateRegisterConfirmationCode({
        client_id: clientId,
        confirmation_code: confirmationCode,
      })
        .then((result) => {
          if (result.status === 200) {
            login({
              email: customerInformation.booker_email,
              password: customerInformation.password,
            })
              .then((resultLogin) => {
                if (resultLogin.data.status === 200) {
                  localStorage.setItem(
                    "user_session",
                    JSON.stringify(resultLogin.data.user_session)
                  );
                  localStorage.setItem(
                    "user_attribute",
                    JSON.stringify(resultLogin.data.user_attr)
                  );
                  console.log("validateRegisterCodeAndLogin customerInformation: ", customerInformation);
                  setCustomerInformation({
                    ...customerInformation,
                    create_account: false,
                  });

                  resolve(resultLogin);
                  context.refresh();
                } else {
                  resultLogin.data.error = resultLogin.data.message;

                  resolve(resultLogin);
                }
                context.showLoader(false);
              })
              .catch((error) => {
                context.showLoader(false);
                context.showError(false);

                reject(error);
              });
          } else {
            resolve(result);
          }
        })
        .catch((error) => {
          context.showLoader(false);
          context.showError(false);
          reject(error);
        });
    });
  };

  const handelChangeAcceptCondition = (e) => {
    // console.log("handelChangeAcceptCondition: ", acceptCondition);
    setAcceptCondition(e.target.checked);
    setValidateBookingError({
      showValidateBookingError: false,
      message: "",
    });
    context.showError(false);
  };

  const customerErrors = Object.keys(customerInformationError);
  const customerInformationErrorExists = customerErrors.some(
    (customerError) => !!customerInformationError[customerError]
  );

  const occupierErrors = Object.keys(occupierInformationErrors);
  const occupierInformationErrorExists = occupierErrors.some(
    (occupierError) => !!occupierInformationErrors[occupierError]
  );

  const test = () => {
    console.log("test: ", customerInformation);
  }

  const redirectToReservationPage = () => {
    checkTokenValid();
    navigate(`/${urlHotelName}`);
  }

  const checkTokenValid = () => {
    if (JSON.parse(localStorage.getItem("user_session"))?.id_token) {
      verifyToken(JSON.parse(localStorage.getItem("user_session"))?.id_token)
        .then((result) => {
          context.setIsTokenValid(result.data.is_id_token_valid, "checkTokenValid");
        })
        .catch((error) => {
          throw error;
        });
    } else {
      context.setIsTokenValid(false, "checkTokenValid");
    }
  };

  // useEffect(() => {
  //   console.log("effect: ", customerInformation);
  // }, [customerInformation])

  return (
    <>
      {/* <button onClick={test}>test</button> */}
      <section className="sec-breadcrumb">
        <div className="container">
          <div className="breadcrumb wow fadeIn" data-wow-delay="800ms" data-wow-duration="1000ms">
            <nav>
              <span>Vous êtes ici : </span>
              <ul>
                {/* <li><a href={`/${urlHotelName}`}>Réservation de votre visite</a></li> */}
                <li><a onClick={redirectToReservationPage} style={{
                  cursor: 'pointer'
                }}>Réservation de votre visite</a></li>
                <li className="current"><span>Confirmation</span></li>
                {/* <li>Résumé de votre réservation</li> */}
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <section className="contentPage pageInt creation-reservation">
        <div class="deco">
          <div class="ico-para ico1 trigger_parallax01">
            <img src={ico1} />
          </div>
          <div class="ico-para ico2 trigger_parallax01">
            <img src={ico2} />
          </div>
          <div class="ico-para ico3 trigger_parallax01">
            <img src={ico3} />
          </div>
          <div class="ico-para ico4 trigger_parallax01">
            <img src={ico4} />
          </div>
          <div class="ico-para ico5 trigger_parallax01">
            <img src={ico5} />
          </div>
          <div class="ico-para ico6 trigger_parallax01">
            <img src={ico6} />
          </div>
          <div class="ico-para ico7 trigger_parallax01">
            <img src={ico7} />
          </div>
          <div class="ico-para ico8 trigger_parallax01">
            <img src={ico8} />
          </div>
        </div>
        <div className="container">
          {/* <NavigationSteps actualStep="your coordinates" /> */}
          {/* {(customerInformationErrorExists || occupierInformationErrorExists) &&
            !closeErrors && (
              <div className="sidebar" id="sidebar">
                <div className="boxNotification shadow35 sidebar__inner">
                  <label
                    className="modal_close doNotHide"
                    htmlFor="modal-1"
                    onClick={() => setCloseErrors(false)}
                  ></label>
                  <ul className="notificationMsg">
                    {customerInformationErrorExists &&
                      Object.keys(customerInformationError).map((bookerError) => {
                        return (
                          <li className="textRed">
                            <p>{customerInformationError[bookerError]}</p>
                          </li>
                        );
                      })}
                    {occupierInformationErrorExists &&
                      Object.keys(occupierInformationErrors).map(
                        (occupierInformationError) => {
                          return (
                            <li className="textRed">
                              <p>
                                {
                                  occupierInformationErrors[
                                  occupierInformationError
                                  ]
                                }
                              </p>
                            </li>
                          );
                        }
                      )}
                  </ul>
                </div>
              </div>
            )} */}
          {/* <div className="boxCenter w810 mxAuto"> */}
          <Timer
            timerEnd={timerEnd}
            modalTimeUpAlreadyOpened={modalTimeUpAlreadyOpened}
            setModalTimeUpAlreadyOpened={setModalTimeUpAlreadyOpened}
          />
          {/* <div className="shadow35"> */}
          {!context.isTokenValid && (
            <>
              {/* <section className="contentPage pageInt creation-compte"> */}
              <div className="container">
                <h2 className="wow" data-wow-delay="800ms" data-wow-duration="2000ms">{context.translate('reservation')} "{context.translate("national_parc")} Nosy Tanihely"</h2>
                <div className="blcFormulaire wow">
                  <form>
                    <h3>{context.translate("contact_informations")}</h3>
                    <div className="bloc-chp">
                      <InformationContact
                        customerInformationError={customerInformationError}
                        customerInformation={customerInformation}
                        handleChange={handleChange}
                      />
                      <CreateAccount
                        handleChange={handleChange}
                        customerInformation={customerInformation}
                        customerInformationError={customerInformationError}
                        createAccountError={createAccountError}
                        isEnterConfirmationCodeOpen={isEnterConfirmationCodeOpen}
                        setIsEnterConfirmationCodeOpen={setIsEnterConfirmationCodeOpen}
                        clientId={clientId}
                        setClientId={setClientId}
                        validateRegisterCodeAndLogin={validateRegisterCodeAndLogin}
                        confirmationCode={confirmationCode}
                        setConfirmationCode={setConfirmationCode}
                        setCreateAccountError={setCreateAccountError}
                      />

                    </div>
                  </form>
                </div>
              </div>
              {/* </section> */}
            </>
          )}

          <div className="blcFormulaire">
            <h3>{context.translate("group_reservation")}</h3>
            <FastAction
              setAllNationality={setAllNationality}
              setAllOccupierInformation={setAllOccupierInformation}
            />
          </div>

          {/* </div> */}
          {/* <SummaryOfReservation /> */}
          {/* <ActivitySummary
            cart={cart}
          /> */}
          {cartTemp ? (
            cartTemp?.map((itinerary, indexItinerary) => {
              return (
                <ActivitySummary
                  key={`${itinerary?.dateSejour?.debut}_${itinerary?.dateSejour?.fin}`}
                  itinerary={itinerary}
                  indexItinerary={indexItinerary}
                  customers={customers}
                  handleChange={handleChange}
                  setOccupierInformationToBooker={setOccupierInformationToBooker}
                  occupierInformationErrors={occupierInformationErrors}
                  customerInformation={customerInformation}
                  setCustomerInformation={setCustomerInformation}
                  removeItemFromCart={removeItemFromCart}

                  occupierIds={occupierIds}
                  setOccupierIds={setOccupierIds}
                />
              );
            })
          ) : (
            <></>
          )}
          {/* {cartTemp ? (
              cartTemp?.map((itinerary, indexItinerary) => {
                return (
                  <Itinerary
                    key={`${itinerary?.dateSejour?.debut}_${itinerary?.dateSejour?.fin}`}
                    itinerary={itinerary}
                    indexItinerary={indexItinerary}
                    customers={customers}
                    handleChange={handleChange}
                    setOccupierInformationToBooker={
                      setOccupierInformationToBooker
                    }
                    occupierInformationErrors={occupierInformationErrors}
                    customerInformation={customerInformation}
                    removeItemFromCart={removeItemFromCart}
                  />
                );
              })
            ) : (
              <></>
            )} */}
          {/* <div className="shadow35">
            <div className="blkSummay">
              <PaymentForm
                bankAccount={bankAccount}
                setBankAccount={setBankAccount}
                bankAccountError={bankAccountError}
                setBankAccountError={setBankAccountError}
                matchBankAccountErrorMessage={matchBankAccountErrorMessage}
              />
            </div>
          </div>
          <div className="blkSummay">
            <div className="foot">
              <div className="infoTips">
                <div className="pure-checkbox consent">
                  <input
                    id="mainCustomer"
                    name="typeCustomer"
                    type="checkbox"
                    checked={acceptCondition}
                    onChange={() => { }}
                    onClick={handelChangeAcceptCondition}
                  />
                  <label htmlFor="mainCustomer">J'accepte la <a href={`${window.location.origin}/${JSON.parse(sessionStorage.getItem("hotel"))?.name}/generalConditionOfSale`} target="_blank">politique de confidentialité.</a> En effectuant cette réservation, j'accepte les <a href="#">conditions de réservation.</a></label>
                </div>
              </div>
            </div>
          </div>

          <div className="mb50"></div>

          <div className="mb40"></div>
          {!modalTimeUpAlreadyOpened && (
            <div>
              <button onClick={validateBookings} className="btn fullWidht">
                {" "}
                {context.translate("validate_the_reservation")}
              </button>
              {validateBookingError.showValidateBookingError && (
                <p style={{ color: "red", textAlign: "center" }}>
                  {validateBookingError.message}
                </p>
              )}
            </div>
          )} */}
          {jwt_token != null && <DataCollection jwt={jwt_token} />}
          <div className="blcFormulaire" >
            <form >
              <MyPaymentForm
                context={context}
                bankAccount={bankAccount}
                setBankAccount={setBankAccount}
                bankAccountError={bankAccountError}
                setBankAccountError={setBankAccountError}
                matchBankAccountErrorMessage={matchBankAccountErrorMessage}
                nameOnTheCard={nameOnTheCard}
                setNameOnTheCard={setNameOnTheCard}
                setTransientToken={setTransientToken}
                selectedExpMonth={selectedExpMonth}
                setSelectedExpMonth={setSelectedExpMonth}
                selectedExpYear={selectedExpYear}
                setSelectedExpYear={setSelectedExpYear}
                microform={microform}
                setMicroform={setMicroform}
                setCaptureContext={setCaptureContext}
                jwt3d={jwt3d}
                setJwt3d={setJwt3DToken}
                setJwtToken={setJwtToken}
                reservationPayload={reservationPayload}
                setValidateBookingError={setValidateBookingError}
                urlHotelName={urlHotelName}
                setRequesting={setRequesting}
                goVoucher={goVoucher}
              />


              {/* <div className="OptionPaiement">
                <div className="option-check">
                  <input type="radio" id="Visa" name="radio-group" />
                  <label for="Visa"><img src={imgVisa} alt="Visa" /></label>
                </div>
                <div className="option-check">
                  <input type="radio" id="MasterCard" name="radio-group" />
                  <label for="MasterCard"><img src={imgMasterCard} alt="MasterCard" /></label>
                </div>
                <div className="option-check">
                  <input type="radio" id="Paypal" name="radio-group" />
                  <label for="Paypal"><img src={imgPaypal} alt="Paypal" /></label>
                </div>
                <div className="option-check">
                  <input type="radio" id="AmercanExpress" name="radio-group" />
                  <label for="AmercanExpress"><img src={imgAmercanExpress} alt="AmercanExpress" /></label>
                </div>
              </div> */}

              {/* <div className="bloc-chp">

                <div className="blcChp col-100">
                  <label>{context.translate('card_number')} *</label>
                  <input type="text" name="numeroCarte" className="form-control" />
                </div>

                <div className="blcChp date col-50">
                  <label>{context.translate('expiration_date')} (MM/AA) *</label>
                  <input type="text" name="dateExpiration" className="form-control" />
                </div>

                <div className="blcChp col-50">
                  <label>CVV *</label>
                  <input type="text" name="email" className="form-control" />
                  <p className="info">{context.translate('sending_confirmation')}</p>
                </div>

                <div className="blcChp col-100">
                  <label>{context.translate('fullname_in_the_card')}</label>
                  <input type="text" name="nomCarte" className="form-control" />
                </div>

              </div> */}

              <div className="bloc-chp">
                <div className="total flex flexBetween">
                  <span>{context.translate('total_to_pay')}</span>
                  <div className="prix">{formatNumber(cart[0].totalPrice)}<sup>AR</sup></div>
                </div>

                <div className="blcChp col-100">
                  <div className="option-check">
                    <input
                      id="cgu"
                      name="radio-group"
                      type="checkbox"
                      checked={acceptCondition ? true : false}
                      onChange={() => { }}
                      // onChange={handelChangeAcceptCondition}
                      onClick={handelChangeAcceptCondition}
                    />
                    <label for="cgu">J'accepte la <a href={`${window.location.origin}/${JSON.parse(sessionStorage.getItem("hotel"))?.name}/generalConditionOfSale`} target="_blank" title="conditions générales de ventes">conditions générales de ventes</a>. En effectuant cette réservation, j'accepte les conditions de réservation.</label>
                  </div>
                </div>
                <input name="transient_token" id="transient_token" type="hidden" value={transientToken} />
                <div className="blcChp col-100 blcBtn">
                  <div className="blcChp col-100">
                    {validateBookingError.showValidateBookingError && validateBookingError.message && <div className="textError">{validateBookingError.message}</div>}
                    <input disabled={requesting} type="button" onClick={() =>
                      requestPayments()
                    } className="btn-primary" value={context.translate("validate_the_reservation")} />
                    <a href="#" className="link" title="retour / annuler la réservation">retour / annuler la réservation</a>
                  </div>
                </div>
              </div>


            </form>
          </div>

        </div>
      </section >
    </>
  );
};

export default ValidateBookingForm;
