import React, { useMemo, useContext, useState, useEffect } from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import NavigationLocation from "../NavigationLocation";
import { ThemeContext } from "../Wrapper";
import ModificationTimeline from "./modify-booking/ModificationTimeline";
import ModifyInfoRoom from "./modify-booking/ModifyInfoRoom";
import ModifyPolitic from "./modify-booking/ModifyPolitic";
import ModifyBookingFooter from "./modify-booking/ModifyBookingFooter";
import ModifyBookingCheckInOut from "./modify-booking/ModifyBookingCheckInOut";
import { sendUpdateReservation } from "../../services/booking";
import { getDateMadagascar } from "../../services/date";

const ModifyBooking = ({
  bookingSummaries,
  getBookingSummaryByBookingId,
  modificationTimeline,
  booker,
  isReservationCanceled,
  bookingId,
  customerInformation,
  setCustomerInformation,
}) => {
  const navigate = useNavigate();

  const context = useContext(ThemeContext);

  const customers = useMemo(
    () => [
      {
        name: "is_principal_client",
        id: "mainCustomer",
        label: "Je suis le client principal",
      },
      {
        name: "is_principal_client",
        id: "anotherCustomer",
        label: "Je réserve pour un autre client",
      },
    ],
    []
  );

  const [validateBookingError, setValidateBookingError] = useState({
    showValidateBookingError: false,
    message: "",
  });

  const [dateMadagascar, setDateMadagascar] = useState(new Date());

  const canModify = !modificationTimeline.some((etat) => etat.val === 0);

  const handleChange = (e, cbMainCustomer, cbAnotherCustomer, valEtat) => {
    if (!canModify || valEtat === 0) return;
    let valueAndChecked = getValueAndCheckedById(
      e.target,
      cbMainCustomer,
      cbAnotherCustomer
    );

    const { name } = e.target;

    setCustomerInformation((item) => ({
      ...item,
      [name]: valueAndChecked,
    }));
  };

  const getValueAndCheckedById = (
    target,
    cbMainCustomer,
    cbAnotherCustomer
  ) => {
    if (target.name === "create_account") {
      return target.checked;
    } else if (target.name.includes("is_principal_client")) {
      if (target.id.includes("mainCustomer")) {
        cbMainCustomer();
        return target.checked;
      } else {
        cbAnotherCustomer();
        return !target.checked;
      }
    } else {
      return target.value;
    }
  };

  const setOccupierInformationToBooker = (index, rate, isInformationEmpty) => {
    setCustomerInformation({
      ...customerInformation,
      [`occupier_name-${index}`]: isInformationEmpty ? "" : booker.booker_name,
      [`occupier_first_name-${index}`]: isInformationEmpty
        ? ""
        : booker.booker_first_name,
      [`occupier_email_confirmation-${index}`]: isInformationEmpty
        ? ""
        : booker.booker_email,
      [`occupier_professional_phone-${index}`]: isInformationEmpty
        ? ""
        : booker.booker_professional_phone,
      [`occupier_phone-${index}`]: isInformationEmpty
        ? ""
        : booker.booker_phone,
    });
  };

  const updateReservation = () => {
    sendUpdateReservation({
      ...customerInformation,
      reservation_id: bookingId,
    })
      .then((results) => {
        if (results.data.status === 200) {
          navigate(
            `../../${context.getHotel().name}/booking-summary/${bookingId}`
          );
        } else {
          setValidateBookingError({
            showValidateBookingError: true,
            message: "Une erreur s'est produite lors de l'annulation",
          });
          context.showLoader(false);
        }
      })
      .catch((error) => {
        context.showLoader(false);
      });
  };

  useEffect(() => {
    getDateMadagascar()
    .then(date => setDateMadagascar(date));
  }, []);

  return (
    <section className="internalPage">
      <div className="wrapper">
        <NavigationLocation>
          <li>{context.translate('modify_reservation')}</li>
        </NavigationLocation>
        <ModificationTimeline modificationTimeline={modificationTimeline} />
          {bookingSummaries ? (
            bookingSummaries?.map((itinerary, indexItinerary) => {
              return (
                <Fragment
                  key={`${itinerary?.dateSejour?.debut}_${itinerary?.dateSejour?.fin}`}
                >
                <div className="boxCenter w810 mxAuto">
                  <div className="shadow35">
                    <div className="blkSummay">
                      {itinerary?.tarifReserves?.map(
                        (rate, indexReservedPrice) => {
                          return (
                            <Fragment key={`${rate?.prix}_${rate?.nbPersPrix}`}>
                              <div className="head">
                                {
                                  indexReservedPrice === 0 &&
                                  <div className="h3">
                                    {context.translate('informations')}{" "}
                                    <span className="colorPrimary">
                                      {context.translate('itinerary')} {indexItinerary + 1}
                                    </span>
                                  </div>
                                }
                                <ModifyInfoRoom
                                  rate={rate}
                                  indexReservedPrice={indexReservedPrice}
                                />
                                <div className="foot">
                                  <div className="infoTips">
                                    <div className="h3 sizeBase uppercase">
                                      {context.translate('who_are_you_booking_for')}
                                    </div>
                                    {customers.map((customer) => {
                                      return (
                                        <div
                                          key={customer.id}
                                          className="pure-checkbox"
                                        >
                                          <input
                                            name={`${customer.name}-${indexItinerary}_${indexReservedPrice}`}
                                            onChange={(e) => {
                                              handleChange(
                                                e,
                                                () => {
                                                  setOccupierInformationToBooker(
                                                    `${indexItinerary}_${indexReservedPrice}`,
                                                    `${rate._id}_${itinerary._id}`,
                                                    false
                                                  );
                                                },
                                                () => {
                                                  setOccupierInformationToBooker(
                                                    `${indexItinerary}_${indexReservedPrice}`,
                                                    `${rate._id}_${itinerary._id}`,
                                                    true
                                                  );
                                                }
                                              );
                                            }}
                                            id={`${customer.id}-${indexItinerary}_${indexReservedPrice}`}
                                            type="radio"
                                          />
                                          <label
                                            htmlFor={`${customer.id}-${indexItinerary}_${indexReservedPrice}`}
                                          >
                                            {customer.label}
                                          </label>
                                        </div>
                                      );
                                    })}

                                    <div className="mb20"></div>
                                    <div className="boxAnotherCustomer">
                                      <div className="contFormControl">
                                        <input
                                          name={`occupier_name-${indexItinerary}_${indexReservedPrice}`}
                                          value={
                                            customerInformation[
                                              `occupier_name-${indexItinerary}_${indexReservedPrice}`
                                            ]
                                          }
                                          onChange={(e) => handleChange(e, null, null,rate.infoEtat?.val)}
                                          className="form-control"
                                          type="text"
                                          placeholder={
                                            customerInformation?.[
                                              `is_principal_client-${indexItinerary}_${indexReservedPrice}`
                                            ]
                                              ? customerInformation?.booker_name
                                              : `${context.translate('name')} *`
                                          }
                                        />
                                      </div>
                                      <div className="contFormControl">
                                        <input
                                          name={`occupier_first_name-${indexItinerary}_${indexReservedPrice}`}
                                          onChange={(e) => handleChange(e, null, null,rate.infoEtat?.val)}
                                          className="form-control"
                                          type="text"
                                          placeholder={
                                            customerInformation?.[
                                              `is_principal_client-${indexItinerary}_${indexReservedPrice}`
                                            ]
                                              ? customerInformation?.booker_first_name
                                              : `${context.translate('first_name')} *`
                                          }
                                          value={
                                            customerInformation[
                                              `occupier_first_name-${indexItinerary}_${indexReservedPrice}`
                                            ]
                                          }
                                        />
                                      </div>
                                      <div className="contFormControl">
                                        <input
                                          name={`occupier_email_confirmation-${indexItinerary}_${indexReservedPrice}`}
                                          onChange={(e) => handleChange(e, null, null,rate.infoEtat?.val)}
                                          className="form-control"
                                          type="email"
                                          placeholder={
                                            customerInformation?.[
                                              `is_principal_client-${indexItinerary}_${indexReservedPrice}`
                                            ]
                                              ? customerInformation?.booker_email
                                              : `${context.translate('email_address')} *`
                                          }
                                          value={
                                            customerInformation[
                                              `occupier_email_confirmation-${indexItinerary}_${indexReservedPrice}`
                                            ]
                                          }
                                        />
                                        <div className="notefield">
                                          {context.translate('this_is_the_email_address_to_which_your_confirmation_will_be_sent')}
                                        </div>
                                      </div>
                                      <div className="form2Cols">
                                        <div className="contFormControl">
                                          <input
                                            name={`occupier_professional_phone-${indexItinerary}_${indexReservedPrice}`}
                                            onChange={(e) => handleChange(e, null, null,rate.infoEtat?.val)}
                                            className="form-control"
                                            type="text"
                                            placeholder={
                                              customerInformation?.[
                                                `is_principal_client-${indexItinerary}_${indexReservedPrice}`
                                              ]
                                                ? customerInformation?.booker_professional_phone
                                                : context.translate('phone_during_the_day')
                                            }
                                            value={
                                              customerInformation[
                                                `occupier_professional_phone-${indexItinerary}_${indexReservedPrice}`
                                              ]
                                            }
                                          />
                                        </div>
                                        <div className="contFormControl">
                                          <input
                                            name={`occupier_phone-${indexItinerary}_${indexReservedPrice}`}
                                            onChange={(e) => handleChange(e, null, null,rate.infoEtat?.val)}
                                            className="form-control"
                                            type="text"
                                            placeholder={
                                              customerInformation?.[
                                                `is_principal_client-${indexItinerary}_${indexReservedPrice}`
                                              ]
                                                ? customerInformation?.booker_phone
                                                : context.translate("portable_phone")
                                            }
                                            value={
                                              customerInformation[
                                                `occupier_phone-${indexItinerary}_${indexReservedPrice}`
                                              ]
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="content">
                                  <div className="h3 sizeBase uppercase">
                                    {context.translate('additional_details_and_preferences')}
                                  </div>
                                  <textarea
                                    name={`details-${indexItinerary}_${indexReservedPrice}`}
                                    onChange={handleChange}
                                    className="form-control largeTextarea"
                                    placeholder={context.translate('please_specify_your_requests')}
                                  ></textarea>
                                </div>
                              </div>
                              <ModifyBookingCheckInOut itinerary={itinerary} />
                              <ModifyPolitic
                                rate={rate}
                                itinerary={itinerary}
                                dateMadagascar={dateMadagascar}
                              />
                              <ModifyBookingFooter
                                rate={rate}
                                isReservationCanceled={isReservationCanceled}
                                getBookingSummaryByBookingId={
                                  getBookingSummaryByBookingId
                                }
                                setValidateBookingError={
                                  setValidateBookingError
                                }
                                indexItinerary={indexItinerary}
                                indexReservedPrice={indexReservedPrice}
                              />
                              { indexReservedPrice < itinerary.tarifReserves.length - 1 && (
                                <div className="roomSeparator"></div>
                              )}
                              
                            </Fragment>
                          );
                        }
                      )}
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            })
          ) : (
            <></>
          )}
          <div className="mb50"></div>
          <div className="mb40"></div>
          <div>
            <button className="btn fullWidht" onClick={updateReservation}>
              {" "}
              {context.translate('validate_the_reservation')}
            </button>
            {validateBookingError.showValidateBookingError && (
              <p style={{ color: "red", textAlign: "center" }}>
                {validateBookingError.message}
              </p>
            )}
          </div>
      </div>
    </section>
  );
};

export default ModifyBooking;
