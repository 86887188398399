import React,{useContext} from "react";
import Confirmation from "../components/Confirmation";
import { useSearchParams } from "react-router-dom";
import { ThemeContext } from "../components/Wrapper";

const ErrorConfirmationCode = () => {
  const [searchParams] = useSearchParams();
  const message = searchParams.get("message");
  const context = useContext(ThemeContext)
  const anErrorOccured = context.translate('an_error_occured')

  return <Confirmation title={`${anErrorOccured}.`} message={message} />;
};

export default ErrorConfirmationCode;
