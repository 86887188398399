import React, { useEffect, useState } from 'react';
import luhn from 'fast-luhn';

// images
import imgVisa from "../../../styles/parc/images/visa.png";
import imgMasterCard from "../../../styles/parc/images/master-card.png";
import imgPaypal from "../../../styles/parc/images/paypal.png";
import imgAmercanExpress from "../../../styles/parc/images/amercanexpress.png";
import { createCaptureContext } from '../../../services/payment';
import ThreeDSecure from '../ThreeDSecure';
import { applyBooking } from '../../../services/booking';
import { useNavigate } from 'react-router';

const MyPaymentForm = ({
    context,
    bankAccount,
    setBankAccount,
    bankAccountError,
    setBankAccountError,
    matchBankAccountErrorMessage,
    nameOnTheCard,
    setNameOnTheCard,
    setTransientToken,
    selectedExpMonth,
    setSelectedExpMonth,
    selectedExpYear,
    setSelectedExpYear,
    microform,
    setMicroform,
    setCaptureContext,
    jwt3d,
    setJwt3d,
    setJwtToken,
    reservationPayload,
    setValidateBookingError,
    urlHotelName,
    setRequesting,
    goVoucher
}) => {

    const navigate = useNavigate();

    const [expMonths, setExpMonths] = useState([]);
    const [expYears, setExpYears] = useState([]);
    const [optionsPayment, setOptionsPayment] = useState([
        {
            img: imgVisa,
            alt: "Visa",
            isChecked: false,
        }, {
            img: imgMasterCard,
            alt: "Mastercard",
            isChecked: false,
        },
    ]);

    function setupFlex() {
        createCaptureContext()
            .then(result => {
                setCaptureContext(result.data.captureContext);
                if (window.Flex) {
                    // custom styles that will be applied to each field we create using Microform
                    var myStyles = {
                        ':focus': { 'color': 'blue' },
                    };

                    const flex = new window.Flex(result.data.captureContext);
                    const microformTemp = flex.microform({ styles: myStyles });
                    var number = microformTemp.createField(
                        'number',
                        {
                            placeholder: context.translate("card_number"),
                        }
                    );
                    number.on('change', function (data) {
                        console.log('number =>');
                        console.log({data});
                    })
                    console.log('microformTemp number : ', number);
                    var securityCode = microformTemp.createField(
                        'securityCode',
                        {
                            placeholder: 'CVV'
                        }
                    );
                    securityCode.on('change', function (data) {
                        console.log('securityCode =>');
                        console.log({data});
                    })

                    number.load('#number-container');
                    securityCode.load('#securityCode-container');
                    setMicroform(microformTemp);
                }
            })
            .catch(err => {
                console.log('setupFlex error : ', err);
            });

    }

    const handleChangeBankAccount = (value, fieldName) => {
        setBankAccount({ ...bankAccount, [fieldName]: value });
        if (fieldName === 'cardNumber') {
            const isCardNumberValid = luhn(value);
            const cardNumberError = isCardNumberValid ? null : context.translate('invalid_card_number');
            setBankAccountError({ ...bankAccountError, card_number: cardNumberError });
        } else {
            const errorValue = value ? null : matchBankAccountErrorMessage[fieldName];
            const matchErrorFields = {
                cardNumber: 'card_number',
                expirationDate: 'expiration_date',
                securityCode: 'security_code',
                cardFullname: 'card_fullname'
            };
            setBankAccountError({ ...bankAccountError, [matchErrorFields[fieldName]]: errorValue });
        }
    };

    const handleChangeExpiryMonth = (event) => {
        setSelectedExpMonth(event.target.value);
    };

    const handleChangeExpiryYear = (event) => {
        setSelectedExpYear(event.target.value);
    };

    const handleChangeNameOnTheCard = (event) => {
        setNameOnTheCard(event.target.value);
    };

    useEffect(() => {
        if (goVoucher == 'ok') {
            goVoucherScreen();
        } else if (goVoucher == 'nonok') {
            closeThreeDSecure();
        }
    }, [goVoucher]);

    useEffect(() => {
        setExpMonths(
            [
                context.translate("January"),
                context.translate("February"),
                context.translate("March"),
                context.translate("April"),
                context.translate("May"),
                context.translate("June"),
                context.translate("July"),
                context.translate("August"),
                context.translate("September"),
                context.translate("October"),
                context.translate("November"),
                context.translate("December"),
            ]
        );
        setSelectedExpMonth(1);
        const year = new Date().getFullYear();
        let yearTemp = year;
        setSelectedExpYear(yearTemp);
        const expiryYearsTemp = [];
        for (let i = 0; i < 10; i++) {
            expiryYearsTemp.push(yearTemp);
            yearTemp++;
        }
        setExpYears(expiryYearsTemp);

        const script = document.createElement('script');
        const body = document.getElementsByTagName('body')[0];
        script.src = (process.env.REACT_APP_ENV_CYBERSOURCE === 'Prod') ? 'https://flex.cybersource.com/microform/bundle/v2/flex-microform.min.js' : 'https://testflex.cybersource.com/microform/bundle/v2/flex-microform.min.js';
        body.appendChild(script);
        script.addEventListener('load', setupFlex);
    }, []);

    useEffect(() => {
        if (selectedExpMonth && selectedExpYear) {
            handleChangeBankAccount(`${selectedExpYear}-${selectedExpMonth < 10 ? '0' + selectedExpMonth : selectedExpMonth}`, 'expirationDate');
        }
    },
        [
            selectedExpYear,
            selectedExpMonth
        ]
    );

    const closeThreeDSecure = () => {
        context.showLoader(false);
        setValidateBookingError({
            showValidateBookingError: true,
            message: "Une erreur s'est produite lors de votre paiement"
        });
        setRequesting(false);
        setJwt3d(null);
        setJwtToken(null);
    }

    const goVoucherScreen = () => {
        context.showLoader(true);
        console.log('goVoucherScreen reservationPayload : ', reservationPayload);
        applyBooking(reservationPayload).then((response) => {
            // console.log('response : ', response);
            let results = response;
            if (results.data.reservation_id) {
                navigate(
                    `/${urlHotelName}/booking/clientVoucher`,
                    {
                        state: {
                            reservationDetails: results.data.reservationDetails
                        }
                    }
                );
                localStorage.removeItem('cartId');
                localStorage.setItem("isCartSubmitted", JSON.stringify(true));
                localStorage.removeItem("cart");

                context.showLoader(false);
            } else {
                context.showLoader(false);
                setValidateBookingError({
                    showValidateBookingError: true,
                    message: results.data.message,
                });
            }
        }).catch((e) => {
            context.showLoader(false);
            context.showError(false);
        });
        setJwt3d(null);
        setJwtToken(null);
    }

    return (
        <>
            <h3>{context.translate('payment_method')}</h3>
            <div className="OptionPaiement">
                {optionsPayment.map(({ img, alt, isChecked }, itemIndex) => (
                    <div
                        id={isChecked ? "checked-payment" : ""}
                        className="option-check"
                    >
                        <input type="radio" id={alt} name="radio-group" />
                        <label for={alt}>
                            <img src={img} alt={alt} />
                        </label>
                    </div>
                ))}
            </div>
            <div className="bloc-chp">

                {/* <div className="blcChp col-50">
                    <label>{context.translate('card_number')} *</label>
                    <input
                        type="number"
                        className={bankAccountError.card_number
                            ? "form-control errorForm cardNumber"
                            : "form-control cardNumber"}
                        placeholder="Numero de la carte"
                        value={bankAccount.cardNumber}
                        onChange={(e) => { handleChangeBankAccount(e.target.value, 'cardNumber') }}

                    />
                    {bankAccountError.card_number && (
                        <div className="textError">
                            {bankAccountError.card_number}
                        </div>
                    )}
                </div>

                <div className="blcChp col-50">
                    <label>CVV *</label>
                    <input
                        type="number"
                        className={bankAccountError.security_code
                            ? "form-control errorForm cardNumber"
                            : "form-control cardNumber"}
                        placeholder="Code de sécurité"
                        value={bankAccount.securityCode}
                        onChange={(e) => { handleChangeBankAccount(e.target.value, 'securityCode') }}
                    />
                    {bankAccountError.security_code && (
                        <div className="textError">
                            {bankAccountError.security_code}
                        </div>
                    )}
                </div> */}

                <div className="blcChp col-100">
                    <div id="number-container"
                        className={bankAccountError.card_number
                            ? "form-control errorForm cardNumber"
                            : "form-control cardNumber"}></div>
                    {bankAccountError.card_number && (
                        <div className="textError">
                            {bankAccountError.card_number}
                        </div>
                    )}
                </div>
                <div className="blcChp col-100">
                    <div id="securityCode-container" className="form-control"></div>
                    {bankAccountError.security_code && (
                        <div className="textError">
                            {bankAccountError.security_code}
                        </div>
                    )}

                </div>

                <div className="blcChp date col-50">
                    {/* <label>{context.translate('expiration_date')} (MM/AA) </label> */}
                    {/* <input
                        type='month'
                        className={bankAccountError.expiration_date
                            ? "form-control errorForm cardNumber"
                            : "form-control cardNumber"}
                        placeholder="Date d'expiration"
                        value={bankAccount.expirationDate}
                        onChange={(e) => { handleChangeBankAccount(e.target.value, 'expirationDate') }}
                    /> */}
                    <div id="expiryDate">
                        <div>
                            <label>{context.translate('expiration_month')} *</label>
                            <select id="expMonth" onChange={handleChangeExpiryMonth}>
                                {expMonths.map((month, index) =>
                                    <option key={month} value={index + 1}>{month}</option>

                                )}
                            </select>
                        </div>
                        <div>
                            <label>{context.translate('expiration_year')} *</label>
                            <select id="expYear" onChange={handleChangeExpiryYear}>
                                {expYears.map(year =>
                                    <option value={year}>{year}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    {bankAccountError.expiration_date && (
                        <div className="textError">
                            {bankAccountError.expiration_date}
                        </div>
                    )}
                </div>

                <div className="blcChp col-100">
                    <input
                        type="text"
                        name="nomCarte"
                        placeholder={context.translate('fullname_in_the_card')}
                        className={bankAccountError.card_fullname
                            ? "form-control errorForm"
                            : "form-control"}
                        value={bankAccount.cardFullname}
                        onChange={(e) => {
                            handleChangeBankAccount(e.target.value, 'cardFullname');
                            handleChangeNameOnTheCard(e)
                        }}
                    />
                    {bankAccountError.card_fullname && (
                        <div className="textError">
                            {bankAccountError.card_fullname}
                        </div>
                    )}
                </div>
            </div>
            <div style={{ display: jwt3d != null ? 'flex' : 'none' }} id="3ds_element" className="modal open">
                <div className="modal_inner Three_secure_element" style={{ maxWidth: '500px' }}>
                    <label
                        className="modal_close"
                        htmlFor="modal-1"
                        onClick={() => closeThreeDSecure()}
                    ></label>
                    <div className="modal_content" style={{ height: '700px' }}>
                        {jwt3d != null && <ThreeDSecure jwt={jwt3d} />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyPaymentForm;