import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeaderBoard from "../../components/LeaderBoard";
import NavigationLocation from "../../components/NavigationLocation";
import { ThemeContext } from "../../components/Wrapper";
import {
  deleteBookingById,
  getBookingSummaryById,
} from "../../services/booking";
import ItineraryInfo from "../../components/booking-summary/ItineraryInfo";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import ModificationTimeline from "../../components/booking/modify-booking/ModificationTimeline";
import DeleteGlobalConfirmation from "../../components/booking-summary/DeleteGlobalConfirmation";
import NavigationSteps from "../../components/NavigationSteps";

const BookingSummary = ({ urlHotelName }) => {
  const params = useParams();
  const navigate = useNavigate();
  const context = useContext(ThemeContext);
  const [bookingSummaries, setBookingSummaries] = useState([]);
  const [bookingState, setBookingState] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [modificationTimeline, setModificationTimeline] = useState([]);
  const [location, setLocation] = useState({ lat: '', lng: '' });

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getBookingSummaryByBookingId = () => {
    context.showError(true);
    context.showLoader(true);
    getBookingSummaryById(params.bookingId)
      .then((results) => {
        console.log(results.data);
        if (results.data.reservation.itineraires.length > 0) {
          setBookingSummaries(results.data.reservation.itineraires);
          setModificationTimeline(results.data.reservation.infoEtat);
          setBookingState(results.data.reservation.etat);
          const { lat, lng } = results.data.reservation.location;
          setLocation({ lat, lng });
        } else {
          setBookingSummaries([]);
        }
        context.showLoader(false);
      })
      .catch((error) => {
        context.showLoader(false);
        context.showError(false);
      });
  };

  const deleteBooking = () => {
    const payload = {
      id: params.bookingId,
    };
    deleteBookingById(payload)
      .then((results) => {
        if (results.data.status === 200) {
          getBookingSummaryByBookingId();
          setShowDeleteConfirmation(false);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    getBookingSummaryByBookingId();
  }, []);

  const redirectToModify = () => {
    navigate(`/${context.getHotel().name}/modify-booking/${params.bookingId}`);
  };

  console.log(`https://maps.google.com/maps?q=${location.lat},${location.lng}&hl=es&z=14&amp;output=embed`);

  return (
    <>
      <Header />
      <main>
        <LeaderBoard />
        <section className="internalPage">
          <div className="wrapper">
            <NavigationSteps actualStep="your voucher" />
            <div className="w1375 mxAuto">
              <div className="flex flexContent colBigRight">
                <div ref={componentRef} className="centerCol">
                  <ModificationTimeline
                    modificationTimeline={modificationTimeline}
                  />
                  <ItineraryInfo
                    bookingId={params.bookingId}
                    getBookingSummaryByBookingId={getBookingSummaryByBookingId}
                    bookingSummaries={bookingSummaries}
                    bookingState={bookingState}
                  />
                </div>
                <div className="rightCol">
                  <div className="advanceTools flexBetween flex">
                    <a onClick={handlePrint}>
                      <span className="material-symbols-rounded">print</span>
                      {context.translate("print_this_page")}
                    </a>
                    <a href="#">
                      <span className="material-symbols-rounded">share</span>
                      {context.translate("share")}
                    </a>
                    <a href="#">
                      <span className="material-symbols-rounded">
                        event_available
                      </span>
                      {context.translate("add_to_calendar")}
                    </a>
                  </div>
                  {bookingState !== 0 && (
                    <div className="shadow35 pad35 mb35">
                      <div className="option withTxt">
                        <a onClick={() => setShowDeleteConfirmation(true)}>
                          <span className="material-symbols-rounded">
                            close
                          </span>
                          {context.translate("cancel_room_reservation")}
                        </a>
                      </div>
                    </div>
                  )}
                  <div className="addMap">
                    <iframe
                      className="shadow35 mb20"
                      src={`https://maps.google.com/maps?q=${location.lat},${location.lng}&hl=es&z=14&amp&output=embed`}
                      width="100%"
                      height="440"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                    {bookingState !== 3 && (
                      <div className="center">
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "displayCalendar",
                              JSON.stringify(true)
                            );
                            navigate(`/${urlHotelName}`);
                          }}
                          className="btn"
                        >
                          {context.translate("add_an_itinerary")}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb50"></div>
            </div>
          </div>
        </section>
      </main>
      {showDeleteConfirmation && (
        <DeleteGlobalConfirmation
          deleteBooking={deleteBooking}
          setShowDeleteConfirmation={setShowDeleteConfirmation}
        />
      )}
      <Footer />
    </>
  );
};

export default BookingSummary;
