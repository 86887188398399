import React,{useContext} from "react";
import { ThemeContext } from "../Wrapper";

const DeleteGlobalConfirmation = ({
  deleteBooking,
  setShowDeleteConfirmation,
}) => {
  const context = useContext(ThemeContext)
  return (
    <div className="modal open">
      <div className="modal_inner">
        <label
          className="modal_close"
          htmlFor="modal-1"
          onClick={() => {
            setShowDeleteConfirmation(false);
          }}
        ></label>
        <div className="modal_content">
          <div className="head_modal center">
            <img src="images/waiting.png" alt="" />
            <div className="h5">{context.translate('confirmation')}</div>
          </div>
          <div className="cont_modal center">
            <p>{context.translate('are_you_sure_you_want_to_cancel_your_booking')}</p>
            <a className="btn w100" onClick={() => deleteBooking()}>
              {context.translate('validate')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteGlobalConfirmation;
