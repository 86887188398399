import React,{useContext} from "react";
import { getFormatedDate } from "../../../services/utils";
import { ThemeContext } from "../../Wrapper";

const ModifyBookingCheckInOut = ({ itinerary }) => {
  const context = useContext(ThemeContext)  
  return (
    <div className="content">
      <div className="resumInfo flex flexBetween">
        <div className="left">
          <div className="mb20">
            <strong className="uppercase">{context.translate('reservation')}</strong> Confirmé:{" "}
            <strong className="ref">#2213598943</strong>
          </div>
          <div className="puce">
            <ul>
              <li>
                {context.translate('arrival_date')} : <strong>{getFormatedDate(itinerary?.dateSejour?.debut)}</strong>
              </li>
              <li>
                {context.translate('exit_date')} : <strong>{getFormatedDate(itinerary?.dateSejour?.fin)}</strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifyBookingCheckInOut;
