import React, {useContext} from 'react';
import { ThemeContext } from '../Wrapper';

const RequestResetPasswordCode = ({
  handleChange,
  passwordResetMessage,
  sendEmail,
  cancel,
}) => {
  const context = useContext(ThemeContext)
  return (
    <>
      <div className="contFormControl">
        <input
          name="email"
          onChange={handleChange}
          className="form-control"
          type="email"
          placeholder={`${context.translate('email_address')} *`}
        />
      </div>
      <div className="center">
        <p
          style={{
            color: passwordResetMessage.isError ? "red" : "green",
          }}
        >
          {passwordResetMessage.message}
        </p>
          <a className="btn w100" onClick={sendEmail}>
            {context.translate('send_confirmation_code')}
          </a>
        <div className="clr"></div>
        <a
          className="link uppercase bold noLine colorSecondary"
          onClick={cancel}
        >
          {context.translate('cancel')}
        </a>
      </div>
    </>
  );
};

export default RequestResetPasswordCode;