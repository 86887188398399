import axios from "axios";
import api from "../config/api-config";

// VAOVAO
export const formatDate = (inputDate) => {
  if (!inputDate) return '';
  const date = new Date(inputDate);
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    // hour: 'numeric',
    // minute: 'numeric',
    // hour12: false,
  };

  return new Intl.DateTimeFormat('fr-FR', options).format(date);
};

export const formatNumber = (num) => {
  const quant = typeof num === 'string' ? parseFloat(num) : num;
  const nombre = quant.toLocaleString('fr-FR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return nombre
}


export const addZero = (val) => {
  if (val < 10 && val >= 0) {
    val = "0" + val;
  }
  return val;
};

export const getReportTimeLimit = (date) => {
  const functionalDateLimit = new Date(date);
  functionalDateLimit.setDate(functionalDateLimit.getDate() - 1);
  const informativeDateLimit = new Date(date);
  informativeDateLimit.setDate(informativeDateLimit.getDate() - 2);
  return {functionalDateLimit, informativeDateLimit};
}

export const buildDate = (date) => {
  return (
    date.getFullYear() +
    "-" +
    addZero(date.getMonth() + 1) +
    "-" +
    addZero(date.getDate())
  );
};

export const getDiffDays = (StartDate, EndDate) => {
  const nDays = (Date.UTC(EndDate.getFullYear(), EndDate.getMonth(), EndDate.getDate())
    - Date.UTC(StartDate.getFullYear(), StartDate.getMonth(), StartDate.getDate())) / 86400000;

  return nDays;
}

export const getFormatedDate = (date) => {
  const dateElements = date.split('-');
  if (!dateElements[2] || !dateElements[1] || !dateElements[0]) return '';
  return dateElements[2] + '-' + dateElements[1] + '-' + dateElements[0];
};

export const getDateYYYYMMDDHHMMSS = (date) => {
  const dateTemp = date.split(" ");

  const daysPart = dateTemp[0].split('-');
  const year = daysPart[0];
  const month = daysPart[1];
  const day = daysPart[2];

  return `${day}-${month}-${year} ${dateTemp[1]}`;
}

//Daniel
export const isValidCIN = (cin) => {
  if (typeof cin !== 'string' || cin.length === 0) {
    return false;
  }
  return /^\d{12}$/.test(cin);
}

export const extractFieldString = (inputString) => {
  if (typeof inputString !== 'string' || inputString.length === 0) {
    return null;
  }
  const match = inputString.match(/_(.*?)-/);
  if (match && match[1]) {
    return match[1];
  } else {
    return null;
  }
}

export const dateToFullString = (selectedDate) => {
  const date = new Date(selectedDate);
  const jourDelaSemaine = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
  const months = ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = months[date.getMonth()]; // January is 0!
  const yyyy = date.getFullYear();
  const day = jourDelaSemaine[date.getDay()];

  const str = `${day} ${dd} ${mm} ${yyyy}`;

  return str;
}