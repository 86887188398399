import React, { useContext, useState } from "react";
import { Fragment } from "react";
import api from "../../config/api-config";
import { cancelARoom } from "../../services/booking";
import { getDaysBetweenToDate } from "../../services/date";
import { getFormatedDate } from "../../services/utils";
import RateState from "../booking/modify-booking/RateState";
import { ThemeContext } from "../Wrapper";
import DeleteConfirmation from "./DeleteConfirmation";
import PriceBreakDown from "./PriceBreakDown";
import ConfirmationNumber from './ConfirmationNumber';
import { getCurrencyEuroTo } from "../../services/currency";

let indexItineraryOfRoomToCancel = 0;
let indexReservedPriceOfRoomToCancel = 0;

const ItineraryInfo = ({
  bookingSummaries,
  bookingState,
  getBookingSummaryByBookingId,
  bookingId,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const context = useContext(ThemeContext);
  const [validateBookingError, setValidateBookingError] = useState({
    showValidateBookingError: false,
    message: "",
  });

  const removeItemFromCart = () => {
    context.showLoader(true);

    const payload = {
      idReservation: bookingId,
      indexItineraire: indexItineraryOfRoomToCancel,
      indexTarif: indexReservedPriceOfRoomToCancel,
    };

    cancelARoom(payload)
      .then((results) => {
        if (results.data.status === 200) {
          getBookingSummaryByBookingId();
        } else {
          setValidateBookingError({
            showValidateBookingError: true,
            message: context.translate(
              "an_error_occured_during_the_cancelation"
            ),
          });

          context.showLoader(false);
        }
      })
      .catch((error) => {
        context.showLoader(false);
      });
  };

  const openPopupToCancelARoom = (indexItinerary, indexReservedPrice) => {
    setShowDeleteConfirmation(true);
    indexItineraryOfRoomToCancel = indexItinerary;
    indexReservedPriceOfRoomToCancel = indexReservedPrice;
  };

  const lang = localStorage.getItem('lang') || 'fr';
  
  return (
    <>
      {bookingSummaries &&
        bookingSummaries.map((bookingSummary, indexItinerary) => {
          return (
            <Fragment key={bookingSummary.NumeroITineraire}>
              <div className="h3">
                <span className="uppercase">{context.translate("your_itinerary")}</span>
                <span className="colorPrimary">
                  #{bookingSummary.NumeroITineraire}
                </span>
              </div>
              <div className="shadow35 pad25 mb40 contConf">
                <div className="flex flexBetween">
                  <div className="listConf">
                    <div className="item">
                      {context.translate("your_itinerary_number")}{" "}
                      {indexItinerary + 1} :{" "}
                      <strong>#{bookingSummary.NumeroITineraire}</strong>
                    </div>
                  </div>
                  <div className={bookingState === 0 ? "stat error" : "stat success"}>
                    {bookingState === 0
                      ? context.translate("cancelled")
                      : context.translate("confirmed")}
                  </div>
                </div>
              </div>
              {bookingSummary.tarifReserves.map(
                (reservedRate, indexReservedPrice) => {
                  return (
                    <Fragment
                      key={`${reservedRate.numeroConfirmation}_${indexReservedPrice}`}
                    >
                      <div className="h3 uppercase">
                        {context.translate("room_details")}{" "}
                        {indexReservedPrice + 1}
                      </div>
                      <div className="shadow35">
                        <div className="blkSummay">
                          <div className="head ">
                            <div className="bordered flex">
                              <div className="contImg">
                                <img
                                  className="cover"
                                  src={
                                    api.host +
                                    "/" +
                                    reservedRate.infoTypeChambre.photoCrop[0].replace(
                                      "\\",
                                      "/"
                                    )
                                  }
                                  alt="room"
                                />
                              </div>
                              <div className="right">
                                <RateState state={reservedRate.infoEtat} />
                                <ConfirmationNumber 
                                  state={reservedRate.infoEtat}
                                  indexReservedPrice={indexReservedPrice}
                                  confirmationNumber={reservedRate.numeroConfirmation}
                                />
                                <div className="mb10">
                                  <strong>{reservedRate.infoTarif.names[lang]}</strong>
                                </div>
                                <ul className="result">
                                  <li>
                                    <span className="material-symbols-rounded">
                                      bedtime
                                    </span>
                                    {getDaysBetweenToDate(
                                      new Date(bookingSummary.dateSejour.debut),
                                      new Date(bookingSummary.dateSejour.fin)
                                    )}{" "}
                                    {context.translate("nights")}
                                  </li>
                                  <li>
                                    <span className="material-symbols-rounded">
                                      group
                                    </span>
                                    {reservedRate.guests.nbAdulte} {context.translate('adults')} {context.translate('and')} {reservedRate.guests.nbEnfant} {context.translate('children')}
                                  </li>
                                  <li>
                                    <span className="material-symbols-rounded">
                                      zoom_out_map
                                    </span>
                                    {context.translate("surface")}:{" "}
                                    {reservedRate.infoTypeChambre.superficie} m²
                                  </li>
                                  <li>
                                    <span className="material-symbols-rounded">
                                      single_bed
                                    </span>
                                    {reservedRate.infoTypeChambre.names[lang]}{" "}
                                    {reservedRate.infoTypeChambre.chambreTotal}{" "}
                                    {context.translate("rooms")}
                                  </li>
                                </ul>
                                <div>
                                <strong>
                                  {context.translate("price")}:{" "}
                                  {(
                                    reservedRate.toPay.afterProm *
                                    context.currency.value
                                  ).toFixed(2)}{" "}
                                  { context.currency.symbol }
                                </strong>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content">
                            <div className="resumInfo flex flexBetween">
                              <div className="left">
                                <div className="mb20">
                                  <strong className="uppercase">
                                    {context.translate("reservation")}
                                  </strong>{" "}
                                  { reservedRate.infoEtat && reservedRate.infoEtat.val === 0 
                                    ? context.translate("cancelled") 
                                    : <>
                                      {context.translate("confirmed")}:{" "}
                                      <strong className="ref">
                                        #{bookingSummary.NumeroITineraire}
                                      </strong>
                                    </>
                                  }
                                </div>
                                <div className="puce">
                                  <ul>
                                    <li>
                                      {context.translate("arrival_date")}:{" "}
                                      <strong>
                                        {getFormatedDate(bookingSummary.dateSejour.debut)}
                                      </strong>
                                    </li>
                                    <li>
                                      {context.translate("exit_date")}:{" "}
                                      <strong>
                                        {getFormatedDate(bookingSummary.dateSejour.fin)}
                                      </strong>
                                    </li>
                                    {/* <li>
                                    Nombre de personne:
                                    <strong>2 adulte(s)</strong>
                                  </li>
                                  <li>
                                    Chambre: <strong>Suite Deluxe</strong>
                                  </li>
                                  <li>
                                    Tarif/formule: <strong>flexible</strong>
                                  </li>
                                  <li>
                                    Nuitée(s): <strong>03</strong>
                                  </li> */}
                                  </ul>
                                </div>
                              </div>
                              <div className="right">
                                <div className="mb20">
                                  <strong className="uppercase">Client</strong>
                                </div>
                                <p>
                                  {context.translate("name")} :{" "}
                                  <strong>
                                    {reservedRate.reservateurWithEmail.prenom}{" "}
                                    {reservedRate.reservateurWithEmail.nom}
                                  </strong>
                                </p>
                                <p>
                                  {context.translate("phone")}:{" "}
                                  <strong>
                                    {reservedRate.reservateurWithEmail.tel}
                                  </strong>
                                </p>
                                <p>
                                  {context.translate("email_address")}:{" "}
                                  <strong>
                                    <a
                                      href={`mailto:${reservedRate.reservateurWithEmail.email}`}
                                    >
                                      {reservedRate.reservateurWithEmail.email}
                                    </a>
                                  </strong>
                                </p>
                                <div className="mb20">
                                  <strong className="uppercase">
                                    {context.translate("fees_informations")}
                                  </strong>
                                  <p>{reservedRate.infoTarif.descriptions[lang]}</p>
                                </div>
                                <div className="mb20">
                                  <strong className="uppercase">
                                    {context.translate(
                                      "additional_preferences"
                                    )}
                                  </strong>
                                  <p>
                                    {
                                      reservedRate.reservateurWithEmail.messageParticulier
                                      ? reservedRate.reservateurWithEmail.messageParticulier
                                      : context.translate("empty")
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="foot">
                            <div className="infoTips">
                              <h4>{context.translate("warranty_politic")}</h4>
                              <ul>
                                <li>
                                {context.translate('details_warranty_policy')}
                                </li>
                              </ul>
                              <h4>
                                {context.translate("cancellation_politic")}: {reservedRate.politiqueAnnulAtrb[0].names[lang]}
                              </h4>
                              <ul>
                                {reservedRate
                                  .politiqueAnnulAtrb[0]
                                  ?.remboursable 
                                &&
                                  !reservedRate
                                  .politiqueAnnulAtrb[0]
                                  ?.cancelingInfoWithPreciseDate[0]
                                  ?.gracePeriodEnded 
                                && reservedRate
                                  .politiqueAnnulAtrb[0]
                                  ?.cancelingInfoWithPreciseDate[0]
                                  ?.isFirstCondition
                                && (
                                      <li  key={`${reservedRate.politiqueAnnulAtrb[0]?.cancelingInfoWithPreciseDate[0].date}_${reservedRate.politiqueAnnulAtrb[0]?.cancelingInfoWithPreciseDate[0].pourcentage}`}>
                                        {context.translate(
                                          "you_can_freely_cancel_your_reservation_before"
                                        )}{" "}
                                        {
                                          getFormatedDate(
                                            reservedRate.politiqueAnnulAtrb[0]
                                            .cancelingInfoWithPreciseDate[0]
                                            .date
                                          )
                                        }{" "}
                                        {context.translate("at_noon")}
                                      </li>
                                  )}
                                { reservedRate
                                    .politiqueAnnulAtrb[0]
                                    ?.remboursable
                                  && reservedRate
                                    .politiqueAnnulAtrb[0]
                                    ?.cancelingInfoWithPreciseDate.map(
                                    (cancelingInfoWithPreciseDate, index) => {

                                      if (cancelingInfoWithPreciseDate.gracePeriodEnded 
                                        && !cancelingInfoWithPreciseDate?.isCheckIn) {
                                        return (
                                          <li>
                                            {context.translate("In_the_event_of_cancellation,_the_cancellation_costs_will_be")}
                                            {cancelingInfoWithPreciseDate.pourcentage}{" "}%.
                                          </li>
                                        );
                                      }

                                      if (!cancelingInfoWithPreciseDate?.isCheckIn) {
                                        return (
                                          <li key={`${cancelingInfoWithPreciseDate.date}_${cancelingInfoWithPreciseDate.pourcentage}`}>
                                            {context.translate(
                                              "in_case_an_annulation_occures_after"
                                            )}{" "}
                                            {
                                              getFormatedDate(cancelingInfoWithPreciseDate.date)
                                            }{" "}
                                            {context.translate(
                                              "at_noon_your_card_will_be_debited_by"
                                            )}{" "}
                                            {
                                              cancelingInfoWithPreciseDate.pourcentage
                                            }{" "}
                                            %.
                                          </li>
                                        );
                                      }
                                    }
                                  )}
                                  <li key="end">
                                    {context.translate(
                                      "your_card_will_be_debited_by_the_reservation_s_total_price_in_your_arrival_in_unless"
                                    )}
                                  </li>

                                {/* <li>
                                  {reservedreservedRate.politiqueAnnulAtrb[0]
                                    .remboursable &&
                                    reservedRate.politiqueAnnulAtrb[0]?.datePrice.map(
                                      (datePrice) => {
                                        return (
                                          <>
                                            <li>
                                              Vous pouvez annuler votre
                                              réservation gratuitement avant le{" "}
                                              {datePrice.date} à Midi.
                                            </li>
                                            <li>
                                              En cas d’annulation après le{" "}
                                              {datePrice.date} à Midi, votre
                                              carte sera débité de{" "}
                                              {datePrice.pourcentage} %.
                                            </li>
                                            <li>
                                              {" "}
                                              Votre carte sera débité du montant
                                              total de la réservation le jour de
                                              votre arrivée le {datePrice.date}.
                                            </li>
                                          </>
                                        );
                                      }
                                    )}
                                </li>
                               */}
                              </ul>
                            </div>
                          </div>
                          <div className="content contTot">
                            <div className=" flex flexBetween">
                              <div className="left">
                                <strong className="uppercase">
                                  {context.translate("price_breakdown")} :
                                </strong>
                                <PriceBreakDown
                                    priceList={reservedRate.listPrix}
                                    adultsNum={reservedRate.guests.nbAdulte}
                                    childrenNum={reservedRate.guests.nbEnfant}
                                  />
                                <br/>
                                <div>
                                  <strong className="uppercase">
                                    {context.translate("price")} :
                                  </strong>
                                </div>
                                <div className="priceTot">
                                  {(
                                    reservedRate.toPay.afterProm *
                                    context.currency.value
                                  ).toFixed(2)}{" "}
                                  { context.currency.symbol }
                                </div>
                              </div>
                              {!bookingSummary.isReservationCanceled &&
                                reservedRate.etat !== 0 && (
                                  <div className="right">
                                    <div className="option withTxt">
                                      <a
                                        onClick={() => { openPopupToCancelARoom(
                                          indexItinerary,
                                          indexReservedPrice)
                                        }}
                                      >
                                        <span className="material-symbols-rounded">
                                          close
                                        </span>
                                        {context.translate(
                                          "cancel_room_reservation"
                                        )}
                                      </a>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                          {validateBookingError.showValidateBookingError && (
                            <p style={{ color: "red", textAlign: "center" }}>
                              {validateBookingError.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {showDeleteConfirmation && (
                        <DeleteConfirmation
                          deleteBooking={() =>
                            removeItemFromCart()
                          }
                          setShowDeleteConfirmation={setShowDeleteConfirmation}
                        />
                      )}
                    </Fragment>
                  );
                }
              )}
              <div className="shadow35">
                <div className="blkSummay">
                  <div className="content">
                    <div className="contTot">
                      <div className="">
                        <div className="left">
                          <strong className="uppercase">
                            {context.translate("itinerary_total")} :{" "}
                          </strong>
                          <span className="priceTot">
                          <div className="priceTot">
                            {(
                              bookingSummary.toPay * context.currency.value
                            ).toFixed(2)}{" "}
                            { context.currency.symbol }
                          </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
    </>
  );
};

export default ItineraryInfo;
