import React from 'react';
import QRCode from 'react-qr-code';
import logo2 from '../../../styles/parc/images/Logo TNP.jpg';

import './styles/ClientVoucherPdf.css';
import { formatDate } from '../../../services/utils';

const ClientVoucherPdfComponent = ({
    context,
    reservationDetails
}) => {

    return (
        <div>
            <div className="bloc-chp pdf-content">
                {
                    reservationDetails.map((reservationDetail, index) =>
                        <div key={index} className="det-resa">
                            <div className="blcLeft">
                                <a href="#" className="logo-int"><img src={logo2} alt="Aiolia" /></a>
                                <h3>{context.translate('destination')} Nosy Tanihely</h3>
                                <ul className="DetLstReservation">
                                    <li>{context.translate('your_reservation_number')} : <strong>{reservationDetail.numero}</strong></li>
                                    <li>{context.translate('client')} : <strong>{reservationDetail.nom} {reservationDetail.prenom}</strong></li>
                                </ul>
                                <h4>{reservationDetail.tarif?.nomTypeChambre}</h4>
                                <h5>{reservationDetail.tarif?.nomTarif}</h5>
                                <br />
                                <div className="contTxt">
                                    <p>{reservationDetail.tarif?.description}</p>
                                </div>
                                <ul className="lst-resrvation">
                                    <li className={reservationDetail.tarif?.categorieAge}>
                                        {
                                            reservationDetail.tarif?.categorieAge === 'enfant' ?
                                                <>{context.translate('children')}</> :
                                                <>{context.translate('adults')}</>
                                        }
                                    </li>
                                </ul>
                            </div>
                            <div className="blcRight">
                                <div className="blcCodeQr">
                                    <div className="ref">{reservationDetail.numero}</div>
                                    <ul className="lst-resrvation">
                                        <li className="date">
                                            {context.translate('entry_date')}: <strong>{formatDate(reservationDetail.dateEntree)}</strong>
                                        </li>
                                    </ul>
                                    <div className="date"></div>
                                    <div className="codeQr" style={{
                                        width: '300px',
                                        height: '300px',
                                    }}>
                                        <QRCode value={`${reservationDetail._id}`}></QRCode>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default ClientVoucherPdfComponent;